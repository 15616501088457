import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

const removeBackground = (data) => {
  return axios.post(`${API_URL}image/remove-background`, {
    image: data,
  });
};

const generateImage = (data) => {
  return axios.post(`${API_URL}image/generate-image`, data);
};

const getImageProgress = (messageId) => {
  return axios.post(`${API_URL}image/get-progress`, {
    messageId,
  });
};

const imageControls = (data) => {
  return axios.post(`${API_URL}image/image-controls`, data);
};

const getGeneratedImages = (userId, websiteId) => {
  return axios.get(`${API_URL}image/get-generated-images/${userId}/${websiteId}`);
};

const ImageService = {
  removeBackground,
  generateImage,
  getImageProgress,
  imageControls,
  getGeneratedImages,
};

export default ImageService;
