import React, { useRef, useState } from "react";
import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import { ButtonLoaderJoxy } from "./LoaderJoxy";
import { ToastContainer, toast } from "react-toastify";

const CustomAddBackgroundModal = ({
  addBackgroundImageUrl,
  setOpenAddBackgroundModal,
  getCroppedImage,
  setCroppedImageDataUrl,
  cropperWidth,
  cropperHeight,
  setSelectedImage,
  setModalOpen,
  ...props
}) => {
  const [openCategory, setOpenCategory] = useState(false);
  const [savingImage, setSavingImage] = useState(false);
  const [textures, setTextures] = useState([
    "/images/wood1.jpg",
    "/images/wood2.jpg",
    "/images/wood3.jpg",
    "/images/wood4.jpg",
    "/images/wood5.jpg",
    "/images/wood6.jpg",
  ]);
  const [dragActive, setDragActive] = useState(false);
  const inputRef = useRef(null);
  const [customImage, setCustomImage] = useState(null);
  const [selectedBackground, setSelectedBackground] = useState(false);

  const resetBackground = () => {
    if(savingImage){
      return;
    }
    const element = document.getElementById("addBackgroundImage");
    if (element) {
      setSelectedBackground(false);
      element.style.backgroundImage = `none`;
      setCustomImage(null);
    }
  };
  const setBackground = (background) => {
    if(savingImage){
      return;
    }
    const element = document.getElementById("addBackgroundImage");
    if (element) {
      setSelectedBackground(true);
      element.style.backgroundImage = `url(${background})`;
    }
  };

  // handle drag events
  const handleDrag = function (e) {
    if(savingImage){
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e) {
    if(savingImage){
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const reader = new FileReader();
      const file = e.dataTransfer.files[0];
      const acceptedFileTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/webp', 'image/gif'];
      if (!acceptedFileTypes.includes(file.type)) {
        toast.error('File type not supported. Please upload an image of type JPEG, JPG, PNG, WEBP, or GIF.');
        return;
      }
      reader.onloadend = () => {
        setCustomImage(reader.result);
        setBackground(reader.result);
      };
      reader.readAsDataURL(e.dataTransfer.files[0]);
    }
  };

  // triggers when file is selected with click
  const handleChange = function (e) {
    if(savingImage){
      return;
    }
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      const reader = new FileReader();
      const file = e.target.files[0];
      const acceptedFileTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/webp', 'image/gif'];
      if (!acceptedFileTypes.includes(file.type)) {
        toast.error('File type not supported. Please upload an image of type JPEG, JPG, PNG, WEBP, or GIF.');
        return;
      }
      reader.onloadend = () => {
        setCustomImage(reader.result);
        setBackground(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    if(savingImage){
      return;
    }
    inputRef.current.click();
  };
  const saveNewImage = () => {
    setSavingImage(true);
    const styledImageContainer = document.getElementById("cropper-container");
    const image = document.getElementById("addBackgroundImage");
    // Create a canvas
    const canvas = document.createElement("canvas");
    canvas.width = cropperWidth;
    canvas.height = cropperHeight;

    console.log(canvas.width, canvas.height, {cropperWidth,cropperHeight});


    const ctx = canvas.getContext("2d");

    // Draw the background
    ctx.fillStyle =
      window.getComputedStyle(styledImageContainer).backgroundColor;
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // Draw the background image if exists
    const bgImageUrl = window
      .getComputedStyle(image)
      .backgroundImage.slice(5, -2);
    if (!bgImageUrl) {
      // return getCroppedImage(addBackgroundImageUrl);
      setTimeout(() => {
      setOpenAddBackgroundModal(false);
      setSelectedImage(addBackgroundImageUrl);
      setModalOpen(true);
    }, 500); // Wait for fade-out transition
      return;
    }
    const bgImage = new Image();
    bgImage.crossOrigin = "anonymous"; // Handle CORS
    bgImage.src = bgImageUrl;
    bgImage.onload = function () {
      ctx.drawImage(bgImage, 0, 0, canvas.width, canvas.height);

      // Draw the foreground image
      const img = new Image();
      img.crossOrigin = "anonymous"; // Handle CORS
      img.src = image.src;
      img.onload = function () {
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        // Convert canvas to a data URL
        const dataURL = canvas.toDataURL("image/png");
        // setCroppedImageDataUrl(dataURL);
        // getCroppedImage(dataURL);
        setTimeout(() => {
        setOpenAddBackgroundModal(false);
        setSelectedImage(dataURL);
        setModalOpen(true);
        setCustomImage(null);
      }, 500); // Wait for fade-out transition
      };
    };
  };

  return (
    <div className=" edit-moadal absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-5 rounded relative w-[1200px]">
      <div className="flex   justify-between">
          <button className="flex items-center text-[14px] gap-1.5 mb-[15px]"
           onClick={() => {
            setTimeout(() => {
              setOpenAddBackgroundModal(false);
             setSelectedImage(addBackgroundImageUrl);
             setOpenCategory(false);
             setModalOpen(true);
            }, 500); // Wait for fade-out transition
          }}>
           <img
              src="/images/arrow.svg"
              alt="arrow"
              className="w-[14px]"
            />
            Back</button>
            <button
            type="button"
            onClick={() => {
              setOpenAddBackgroundModal(false);
              setOpenCategory(false);
            }}
          >
            <Icon size={1} path={mdiClose} />
          </button>
        </div>
        <div className="absolute flex justify-between w-[95%]">
          <h2>Add AI Background</h2>
        </div>
        <div className="flex mt-12">
          <div className="crop-section">
            <div class="cropper-container addBackgroundImage " id="cropper-container">
              <img
                id="addBackgroundImage"
                src={addBackgroundImageUrl}
                alt="addBackgroundImage"
                className={`h-[${cropperHeight}] w-[${cropperWidth}] object-contain`}
              />
            </div>
            <div className="mt-5 text-center">
            {selectedBackground && <button
                type="button"
                className="rounded-lg bg-black p-2.5 text-center text-sm font-medium text-white w-20"
                onClick={resetBackground}
              >
                Reset
              </button>}
              {savingImage ? (
                <button
                  type="button"
                  className="ms-5 rounded-lg bg-[#2688D1] p-2.5 text-center text-sm font-medium text-white w-20"
                  disabled
                >
                  {/* <CircularProgress size={16} color="inherit" /> */}
                  <ButtonLoaderJoxy />
                </button>
              ) : (
                <button
                  type="button"
                  className="ms-5 rounded-lg bg-[#2688D1] p-2.5 text-center text-sm font-medium text-white "
                  onClick={saveNewImage}
                >
                  Select Image
                </button>
              )}
              
            </div>
          </div>
          {!openCategory && (
            <div>
              <div className="ms-3">
                <span className="font-bold text-lg">Background</span>
              </div>
              <div className="ms-3 mt-4">
                {customImage && (
                  <div id="label-file-upload">
                    <div className="w-[50%] flex gap-[50%]">
                      <img src={customImage} alt="Upload" className="w-[50%]" />
                      <button type="button" onClick={resetBackground}>
                        <Icon size={1} path={mdiClose} />
                      </button>
                    </div>
                  </div>
                )}
                {!customImage && (
                  <form
                    id="form-file-upload"
                    onDragEnter={handleDrag}
                    onSubmit={(e) => e.preventDefault()}
                  >
                    <input
                      ref={inputRef}
                      type="file"
                      id="input-file-upload"
                      multiple={true}
                      onChange={handleChange}
                    />
                    <label
                      id="label-file-upload"
                      htmlFor="input-file-upload"
                      className={dragActive ? "drag-active h-[72px]" : "h-[72px]"}
                    >
                      <div className="flex flex-col items-center">
                        <img
                          src="/images/upload-image-icon.svg"
                          alt="upload-icon"
                          className="w-[22px]"
                        />
                        <div className="text-[8px] flex items-center">
                          <p>Drop a file or</p>
                          <button
                            className="text-[8px] upload-button"
                            onClick={onButtonClick}
                          >
                            select an image
                          </button>
                        </div>
                      </div>
                    </label>
                    {dragActive && (
                      <div
                        id="drag-file-element"
                        onDragEnter={handleDrag}
                        onDragLeave={handleDrag}
                        onDragOver={handleDrag}
                        onDrop={handleDrop}
                      ></div>
                    )}
                  </form>
                )}
              </div>

              {/* <div className="flex items-center mt-3 ms-3 rounded-lg outline outline-none bg-[#F1F4F9] ">
                  <span><img src="/images/search-icon.svg" alt="search-icon" className="p-3"/></span>
                  <input type="search"  placeholder="Search Background" className="rounded-lg ms-0.5 w-60 h-10 bg-[#F1F4F9]"/>
                </div> */}

              <div className="ms-3 mt-4">
                <span className="font-bold text-sm">Texture</span>
              </div>
              <div className="grid grid-cols-2 ms-3 mt-3  gap-1.5">
                <div
                  className="bg-[#F1F4F9] p-1 rounded-lg h-20"
                  onClick={() => {
                    setOpenCategory(true);
                  }}
                >
                  <span className="font-bold text-[10px]">Wood</span>
                  <div className="flex gap-2.5 mt-2">
                    {textures?.slice(0, 4).map((img) => (
                      <img
                        className="w-[25px] h-[25px] rounded"
                        src={img}
                        alt="background"
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}

          {openCategory && (
            <div>
              <div className="flex ms-3">
                <span
                  className="bg-[#F1F4F9]"
                  onClick={() => {
                    setOpenCategory(false);
                  }}
                >
                  <img src="/images/arrow.svg" alt="arrow" className="p-3" />
                </span>
                <span className="ms-2.5 font-bold text-lg">Wood</span>
              </div>
              <div className="grid grid-cols-3 ms-3 mt-3 gap-1.5">
                {textures.map((img) => (
                  <div
                  className="image-box w-[76px] h-[76px] rounded logo-trans">
                  <img                   
                    src={img}
                    alt="background"
                  />
                  <div className="overlay w-[76px] h-[76px] rounded">
                    <button
                      className="hover-btn disabled:cursor-default disabled:bg-[#3c3c3c] disabled:text-[#777777]"
                      onClick={() => {
                        setBackground(img);
                      }}
                    >
                      Select
                    </button>
                  </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      <ToastContainer autoClose={2000} pauseOnHover={false} theme="colored" />
    </div>
  );
};

export default CustomAddBackgroundModal;
