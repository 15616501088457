import { CHATBOX_OPEN_AI_FAILURE, CHATBOX_OPEN_AI_SUCCESS } from "../actions/type";


const initialState = {

};

export default function chatGptReducer(state = initialState, action) {
  switch (action.type) {
    case CHATBOX_OPEN_AI_SUCCESS:
      return {
        ...state,
        chatboxOpenAI: action.payload,
      };

    case CHATBOX_OPEN_AI_FAILURE:
      return {
        ...state,
        chatboxOpenAI: action.payload,
      };

    default:
      return state;
  }
}