import React, { useEffect, useState } from "react";
import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { replaceImagesWithThumbnails } from "../constants/jsonData";
import { getSavedVariant } from "../../actions/grapesjs";
import { ButtonLoaderJoxy } from "./LoaderJoxy";
import { ExpandLessOutlined, ExpandMoreOutlined } from "@mui/icons-material";

const CustomUploadVideoModal = ({
  editorRef,
  setSelectedImage,
  setModalOpen,
  setIsUploadVideoModalOpen,
  setMessageId,
  setIsCreateImageModalOpen,
  setOriginalImage,
  aiImages,
  ...props
}) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const location = useLocation();
  const [url, setUrl] = useState("");
  const [assets, setAssets] = useState([]);
  const [moreImgLoading, setMoreImgLoading] = useState(false);
  const [invalidUrl, setInvalidUrl] = useState(false);
  const [expandedSection, setExpandedSection] = useState("saved");

  // Function to extract query parameter from the URL
  const getQueryParam = (param) => {
    return new URLSearchParams(location.search).get(param);
  };

  // Extract the v_id from the query string
  const variantId = getQueryParam("v_id");

  useEffect(() => {
    getBucketImages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // get s3 bucket images
  const getBucketImages = () => {
    setMoreImgLoading(true);
    dispatch(
      getSavedVariant({
        id: id,
        variantId: variantId,
      })
    )
      .then(async (response) => {
        setMoreImgLoading(true);
        if (response.data.assets_images) {
          setAssets(response.data.assets_images);
        }
      })
      .catch((error) => {
        // Handle error as needed
        console.log("Error in fetching template", error);
      });
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      getVideoUrl();
    }
  };
  const isValidURL = (url) => {
    try {
      new URL(url);
      return true;
    } catch (e) {
      return false;
    }
  };

  const getVideoUrl = async () => {
    if (!url) {
      return;
    }
    console.log({"editorRef.current.getSelected()editorRef.current.getSelected()":editorRef.current.getSelected()})
    var embedUrl;
    if (!isValidURL(url)) {
      // return setInvalidUrl(true);

      embedUrl = url;
    } else {
      var srcUrl = url + "?autoplay=1&mute=1&loop=1";
      if (url.includes("youtube") && !url.includes("embed")) {
        const videoId = url.split("v=")[1];

        // Construct the embed URL
        srcUrl = `https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1&loop=1`;
      }

      embedUrl = `<iframe src="${srcUrl}" allow="autoplay" muted></iframe>`;

    }
    setIsUploadVideoModalOpen(false);
    window.editor.runCommand("replace-img-with-video", { uploadUrl: embedUrl });
    return;
  };

  return (
    <div className=" edit-moadal absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white px-7 py-10 rounded relative w-[1200px]">
        <div className="flex   justify-between">
          <button
            className="flex items-center text-[14px] gap-1.5 mb-[15px]"
            onClick={() => {
              setTimeout(() => {
                editorRef.current.runCommand("open-assets");
                setUrl("");
                setIsUploadVideoModalOpen(false);
              }, 500); // Wait for fade-out transition
            }}
          >
            <img src="/images/arrow.svg" alt="arrow" className="w-[14px]" />
            Back
          </button>
          <button
            type="button"
            onClick={() => {
              setIsUploadVideoModalOpen(false);
              setUrl("");
            }}
          >
            <Icon size={1} path={mdiClose} />
          </button>
        </div>
        <div className="flex">
          <h2>Insert Video</h2>
        </div>
        <div className="flex mt-10 w-[1140px] h-[446px]">
          <div className="outline outline-1 outline-[#959595] rounded-xl overflow-y-auto w-[878px] h-[100%] px-10 pt-8">
            <div>
              <div className="mt-6">
                <span className="font-bold text-[12px]">
                  Insert Video from URL
                </span>
                <div className="mt-1 flex">
                  <input
                    type="url"
                    onChange={(e) => {
                      setUrl(e.target.value);
                      setInvalidUrl(false);
                    }}
                    className=" w-[100%] h-[40px] p-3 bg-[#F1F4F9] rounded-lg focus:outline-none"
                    name="url"
                    placeholder="Add file URL"
                    value={url}
                    onKeyDown={handleKeyDown}
                  />
                </div>
                <div className="font-medium text-[10px] ms-1 mt-1"> E.g., add a URL from YouTube or Wistia.</div>
                {invalidUrl && <div className="text-[red]">Invalid Url</div>}
              </div>
            </div>
            <div className="text-end mt-4">
              <button
                type="button"
                className="rounded-lg text-[12px] outline outline-1 outline-[#1A1A1A2E] text-center font-medium w-[70px] h-[32px]"
                onClick={() => {
                  setUrl("");
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="ms-4 rounded-lg text-[12px] bg-[#2688D1] text-center font-medium text-white w-[70px] h-[32px]"
                onClick={() => {
                  getVideoUrl(url);
                }}
              >
                Save
              </button>
            </div>
          </div>

          <div>
            <div className={`${expandedSection === "saved" ? "h-[86%]" : ""}`}>
              <div
                className="ms-3 flex items-center justify-between cursor-pointer"
                onClick={() => {
                  expandedSection === "ai_generated"
                    ? setExpandedSection("saved")
                    : setExpandedSection("ai_generated");
                }}
              >
                <span className="font-bold text-sm">Saved Images</span>
                {expandedSection === "saved" ? (
                  <span onClick={() => setExpandedSection("ai_generated")}>
                    <ExpandLessOutlined />
                  </span>
                ) : (
                  <span onClick={() => setExpandedSection("saved")}>
                    <ExpandMoreOutlined />
                  </span>
                )}
              </div>
              {moreImgLoading && assets.length === 0 && (
                <div className="flex ms-3 w-[248px] h-[95%] content-center flex-wrap">
                  {/* <CircularProgress color="inherit" /> */}
                  <ButtonLoaderJoxy />
                </div>
              )}
              {expandedSection === "saved" && (
                <div className="max-h-[90%] grid-container grid grid-cols-3 ms-3 mt-3 gap-1.5 overflow-y-auto">
                  {assets.map((asset, index) => (
                    <div
                      key={index}
                      className="image-box w-[76px] h-[76px] rounded logo-trans"
                    >
                      <img
                        className="image"
                        src={replaceImagesWithThumbnails(asset)}
                        alt="background"
                      />
                      <div className="overlay w-[76px] h-[76px] rounded">
                        <button
                          className="hover-btn disabled:cursor-default disabled:bg-[#3c3c3c] disabled:text-[#777777]"
                          onClick={() => {
                            setTimeout(() => {
                              setIsUploadVideoModalOpen(false);
                              setSelectedImage(asset);
                              setOriginalImage(asset);
                              setUrl("");
                              setModalOpen(true);
                            }, 500); // Wait for fade-out transition
                          }}
                        >
                          Select
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>

            {aiImages.length > 0 && (
              <div
                className={`${
                  expandedSection === "ai_generated" ? "h-[86%] mt-6" : "mt-6"
                }`}
              >
                <div className="ms-3 grid">
                  <div
                    className="flex items-center justify-between cursor-pointer"
                    onClick={() => {
                      expandedSection === "ai_generated"
                        ? setExpandedSection("saved")
                        : setExpandedSection("ai_generated");
                    }}
                  >
                    <span className="font-bold text-sm">
                      AI Generated Images
                    </span>
                    {expandedSection === "ai_generated" ? (
                      <span onClick={() => setExpandedSection("saved")}>
                        <ExpandLessOutlined />
                      </span>
                    ) : (
                      <span onClick={() => setExpandedSection("ai_generated")}>
                        <ExpandMoreOutlined />
                      </span>
                    )}
                  </div>
                  <span className="text-[10px]">
                    (To modify a previously generated image click edit)
                  </span>
                </div>
                {expandedSection === "ai_generated" && (
                  <div className="max-h-[90%] grid-container grid grid-cols-3 ms-3 mt-3 gap-1.5 overflow-y-auto">
                    {aiImages.map((img, index) => (
                      <div
                        key={index}
                        className="image-box w-[76px] h-[76px] rounded logo-trans"
                      >
                        <img
                          className="image"
                          src={img.thumbnailUri}
                          alt="background"
                        />
                        <div className="overlay w-[76px] h-[76px] rounded">
                          <button
                            className="hover-btn disabled:cursor-default disabled:bg-[#3c3c3c] disabled:text-[#777777]"
                            onClick={() => {
                              setTimeout(() => {
                                setIsUploadVideoModalOpen(false);
                                setSelectedImage(img.imageUri);
                                setOriginalImage(img.imageUri);
                                setUrl("");
                                setModalOpen(true);
                              }, 500); // Wait for fade-out transition
                            }}
                          >
                            Select
                          </button>
                          <button
                            className="hover-btn disabled:cursor-default disabled:bg-[#3c3c3c] disabled:text-[#777777]"
                            onClick={() => {
                              setMessageId(img);
                              setIsCreateImageModalOpen(true);
                              setIsUploadVideoModalOpen(false);
                            }}
                          >
                            Edit
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomUploadVideoModal;
