import React, { useEffect, useRef, useState } from "react";
import { mdiClose, mdiMenuDown, mdiMenuUp } from "@mdi/js";
import Icon from "@mdi/react";
import { useDispatch } from "react-redux";
import { getExistingDomains, existingDomain } from "../../actions/grapesjs";
import { useLocation, useParams } from "react-router-dom";

const CustomUseDomainModal = ({
  setUseDomainModal,
  publish_url,
  websiteId,
  variant_Id,
  ...props
}) => {
  const [pagePath, setPagePath] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState({domain:"try.example.com"});
  const customSelectWrapper = useRef(null);
  const [existingDomains, setExistingDomains] = useState([]);
  const dispatch = useDispatch();
  const location = useLocation();
  const { id } = useParams();
  const [error, setError] = useState(null);

    // Function to extract query parameter from the URL
    const getQueryParam = (param) => {
      return new URLSearchParams(location.search).get(param);
    };

  const variantId = getQueryParam("v_id");

  const token = localStorage.getItem("user");
  // Parse the JSON string to get the object
  const userData = JSON.parse(token);
  // Access the "id" property from the user object
  const userId = userData.user.id;

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setError('');
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        customSelectWrapper.current &&
        !customSelectWrapper.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    getExistingDomainsData();
  }, []);

  const getExistingDomainsData = async() =>{
    dispatch(getExistingDomains(userId))
      .then(async (response) => {
        setExistingDomains(response.data);
      })
      .catch((error) => {
        // Handle error as needed
        console.log("Error in adding domain", error);
      });
  }

  const useDomain = async() => {
    if(!selectedOption.id){
      return setError('Please select a domain');
    }
    const new_cname = (publish_url).replace("http://", "");
    const domain_data = {
      id: selectedOption.id,
      userId: userId,
      websiteId: id ? id : websiteId,
      variantId: variantId ? variantId : variant_Id,
      domain: selectedOption.domain,
      cname: new_cname,
      page_path: pagePath
    }
    dispatch(existingDomain(domain_data))
      .then(async (response) => {
        if(response.data.message) {
          return setError(response.data.message);
        }
        setError('')
        setUseDomainModal(false);
        setPagePath("");
      })
      .catch((error) => {
        // Handle error as needed
        console.error("Error in adding domain", error);
      });
  }

  return (
    <div className=" edit-moadal absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 ">
      <div className="bg-[white] rounded-xl relative w-[484px] ">
        <div className="flex justify-end pe-2 pt-2">
          <button
            className="outline outline-black outline-1 rounded-full"
            type="button"
            onClick={() => {
              setUseDomainModal(false);
              setPagePath("");
            }}
          >
            <Icon size={0.9} path={mdiClose} color={'black'} />
          </button>
        </div>
        <div className="flex justify-center">
          <span className="text-[20px] text-black font-bold leading-6">
            Change Page Url
          </span>
        </div>
        <div className="px-5 pt-6">
          <div className="">
            <span className="font-medium text-[15px] text-black leading-5">
              URL preview
            </span>
            <input
              type="text"
              className=" w-[100%] mt-2 outline outline-1 outline-[#E7E7E7] rounded-lg ps-[10px] py-[10px] font-medium text-[15px] text-black leading-5 placeholder:font-medium placeholder:text-[15px] placeholder:leading-5 bg-[#E7E7E7]"
              name="pagePath"
              placeholder="try.example.com"
              value={`http://${selectedOption.domain}/${pagePath}`}
              autoComplete="off"
              readOnly
            />
          </div>
          <div className="mt-3">
            <span className="font-medium text-[15px] text-black leading-5">
              Domain Name
            </span>
            <div
              className={`custom-select-wrapper w-[441px]`}
              ref={customSelectWrapper}
            >
              <div
                className={`flex justify-between text-[15px] text-black leading-5 font-medium py-[10px] px-[12px] cursor-pointer outline outline-1 ${
                  isOpen
                    ? "rounded-t outline-[#000000]"
                    : "rounded outline-[#959595]"
                }`}
                onClick={handleToggle}
              >
                <span
                  className={`${
                    selectedOption.domain.includes("try.example.com")
                      ? "text-[#A0AEC0]"
                      : "text-[#000000]"
                  }`}
                >
                  {selectedOption.domain}
                </span>
                {!isOpen ? (
                  <span aria-label="arrow">
                    <Icon size={0.9} path={mdiMenuDown} color={'black'} />
                  </span>
                ) : (
                  <span aria-label="arrow">
                    <Icon size={0.9} path={mdiMenuUp} color={'black'} />
                  </span>
                )}
              </div>
              {isOpen && (
                <div
                  className="absolute bg-[#262626] text-[white] z-10 w-[inherit] rounded-b max-h-[200px] overflow-auto"
                  onMouseLeave={handleToggle}
                >
                 {existingDomains?.map((domain) => ( <div
                    className="custom-option border-b border-[#1F1F1F] cursor-pointer py-[10.75px] ps-4 text-[10px] font-medium hover:bg-[black]"
                    onClick={() =>
                      handleOptionClick(domain)
                    }
                    data-value="1"
                  >
                    {domain.domain}
                  </div>
                 ))}
                </div>
              )}
            </div>
          </div>
          <div className="mt-3">
            <span className="font-medium text-[15px] text-black leading-5">
              Page path
            </span>
            <input
              type="text"
              onChange={(e) => {
                setPagePath(e.target.value);
              }}
              className=" w-[100%] mt-2 outline outline-1 outline-[#959595] rounded-lg ps-[10px] py-[10px] font-medium text-[15px] text-black leading-5 placeholder:font-medium placeholder:text-[15px] placeholder:leading-5"
              name="pagePath"
              placeholder="abc-ab-abc"
              value={pagePath}
              autoComplete="off"
            />
          </div>
          {error && <div className="mt-1 mb-2">
            <span className="font-normal text-[14px] text-[red] leading-5">{error}</span>
          </div>}

          <div className="text-center mb-10 mt-6">
            <button
              type="button"
              className="rounded text-[12px] outline outline-1 outline-black text-center font-medium w-[79px] h-[27px] text-black btn-bg hover:outline-none hover:text-white"
              onClick={() => {
                setPagePath("");
                setUseDomainModal(false);
              }}
            >
              Cancel
            </button>
            <button
              type="button"
              className="ms-2 rounded text-[12px] outline outline-1 outline-black text-center font-medium w-[79px] h-[27px] text-black btn-bg hover:outline-none hover:text-white"
              onClick={useDomain}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomUseDomainModal;
