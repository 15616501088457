import React, { useEffect, useRef, useState } from "react";
import { mdiClose, mdiMenuDown, mdiMenuUp, mdiTrashCanOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { ToastContainer, toast } from "react-toastify";
import {
  addScript,
  deleteScripts,
  editScripts,
  getScripts,
} from "../../actions/scripts";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { ButtonLoaderJoxy } from "./LoaderJoxy";

const CustomAddTagModal = ({ setOpenAddTagModal, ...props }) => {
  const [scriptList, setScriptList] = useState([]);
  const [optionsList, setOptionsList] = useState([
    "Before Body End Tag",
    "After Body End Tag",
    "Header",
  ]);
  const [scriptName, setScriptName] = useState("");
  const [script, setScript] = useState("");
  const [hoveredBlock, setHoveredBlock] = useState("");
  const [selectedScript, setSelectedScript] = useState("");
  const [isPlacementOpen, setIsPlacementOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Before Body End Tag");
  const [savingScript, setSavingScript] = useState(false);
  const [editScript, setEditScript] = useState(false);
  const [applyScript, setApplyScript] = useState(false);
  const customSelectWrapper = useRef(null);
  const [error, setError] = useState('');
  const dispatch = useDispatch();
  const { id } = useParams();
  const location = useLocation();

  // Function to extract query parameter from the URL
  const getQueryParam = (param) => {
    return new URLSearchParams(location.search).get(param);
  };

  const variantId = getQueryParam("v_id");
  const token = localStorage.getItem("user");
  // Parse the JSON string to get the object
  const userData = JSON.parse(token);
  // Access the "id" property from the user object
  const userId = userData.user.id;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        customSelectWrapper.current &&
        !customSelectWrapper.current.contains(event.target)
      ) {
        setIsPlacementOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    getScriptsData();
  }, []);

  const getScriptsData = async () => {
    dispatch(getScripts(userId))
      .then(async (response) => {
        setScriptList(response.data);
        setError('');
      })
      .catch((error) => {
        // Handle error as needed
        console.log("Error in getting script", error);
      });
  };

  const saveScript = async () => {
    if (!selectedOption || !scriptName || !script) {
      return;
    }
    setSavingScript(true);
    const script_data = {
      userId: userId,
      websiteId: id,
      variantId: variantId,
      script: script,
      script_name: scriptName,
      placement: selectedOption,
      apply_script: applyScript,
    };
    !editScript
      ? await addNewScript(script_data)
      : await editSelecetedScript(script_data);
      dispatch({ type: "EDITOR_PUBLISH_STATUS", payload: true });
    return;
  };

  const addNewScript = async (script) => {
    dispatch(addScript(script))
      .then(async (response) => {
        if(response.data.message){
          setSavingScript(false);
          return setError(response.data.message)
        }
        toast.success("Script added successfully");
        setTimeout(() => {
          setError('');
          setSavingScript(false);
          setScript("");
          setScriptName("");
          setOpenAddTagModal(false);
        }, 2000);
      })
      .catch((error) => {
        // Handle error as needed
        setSavingScript(false);
        console.error("Error in adding script", error);
      });
  };

  const editSelecetedScript = async (script) => {
    script.id = selectedScript;
    dispatch(editScripts(script))
      .then(async (response) => {
        if(response.data.message){
          setSavingScript(false);
          return setError(response.data.message)
        }
        toast.success("Script Updated successfully");
        setTimeout(() => {
          setError('');
          setSavingScript(false);
          setScript("");
          setScriptName("");
          setOpenAddTagModal(false);
        }, 2000);
      })
      .catch((error) => {
        // Handle error as needed
        setSavingScript(false);
        console.error("Error in editing script", error);
      });
  };

  const deleteScript = async (script) => {
    dispatch(deleteScripts(script._id))
      .then(async (response) => {
        await getScriptsData();
        setScript("");
        setScriptName("");
        setSelectedOption("Before Body End Tag");
        setEditScript(false);
        toast.success("Script deleted successfully");
      })
      .catch((error) => {
        // Handle error as needed
        console.error("Error in deleting script", error);
      });
  };

  const checkIsApplied = (script) => {
    const isApplied = script.appliedScriptsDetails.find((appliedScript) => {
      if (
        appliedScript.websiteId === id &&
        appliedScript.scriptId === script._id
      ) {
        return true;
      }
    });
    return isApplied ? true : false;
  };

  const selectScript = (script) => {
    setError('');
    setSelectedScript(script._id);
    const scripthtml = script.script.replace(/&lt;/g, "<")
    setScript(scripthtml);
    setScriptName(script.script_name);
    setSelectedOption(script.placement);
    if (script.appliedScriptsDetails.length > 0) {
      const isApplied = checkIsApplied(script);
      isApplied ? setApplyScript(true) : setApplyScript(false);
    }else{
      setApplyScript(false)
    }
    setEditScript(true);
  };

  const handleToggle = () => {
    setIsPlacementOpen(!isPlacementOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsPlacementOpen(false);
  };

  return (
    <div className="edit-moadal absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-4 rounded relative w-[1200px]">
        <div className="flex justify-between">
          <div className="ps-[14px] pt-6">
            <span className="font-bold text-[20px] leading-[21px] text-black">
              Add a script to this page
            </span>
          </div>
          <button
            type="button"
            onClick={() => {
              setOpenAddTagModal(false);
            }}
          >
            <Icon size={1.5} path={mdiClose} color={'black'} />
          </button>
        </div>
        <div className="flex border border-[#959595] rounded-xl mt-10 mb-6 mx-3.5">
          <div className="w-[246px] pt-6 pb-5 border-r border-[#959595]">
            <div className="border-b border-[#959595] pb-6">
              <span className="font-medium text-[15px] leading-[18.85px] ps-5 text-black">
                VARIANT SCRIPTS
              </span>
            </div>
            <div className="flex flex-col max-h-[391px] overflow-auto">
              {scriptList.map((script) => (
                <div
                  className="flex justify-between text-[12px] leading-[15.08px] px-5 py-2 hover:bg-[#D9D9D9] h-[39px] cursor-pointer"
                  onMouseEnter={() => setHoveredBlock(script.script_name)}
                  onMouseLeave={() => setHoveredBlock("")}
                >
                  <span
                    className="w-full text-black"
                    onClick={() => {
                      selectScript(script);
                    }}
                  >
                    {script.script_name}
                  </span>
                  {hoveredBlock === script.script_name && (
                    <>
                      {checkIsApplied(script) && (
                        <span className="me-2 bg-[white] rounded-full w-[22px] h-[22px] flex justify-center items-center">
                          <input
                            type="checkbox"
                            id="remember-me"
                            name="rememberMe"
                            className="checked:bg-blue-600 checked:border-transparent rounded-lg border-black"
                            checked={checkIsApplied(script)}
                            readOnly
                          />
                        </span>
                      )}
                      <span
                        className="bg-[white] rounded-full w-[22px] h-[22px] flex justify-center items-center"
                        onClick={() => {
                          deleteScript(script);
                        }}
                      >
                        <Icon
                          path={mdiTrashCanOutline}
                          size={0.6}
                          color={"red"}
                        />
                      </span>
                    </>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div>
            <div className="flex gap-4 ps-8 pt-6 pe-5">
              <div className="flex flex-col">
                <span className="font-medium text-[15px] leading-[18.85px] text-black">
                  Script Name
                </span>
                <input
                  type="text"
                  className="w-[307px] mt-2 outline outline-1 outline-[#959595] rounded-lg ps-[10px] py-[10px] font-medium text-[15px] text-black leading-5 placeholder:font-medium placeholder:text-[15px] placeholder:leading-5"
                  name="script"
                  placeholder="E.g., GTM Tags or Meta Pixel."
                  autoComplete="off"
                  value={scriptName}
                  onChange={(e) => {
                    setScriptName(e.target.value);
                  }}
                />
              </div>
              <div className="flex flex-col">
                <span className="font-medium text-[15px] leading-[18.85px] text-black">
                  Placement
                </span>
                <div
                  className={`custom-select-wrapper w-[307px] mt-2`}
                  ref={customSelectWrapper}
                >
                  <div
                    className={`flex justify-between text-[15px] text-black leading-5 font-medium py-[10px] px-[12px] cursor-pointer outline outline-1 ${
                      isPlacementOpen
                        ? "rounded-t-lg outline-[#000000]"
                        : "rounded-lg outline-[#959595]"
                    }`}
                    onClick={handleToggle}
                  >
                    <span
                      className={`${
                        selectedOption.includes("try.example.com")
                          ? "text-[#A0AEC0]"
                          : "text-[#000000]"
                      }`}
                    >
                      {selectedOption}
                    </span>
                    {!isPlacementOpen ? (
                      <span aria-label="arrow">
                        <Icon size={0.9} path={mdiMenuDown} color={"black"} />
                      </span>
                    ) : (
                      <span aria-label="arrow">
                        <Icon size={0.9} path={mdiMenuUp} color={"black"} />
                      </span>
                    )}
                  </div>
                  {isPlacementOpen && (
                    <div
                      className="absolute bg-[#262626] text-[white] z-10 w-[inherit] rounded-b max-h-[200px] overflow-auto"
                      onMouseLeave={handleToggle}
                    >
                      {optionsList?.map((option) => (
                        <div
                          className="custom-option border-b border-[#1F1F1F] cursor-pointer py-[10.75px] ps-4 text-[10px] font-medium hover:bg-[black]"
                          onClick={() => handleOptionClick(option)}
                          data-value="1"
                        >
                          {option}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="ps-8 pt-4 pe-5">
              <span className="font-medium text-[15px] leading-[18.85px] text-black">
                {`Add the script below. <script> tags must be present.`}
              </span>
              <div>
                <textarea
                  className="w-[840px] h-[246px] mt-2 outline outline-1 outline-[#959595] rounded-lg ps-[10px] py-[10px] font-medium text-[15px] text-black leading-5 placeholder:font-medium placeholder:text-[15px] placeholder:leading-5"
                  name="script"
                  placeholder="Test"
                  autoComplete="off"
                  value={script}
                  onChange={(e) => {
                    setScript(e.target.value);
                    setError('');
                  }}
                />
                {error &&<div className="mb-2"><span className="text-[red] leading-[21px] text-sm font-normal">{error}</span></div>}
                <div className="flex justify-between flex-wrap items-start">
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      id="remember-me"
                      name="rememberMe"
                      className="mr-1 checked:bg-blue-600 checked:border-transparent rounded-lg border-black"
                      checked={applyScript}
                      onChange={() => {
                        setApplyScript(!applyScript);
                      }}
                    />
                    <label
                      htmlFor="remember-me"
                      className="text-black leading-[21px] text-xs font-normal"
                    >
                      Apply Script to Variant
                    </label>
                  </div>
                  <div className="pb-5 mt-4">
                    <button
                      className="w-[79px] h-[27px] py-[6px] px-5 text-black font-medium text-[12px] leading-[15.08px] rounded-lg outline outline-1 outline-black"
                      type="button"
                      onClick={() => {
                        setOpenAddTagModal(false);
                      }}
                    >
                      Cancel
                    </button>
                    {!savingScript ? (
                      <button
                        className="w-[79px] h-[32px] py-[6px] px-5 text-white font-medium text-[12px] leading-[15.08px] rounded-lg outline outline-1 outline-[#2688D1] bg-[#2688D1] ms-2"
                        type="button"
                        onClick={saveScript}
                      >
                        Save
                      </button>
                    ) : (
                      <button
                        className="w-[79px] h-[32px] py-[6px] px-5 text-white font-medium text-[12px] leading-[15.08px] rounded-lg outline outline-1 outline-[#2688D1] bg-[#2688D1] ms-2"
                        type="button"
                      >
                        <ButtonLoaderJoxy />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer autoClose={2000} pauseOnHover={false} theme="colored" />
    </div>
  );
};

export default CustomAddTagModal;
