import React, { useEffect, useState } from "react";
import basestyle from "../Base.module.css";
import registerstyle from "./Register.module.css";
import axios from "axios";
import { useDispatch } from "react-redux";
import { login, googleLogin, register } from "../../actions/auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useNavigate, NavLink } from "react-router-dom";
// import Footer from "../../pages/Footer";
const Register = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [user, setUserDetails] = useState({
    name: "",
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(true);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setUserDetails({
      ...user,
      [name]: value,
    });
  };

  const validateForm = (values) => {
    const error = {};
    const regex = /^[^\s+@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.name) {
      error.name = "First Name is required";
    }
    if (!values.email) {
      error.email = "Email is required";
    } else if (!regex.test(values.email)) {
      error.email = "This is not a valid email format!";
    }
    if (!values.password) {
      error.password = "Password is required";
    } else if (values.password.length < 4) {
      error.password = "Password must be more than 4 characters";
    } else if (values.password.length > 10) {
      error.password = "Password cannot exceed more than 10 characters";
    }
    return error;
  };
  const signupHandler = (e) => {
    e.preventDefault();
    setFormErrors(validateForm(user));
    setIsSubmit(true);
    // if (!formErrors) {
    //   setIsSubmit(true);
    // }
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      dispatch(register(user))
        .then(async (response) => {
          toast.success("User Register successfully");
          setTimeout(() => {
            navigate("/", { replace: true });
          }, 2000);
        })
        .catch((error) => {
          toast.error(error.message);
          // Handle error as needed
        });
    }
  }, [formErrors]);
  return (
    <>
      <div className={`${basestyle.RegisterFormBackground} grid h-screen xl:h-full`}>
      <div className="xl:m-0  m-auto">
        <div className="mb-4">
          <img
            src="/images/new_theme/Joxy.svg"
            className="scrapper-logo-color"
          />
        </div>
        <form className={basestyle.userForm}>
          <h1 className="font-cera-pro-bold text-xl font-bold leading-[33.6px] pb-7 text-center">
            {" "}
            Register
          </h1>
          <div class="mb-3 w-full">
            <label for="name" class="font-cera-pro-bold block text-sm font-medium leading-6 mb-2">
              User Name
            </label>
            <div class={`relative ${basestyle.formInputBox}  w-full`}>
              <div
                className={`inset-y-0 left-0 w-full flex items-center text-sm leading-5`}
              >
                <img
                  className={`h-6 text-gray-700 cursor-pointer`}
                  src="/images/new_theme/person_icon.svg"
                />
                <input
                  className="w-full pl-[7.18px] focus-visible:outline-none"
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Enter User Name"
                  onChange={changeHandler}
                  value={user.name}
                />
              </div>
            </div>
            <p className={basestyle.error}>{formErrors.name}</p>
          </div>
          <div class="mb-3 w-full">
            <label for="email" class="font-cera-pro-bold block text-sm font-medium leading-6 mb-2">
              Email Address
            </label>
            <div class={`relative ${basestyle.formInputBox}  w-full`}>
              <div
                className={`inset-y-0 left-0 w-full flex items-center text-sm leading-5`}
              >
                <img
                  className={`h-6 text-gray-700 cursor-pointer`}
                  src="/images/new_theme/sms-tracking.svg"
                />
                <input
                  className="w-full pl-[7.18px]  focus-visible:outline-none"
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Enter Email Address"
                  onChange={changeHandler}
                  value={user.email}
                />
              </div>
            </div>
            <p className={basestyle.error}>{formErrors.email}</p>
          </div>
          <div class="mb-3 w-full">
            <label for="email" class="font-cera-pro-bold block text-sm font-medium leading-6 mb-2">
              Password
            </label>
            <div class={`relative ${basestyle.formInputBox}  w-full`}>
              <div
                className={`inset-y-0 left-0 w-full flex items-center text-sm leading-5`}
              >
                <img
                  className={`h-6 text-gray-700 cursor-pointer`}
                  src="/images/new_theme/lock_icon.svg"
                />
                <input
                  className="w-full pl-[7.18px] focus-visible:outline-none"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  id="password"
                  placeholder="Enter Password"
                  onChange={changeHandler}
                  value={user.password}
                />
              </div>

              <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                <img
                  className={`h-6 text-gray-700 cursor-pointer ${
                    showPassword ? "hidden" : "block"
                  }`}
                  onClick={togglePasswordVisibility}
                  src="/images/new_theme/eye.svg"
                />

                <img
                  className={`h-6 text-gray-700 cursor-pointer ${
                    showPassword ? "block" : "hidden"
                  }`}
                  fill="none"
                  onClick={togglePasswordVisibility}
                  src="/images/new_theme/eye-off.svg"
                />
              </div>
            </div>

            <p className={basestyle.error}>{formErrors.password}</p>
          </div>
          <div class="flex justify-between items-center mb-6">
            <div class="flex items-center">
              <input
                type="checkbox"
                id="policy-action"
                name="policy-action"
                value="policy-action"
                class="mr-1 w-4 h-4 checked:bg-blue-600 bg-black checked:border-transparent rounded-lg border-black"
              />
              <label
                for="policy-action"
                class="text-black leading-[21px] text-xs font-normal"
              >
                I agree to the Terms and Privacy Policy.
              </label>
            </div>
          </div>
          <button
            className={`font-cera-pro-bold w-full ${basestyle.button_common}`}
            onClick={signupHandler}
          >
            Register
          </button>
          <div class="mt-8 flex flex-row justify-center">
            <span class="absolute bg-white px-4 text-black">
              Or with Social Profile
            </span>
            <div class="mt-3 h-px w-full bg-[#6C757D]"></div>
          </div>
          <div className="flex justify-center mt-8">
            <img className="mr-3" src="/images/new_theme/google.svg" />
            <img className="mr-3" src="/images/new_theme/facebook.svg" />
            <img className="mr-3" src="/images/new_theme/twitter.svg" />
            <img src="/images/new_theme/linkedin.svg" />
          </div>
          <div className="mt-6 text-center text-black">
          Already Registered?{" "}
            <a href="/login" className="text-[#2688D1] underline">
              Login
            </a>
          </div>
        </form>
      </div>
      </div>
      <ToastContainer autoClose={2000} pauseOnHover={false} theme="colored" />
      {/* <Footer /> */}
    </>
  );
};
export default Register;
