import { UPDATE_SCRAPER_DATA_SUCCESS,AUTO_SKIP_FAILURE, AUTO_SKIP_SUCCESS, UPDATE_SCRAPER_DATA_FAILURE, TEMPLATE_1_SUCCESS, TEMPLATE_1_FAILURE, USER_WEBSITE_FAILURE, USER_WEBSITE_SUCCESS, SCRAPER_FAILURE, SCRAPER_SUCCESS,SCRAPER_DATA_FAILURE,SCRAPER_DATA_SUCCESS } from "./type";
import scraperjsService from "../services/scraper.service";

export const scraper1 = (data) => (dispatch) => {
  return scraperjsService.scraper1(data).then(
    (response) => {
      dispatch({
        type: SCRAPER_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      dispatch({
        type: SCRAPER_FAILURE,
        payload: error,
      });
      return Promise.reject(error);
    }
  );
};
export const scraperData = (id,step ) => (dispatch) => {
  return scraperjsService.scraperData(id,step ).then(
    (response) => {
      dispatch({
        type: SCRAPER_DATA_SUCCESS,
        payload: response.data.data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      dispatch({
        type: SCRAPER_DATA_FAILURE,
        payload: error,
      });
      return Promise.reject(error);
    }
  );
};
  export const updateScraperData = (id,data) => (dispatch) => {
    return scraperjsService.updateScraperData(id,data).then(
      (response) => {
        dispatch({
          type: UPDATE_SCRAPER_DATA_SUCCESS,
          payload: response.data.data,
        });
        return Promise.resolve(response);
      },
      (error) => {
        dispatch({
          type: UPDATE_SCRAPER_DATA_FAILURE,
          payload: error,
        });
        return Promise.reject(error);
      }
    );
};


export const autoSkip = (id,data) => (dispatch) => {
  return scraperjsService.autoSkip(id,data).then(
    (response) => {
      dispatch({
        type: AUTO_SKIP_SUCCESS,
        payload: response.data.data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      dispatch({
        type: AUTO_SKIP_FAILURE,
        payload: error,
      });
      return Promise.reject(error);
    }
  );
};

