import { Add } from "@mui/icons-material";
import {
  UPDATE_VARIANT_FAILURE,
  UPDATE_VARIANT_SUCCESS,
  REMOVE_SECTION_SUCCESS,
  REMOVE_SECTION_FAILURE,
  WEBSITE_LIST_SUCCESS,
  WEBSITE_LIST_FAILURE,
  TEMPLATE_SUCCESS,
  TEMPLATE_FAILURE,
  TEMPLATE_1_FAILURE,
  TEMPLATE_1_SUCCESS,
  USER_WEBSITE_FAILURE,
  USER_WEBSITE_SUCCESS,
  UPDATE_EDITOR_SUCCESS,
  UPDATE_EDITOR_FAILURE,
  TEMPLATE_VARIANTS_FAILURE,
  TEMPLATE_VARIANTS_SUCCESS,
  CLICK_FAILURE,
  CLICK_SUCCESS,
  SAVE_EDITOR_FAILURE,
  SAVE_EDITOR_SUCCESS,
  SAVED_TEMPLATE_SUCCESS,
  SAVED_TEMPLATE_FAILURE,
  PREVIEW_FAILURE,
  PREVIEW_SUCCESS,
  ADD_NEW_VARIANTS_FAILURE,
  ADD_NEW_VARIANTS_SUCCESS,
  ADD_NEW_SECTION_FAILURE,
  ADD_NEW_SECTION_SUCCESS,

} from "../actions/type";

const initialState = {
  template1: {},
};

export default function grapejsReducer(state = initialState, action) {
  switch (action.type) {
    case TEMPLATE_1_SUCCESS:
      return {
        ...state,
        template1: action.payload,
      };
    case TEMPLATE_1_FAILURE:
      return {
        ...state,
        template1: action.payload,
      };
    case USER_WEBSITE_SUCCESS:
      return {
        ...state,
        userWebsite: action.payload,
      };
    case USER_WEBSITE_FAILURE:
      return {
        ...state,
        userWebsite: action.payload,
      };
    case TEMPLATE_SUCCESS:
      return {
        ...state,
        template1: action.payload,
      };
    case TEMPLATE_FAILURE:
      return {
        ...state,
        template1: action.payload,
      };
    case UPDATE_EDITOR_SUCCESS:
      return {
        ...state,
        update_editor: action.payload,
      };
    case UPDATE_EDITOR_FAILURE:
      return {
        ...state,
        update_editor: action.payload,
      };
    case WEBSITE_LIST_SUCCESS:
      return {
        ...state,
        website_list: action.payload,
      };
    case WEBSITE_LIST_FAILURE:
      return {
        ...state,
        website_list: action.payload,
      };
    case REMOVE_SECTION_FAILURE:
      return {
        ...state,
        removed_section: action.payload,
      };
    case REMOVE_SECTION_SUCCESS:
      return {
        ...state,
        removed_section: action.payload,
      };
    case TEMPLATE_VARIANTS_FAILURE:
      return {
        ...state,
        Template_variants: action.payload,
      };
    case TEMPLATE_VARIANTS_SUCCESS:
      return {
        ...state,
        Template_variants: action.payload,
      };
    case UPDATE_VARIANT_FAILURE:
      return {
        ...state,
        updated_variants: action.payload,
      };
    case UPDATE_VARIANT_SUCCESS:
      return {
        ...state,
        updated_variants: action.payload,
      };
    case CLICK_FAILURE:
      return {
        ...state,
        clickData: action.payload,
      };
    case CLICK_SUCCESS:
      return {
        ...state,
        clickData: action.payload,
      };
    case SAVE_EDITOR_FAILURE:
      return {
        ...state,
        save_editor: action.payload,
      };
    case SAVE_EDITOR_SUCCESS:
      return {
        ...state,
        save_editor: action.payload,
      };
    case SAVED_TEMPLATE_FAILURE:
      return {
        ...state,
        save_template: action.payload,
      };
    case SAVED_TEMPLATE_SUCCESS:
      return {
        ...state,
        save_template: action.payload,
      };
    case PREVIEW_FAILURE:
      return {
        ...state,
        preview_data: action.payload,
      };
    case PREVIEW_SUCCESS:
      return {
        ...state,
        preview_data: action.payload,
      };
    case ADD_NEW_VARIANTS_FAILURE:
      return {
        ...state,
        add_new_variants: action.payload,
      };
    case ADD_NEW_VARIANTS_SUCCESS:
      return {
        ...state,
        add_new_variants: action.payload,
      };
    case ADD_NEW_SECTION_FAILURE:
      return {
        ...state,
        add_new_section: action.payload,
      };
    case ADD_NEW_SECTION_SUCCESS:
      return {
        ...state,
        add_new_section: action.payload,
      };

    default:
      return state;
  }
}
