import React from "react";
import { DevicesProvider, WithEditor } from "@grapesjs/react";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { cx } from "./common";
import TopbarButtons from "./TopbarButtons";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

export default function Topbar({ className, rightToggelValue, showSidebar }) {
  const user = useSelector((state) => state.auth);
  const {save_template} = useSelector((state) => state.grapejs);
  const navigate = useNavigate();

  return (
    <>
    <div className={cx("bg-[#222222] flex min-h-[34px] items-center md:px-[120px]", className)}>
      <div className="font-medium text-[12px]">
        <span className="">Variant:</span><span className="px-1 py-0 ml-1">{save_template?.variant_title}</span>
        <span className="ms-4 me-4 border-[0.5px] border-end"></span>
        <span className="">Version:</span><span className="px-1 py-0 ml-1">{save_template?.variant_name}</span>
      </div>
      {/* <div className=" underline italic">{user?.setTimeDifference}</div> */}
    </div>
    <div className={cx("gjs-top-sidebar flex items-center min-h-20 px-6 py-[22px] md:px-[120px]", className)}> 
      <div
        className="grape-top-header"
        style={{ cursor: "pointer" }}
        onClick={() => {
          navigate("/");
          localStorage.removeItem("selectedMenuItem");
        }}
      >
        <img src="/images/new_theme/Joxy.svg" className="scrapper-logo" alt="" />
      </div>
      {/* <DevicesProvider>
        {({selected, select, devices}) => (
          <FormControl size="small">
            <Select value={selected} onChange={(ev) => select(ev.target.value)}>
              {devices.map((device) => (
                <MenuItem value={device.id} key={device.id}>
                  {device.getName()}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </DevicesProvider> */}
      <WithEditor>
        <TopbarButtons
          rightToggelValue={rightToggelValue}
          showSidebar={showSidebar}
          className="ml-auto px-2"
        />
      </WithEditor>
    </div>
    </>
    
  );
}
