import React, { useEffect, useState } from "react";
import {
  mdiArrowULeftTop,
  mdiClose,
  mdiPlus,
  mdiTrashCanOutline,
} from "@mdi/js";
import Icon from "@mdi/react";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { addMetaData, getMetaData, updateFavicon } from "../../actions/scripts";
import { ButtonLoaderJoxy } from "./LoaderJoxy";
import { getSavedVariant } from "../../actions/grapesjs";
import { uploadImageToS3Bucket } from "../../actions/s3bucket";

const CustomEditPagePropModal = ({ setOpenEditPagePropModal, ...props }) => {
  const [title, setTitle] = useState("");
  const [keywords, setKeywords] = useState("");
  const [description, setDescription] = useState("");
  const [hidePage, setHidePage] = useState(false);
  const [saving, setSaving] = useState(false);
  const [savingImage, setSavingImage] = useState(false);
  const [favUrl, setFavUrl] = useState("");
  const [oldFile, setOldFile] = useState(null);
  const [rows, setRows] = useState([
    { field_key_name: "", field_key_value: "", field_value: "" },
  ]);
  const [selectedFile, setSelectedFile] = useState(null);
  const dispatch = useDispatch();
  const { id } = useParams();
  const location = useLocation();

  // Function to extract query parameter from the URL
  const getQueryParam = (param) => {
    return new URLSearchParams(location.search).get(param);
  };

  const variantId = getQueryParam("v_id");
  const token = localStorage.getItem("user");
  // Parse the JSON string to get the object
  const userData = JSON.parse(token);
  // Access the "id" property from the user object
  const userId = userData.user.id;

  const saveProperties = async () => {
    updateImage();
    if (!title || !keywords || !description) {
      return;
    }
    const metaData = [
      {
        field_key_name: "name",
        field_key_value: "keywords",
        field_value: keywords,
      },
      {
        field_key_name: "name",
        field_key_value: "description",
        field_value: description,
      },
      ...rows,
    ];
    setSaving(true);
    const data = {
      userId: userId,
      websiteId: id,
      variantId: variantId,
      metaData: JSON.stringify(metaData),
      hidePage: hidePage,
      title: title,
    };
    dispatch(addMetaData(data))
      .then(async (response) => {
        toast.success("Properties added successfully");
        setTimeout(() => {
          setSaving(false);
          setTitle("");
          setKeywords("");
          setDescription(false);
          setHidePage(false);
          setOpenEditPagePropModal(false);
          dispatch({ type: "EDITOR_PUBLISH_STATUS", payload: true });
        }, 2000);
      })
      .catch((error) => {
        // Handle error as needed
        setSaving(false);
        console.error("Error in adding Properties", error);
      });
  };

  useEffect(() => {
    dispatch(
      getSavedVariant({
        id: id,
        variantId: variantId,
      })
    )
      .then((response) => {
        setFavUrl(response.data.favicon);
        setOldFile(response.data.favicon);
      })
      .catch((error) => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getMetaData(id))
      .then((response) => {
        const data = response.data[0];
        if (data) {
          const metaData = JSON.parse(data?.metaData);
          setTitle(data.title);
          const keywords = getMeta(metaData, "keywords").field_value;
          setKeywords(keywords);
          const description = getMeta(metaData, "description").field_value;
          setDescription(description);
          setHidePage(data.hidePage);
          const rowsData = metaData.filter(
            (item) =>
              item.field_key_value !== "keywords" &&
              item.field_key_value !== "description"
          );
          setRows(rowsData);
        }
      })
      .catch((error) => {
        console.error({ error });
      });
  }, []);

  function getMeta(metaData, key) {
    const data = metaData.find((meta) => meta.field_key_value === key);
    return data;
  }

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const newRows = [...rows];
    newRows[index][name] = value;
    setRows(newRows);
  };

  const addRow = () => {
    setRows([
      ...rows,
      { field_key_name: "", field_key_value: "", field_value: "" },
    ]);
  };

  const removeRow = (index) => {
    if (rows.length === 1) {
      return;
    }
    const newRows = rows.filter((_, i) => i !== index);
    setRows(newRows);
  };

  const handleFileChange = (event) => {
    setOldFile(favUrl);
    const file = event.target.files[0];
    setSelectedFile(file);

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setFavUrl(reader.result); // This sets the image preview to the uploaded file
      };

      reader.readAsDataURL(file); // Converts the file into a data URL
    }
  };

  const handleClear = () => {
    setFavUrl(oldFile);
    setSelectedFile(null);
  };

  const updateImage = () => {
    if (!selectedFile) {
      return;
    }
    setSavingImage(true);
    dispatch(
      uploadImageToS3Bucket({
        file: favUrl,
        folder_name: id,
        file_name: selectedFile.name,
      })
    )
      .then(async (response) => {
        if (response.data.file) {
          dispatch(
            updateFavicon({
              websiteId: id,
              favicon: response.data.file,
            })
          ).then(async (res) => {
            setSavingImage(false);
            setOldFile(response.data.file);
            setFavUrl(response.data.file);
            toast.success("Favicon changed Successfully");
            dispatch({ type: "EDITOR_PUBLISH_STATUS", payload: true });
          });
        }
      })
      .catch((error) => {
        // Handle error as needed
        toast.error(error);
        setSavingImage(false);
        console.log("Error in fetching s3 bucket images", error);
      });
  };

  return (
    <div className="edit-moadal absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white pt-4 px-[30px] rounded relative w-[1200px]">
        <div className="flex justify-between">
          <div className="ps-[14px] pt-6">
            <span className="font-bold text-[20px] leading-[21px] text-black">
              Edit Page Properties
            </span>
          </div>
          <button
            type="button"
            onClick={() => {
              setOpenEditPagePropModal(false);
            }}
          >
            <Icon size={1.5} path={mdiClose} color={"black"} />
          </button>
        </div>
        <div className="flex border border-[#959595] rounded-xl my-10 px-5 py-6">
          <div>
            <div className="flex gap-4">
              <div>
                <span className="font-bold text-[15px] leading-[18.85px] text-black">
                  Title
                </span>
                <div>
                  <textarea
                    className="w-[354px] mt-2 outline outline-1 outline-[#959595] rounded-lg ps-[10px] py-[10px] font-medium text-[15px] text-black leading-5 placeholder:font-medium placeholder:text-[15px] placeholder:leading-5 resize-none"
                    rows={2}
                    value={title}
                    onChange={(e) => {
                      setTitle(e.target.value);
                    }}
                  ></textarea>
                </div>
              </div>
              <div>
                <span className="font-bold text-[15px] leading-[18.85px] text-black">
                  Keywords
                </span>
                <div>
                  <textarea
                    className="w-[354px] mt-2 outline outline-1 outline-[#959595] rounded-lg ps-[10px] py-[10px] font-medium text-[15px] text-black leading-5 placeholder:font-medium placeholder:text-[15px] placeholder:leading-5 resize-none"
                    rows={2}
                    value={keywords}
                    onChange={(e) => {
                      setKeywords(e.target.value);
                    }}
                  ></textarea>
                </div>
              </div>

              <div>
                <span className="font-bold text-[15px] leading-[18.85px] text-black">
                  Description
                </span>
                <div>
                  <textarea
                    className="w-[354px] mt-2 outline outline-1 outline-[#959595] rounded-lg ps-[10px] py-[10px] font-medium text-[15px] text-black leading-5 placeholder:font-medium placeholder:text-[15px] placeholder:leading-5 resize-none"
                    rows={2}
                    value={description}
                    onChange={(e) => {
                      setDescription(e.target.value);
                    }}
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="max-h-[175px] overflow-auto ps-1 pb-2  mt-3">
              <span className="flex text-black font-bold text-[15px] items-center">
                Dynamic Meta Content
                <button
                  className="w-[20px] h-[20px] ms-2 rounded-full bg-[#2688D1] text-white flex items-center justify-center text-[12px] leading-[20.11px]"
                  data-twe-toggle="tooltip"
                  data-twe-placement="right"
                  data-twe-ripple-init
                  data-twe-ripple-color="light"
                  title='<meta property="og:url" content="https://demo/url/gors/here"> 
                  here "property" is attribute name and "og:url" is the attribue value'
                >
                  ?
                </button>
              </span>
              {rows.map((row, index) => (
                <div className="flex  mt-3 gap-5">
                  <div className="flex flex-col">
                    <label className="mb-3 text-black font-bold text-[15px] capitalize">
                      attribute name
                    </label>
                    <input
                      type="text"
                      className="w-[300px] outline outline-1 outline-[#959595] rounded-lg ps-[10px] py-[10px] font-medium text-[15px] text-black leading-5 placeholder:font-medium placeholder:text-[15px] placeholder:leading-5"
                      name="field_key_name"
                      placeholder="attribute name"
                      autoComplete="off"
                      value={row.field_key_name}
                      onChange={(event) => handleInputChange(index, event)}
                    />
                  </div>
                  <div className="flex flex-col">
                    <label className="mb-3 text-black font-bold text-[15px] capitalize">
                      attribute value
                    </label>
                    <input
                      type="text"
                      className="w-[300px] outline outline-1 outline-[#959595] rounded-lg ps-[10px] py-[10px] font-medium text-[15px] text-black leading-5 placeholder:font-medium placeholder:text-[15px] placeholder:leading-5"
                      name="field_key_value"
                      placeholder="attribute value"
                      autoComplete="off"
                      value={row.field_key_value}
                      onChange={(event) => handleInputChange(index, event)}
                    />
                  </div>
                  <div className="flex flex-col">
                    <label className="mb-3 text-black font-bold text-[15px] capitalize">
                      Content
                    </label>
                    <input
                      type="text"
                      className="w-[300px] outline outline-1 outline-[#959595] rounded-lg ps-[10px] py-[10px] font-medium text-[15px] text-black leading-5 placeholder:font-medium placeholder:text-[15px] placeholder:leading-5"
                      name="field_value"
                      placeholder="content"
                      autoComplete="off"
                      value={row.field_value}
                      onChange={(event) => handleInputChange(index, event)}
                    />
                  </div>
                  <div className="flex flex-col">
                    <label className="mb-3 text-black font-medium h-[22.5px] capitalize"></label>
                    <div className="flex h-full items-center gap-1">
                      {index === rows.length - 1 && (
                        <button
                          className="bg-[#2688D1] rounded-full w-[22px] h-[22px] flex justify-center items-center"
                          onClick={addRow}
                        >
                          <Icon path={mdiPlus} size={1} color={"white"} />
                        </button>
                      )}
                      {rows.length > 1 && (
                        <button
                          className="rounded-full w-[24px] h-[24px] flex justify-center items-center"
                          onClick={() => removeRow(index)}
                        >
                          <Icon path={mdiClose} size={1.3} color={"red"} />
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="my-4">
              <div>
                <span className="font-bold text-[15px] leading-[18.85px] text-black">
                  Favicon
                </span>
              </div>
              <div className="w-fit flex justify-end">
                {favUrl !== oldFile && (
                  <button
                    className="absolute  text-white bg-gray-800 rounded-full w-5 h-5 flex items-center justify-center"
                    onClick={handleClear}
                  >
                    x
                  </button>
                )}
                <label className="mt-2 outline outline-1 outline-[#959595] rounded-lg py-4 h-[75px] w-[75px] flex items-center justify-center mb-3 cursor-pointer">
                  <input
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={(e) => handleFileChange(e)} // Add your onChange handler
                  />
                  <img src={favUrl} alt="favicon-preview" className="my-4" />
                </label>
              </div>
              {/* {!savingImage ? (
                <button
                  className="mt-3 w-[122px] h-[32px] py-[5px] px-5 text-white font-medium text-[12px] rounded-[6px] outline outline-1 outline-[#2688D1] bg-[#2688D1]"
                  type="button"
                  onClick={updateImage}
                >
                  Change Image
                </button>
              ) : (
                <button
                  className="mt-3 w-[122px] h-[32px] py-[5px] px-5 text-white font-medium text-[12px] rounded-[6px] outline outline-1 outline-[#2688D1] bg-[#2688D1]"
                  type="button"
                >
                  <ButtonLoaderJoxy />
                </button>
              )} */}
            </div>
            <div className="flex justify-between">
              <div className="flex items-start">
                <input
                  type="checkbox"
                  id="remember-me"
                  name="rememberMe"
                  className="mr-1 mt-1 checked:bg-blue-600 checked:border-transparent rounded-lg border-black"
                  checked={hidePage}
                  onChange={() => {
                    setHidePage(!hidePage);
                  }}
                />
                <div className="flex flex-col max-w-[228px]">
                  <span
                    htmlFor="remember-me"
                    className="text-black leading-[17.6px] text-[14px] font-medium"
                  >
                    Hide my page from search engines
                  </span>
                  <span className="text-[#737373] leading-[12.57px] text-[10px] font-normal">
                    Note: make sure this is set the same for all variants
                  </span>
                </div>
              </div>
              {!saving ? (
                <button
                  className=" w-[82px] h-[32px] py-[5px] px-5 text-white font-medium text-[12px] rounded-[6px] outline outline-1 outline-[#2688D1] bg-[#2688D1]"
                  type="button"
                  onClick={saveProperties}
                >
                  Save
                </button>
              ) : (
                <button
                  className=" w-[82px] h-[32px] py-[5px] px-5 text-white font-medium text-[12px] rounded-[6px] outline outline-1 outline-[#2688D1] bg-[#2688D1]"
                  type="button"
                >
                  <ButtonLoaderJoxy />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer autoClose={2000} pauseOnHover={false} theme="colored" />
    </div>
  );
};

export default CustomEditPagePropModal;
