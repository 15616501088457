import {  GET_S3BUCKET_IMAGES_FAILURE, GET_S3BUCKET_IMAGES_SUCCESS, UPLOAD_S3BUCKET_IMAGES_FAILURE, UPLOAD_S3BUCKET_IMAGES_SUCCESS } from "./type";
import s3BucketService from "../services/s3Bucket.service";

export const getS3Images = (data) => (dispatch) => {
  return s3BucketService.getImagesFromS3(data).then(
    (response) => {
      dispatch({
        type: GET_S3BUCKET_IMAGES_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      dispatch({
        type: GET_S3BUCKET_IMAGES_FAILURE,
        payload: error,
      });
      return Promise.reject(error);
    }
  );
};

export const uploadImageToS3Bucket = (file,folder_name,file_name) => (dispatch) => {
  return s3BucketService.uploadImagesToS3(file,folder_name, file_name).then(
    (response) => {
      dispatch({
        type: UPLOAD_S3BUCKET_IMAGES_FAILURE,
        payload: response.data.data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      dispatch({
        type: UPLOAD_S3BUCKET_IMAGES_SUCCESS,
        payload: error,
      });
      return Promise.reject(error);
    }
  );
};



