import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk'; // Import any middleware you might be using
import grapejsReducer from './reducers/grapejsReducer';
import authReducer from './reducers/authReducer'; 
import chatGptReducer from './reducers/chatGptReducer'; // Adjust the path as needed
import s3BucketReducer from './reducers/s3BucketReducer';
import scraperReducer from './reducers/scraperReducer';

const store = configureStore({
  reducer: {
    grapejs: grapejsReducer,
    auth: authReducer,
    chatGpt: chatGptReducer,
    s3Bucket: s3BucketReducer,
    scraperReducer: scraperReducer,

    // Add other slices or reducers here if needed
  },
  middleware: [thunk],
});

export default store;