import React, { useCallback, useEffect, useRef, useState } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import { useDispatch } from "react-redux";
import moment from "moment";
import Datepicker from "react-tailwindcss-datepicker"; 
import {
  clickViewCount,
  getWebsiteByUserId,
  updateVariantsaByWebsiteId,
  updateVariantTitle,
  addNewVariant,
  disableWebsite,
  deleteWebsite,
  duplicateVariant,
} from "../actions/grapesjs";
import { mdiPencilBox, mdiEye, mdiPlusCircleOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { debounce } from "lodash";
import {
  ArrowDropDown,
  ArrowDropUp,
  CalendarMonth,
  Edit,
  MoreVert,
  Search,
} from "@mui/icons-material";
import { DateRangePicker } from "@nextui-org/date-picker";
import { ToastContainer, toast } from "react-toastify";
import CustomAddDomainModal from "./library/CustomAddDomainModal";
import CustomUseDomainModal from "./library/CustomUseDomainModal";
const Swal = require("sweetalert2");

const WebsiteList = ({
  menuOpen,
  handleMenuClick,
  handleMenuClose,
  isCalendarOpen,
  setIsCalendarOpen,
  isOpen,
  setIsOpen,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(20); // Items per page
  const [totalPages, setTotalPages] = useState(0);
  const [tabValue, setTabValue] = useState("1");
  const dispatch = useDispatch();
  const [totalDocuments, setTotalDocuments] = useState(0);
  const [filterBy, setFilterBy] = useState("");
  const [search, setSearch] = useState("");
  const [dateRange, setDateRange] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [useDomainModal, setUseDomainModal] = useState(false);
  const [publishUrl, setPublishUrl] = useState("");
  const [variant_Id, setvariantId] = useState("");
  const [websiteId, setwebsiteId] = useState("");
 
  const getWebsitesData = async () => {
    dispatch(
      getWebsiteByUserId({
        user_id: userId,
        page: currentPage,
        limit: perPage,
      })
    )
      .then((response) => {
        setData(response?.data.websites);
        setTotalPages(response.data.totalPages);
        setTotalDocuments(response.data.totalDocuments);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      });
  };

  const handleVariantStatus = (id, variantId) => {
    dispatch(
      updateVariantsaByWebsiteId({
        id: id,
        variantId: variantId,
      })
    )
      .then(async (response) => {
        await getWebsitesData();
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  const [view, setView] = useState(localStorage.getItem("view_count") || 0);
  useEffect(() => {
    // Update the state whenever the component mounts or the localStorage changes
    const handleStorageChange = () => {
      const updatedView = localStorage.getItem("view_count");
      setView(updatedView);
      if (filterBy === "date") {
        getFilteredData(dateRange, filterBy);
      }
      if (filterBy) {
        getFilteredData(search, filterBy);
      } else {
        getFilteredData();
      }
    };

    // Listen to localStorage changes
    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [view]);

  useEffect(() => {
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    const endDate = Math.floor(now.getTime()); // current timestamp in seconds
    const fromDate = new Date(now);
    fromDate.setDate(now.getDate() - 30);
    const startDate = Math.floor(fromDate.getTime()); // timestamp of 30 days ago in seconds

    setDateRange({ startDate, endDate });
    setFilterBy("date");
    getFilteredData({ startDate, endDate }, "date");
  }, []);

  const getDateValue = () => {
    return dateRange
      ? moment(dateRange.startDate).format("DD/MM/YYYY") +
          " - " +
          moment(dateRange.endDate).format("DD/MM/YYYY")
      : "";
  };
  const getWebsiteStatus = (row) => {
    if (row.website_status === "ai_formatted") {
      if (!row.generated_web_url) {
        return "In Progress";
      }
      if (row.isDisabled) {
        return "Disabled";
      }
      return row.generated_web_url && row.published_variant ? "Published" : "Pages Generated!";
    }
    return row.website_status.replace(/_/g, " ");
  };

  const getStatusClass = (row) => {    
   if( row.website_status === "ai_formatted" && !row.isDisabled) {
    return "bg-[#F2FFF7] border-[#00B448] text-[#00B448]"
   }
   if( row.website_status === "ai_formatted" && row.isDisabled) {
    return "bg-[#ffe8e8] border-[#e74c3c] text-[#e74c3c]"
   }
    return "bg-[#FFF6E8] border-[#FFCC81] text-[#FFCC81]"
  } 

  const handleDeleteWebsite = (id) => {
    Swal.fire({
      html: "<div><p style='font-size:20px; font-weight:700; margin-bottom:20px'>Are you sure you want to delete this page?</p><span style='font-size:14px; font-weight:400; margin-bottom:32px'>Once deleted, this page will be lost forever.</span></div>",
      customClass: {
        confirmButton: "btn-success",
        cancelButton: "btn-danger",
        closeButton: "close-button",
      },
      icon: "",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      showCloseButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteWebsite(id._id))
          .then(async (response) => {
            handleMenuClose();
            await getWebsitesData();
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      }
    });
  };

  const handleDisableWebsite = (website_id, isDisabled) => {
    Swal.fire({
      html: `<div><p style='font-size:20px; font-weight:700; margin-bottom:20px'>Are you sure you want to ${
        !isDisabled ? "enable" : "disable"
      } this page?</p>${
        !isDisabled
          ? ""
          : '<span style="font-size:14px; font-weight:400; margin-bottom:32px">Once disabled, visitors to the URL will see a blank page.</span>'
      }</div>`,
      customClass: {
        confirmButton: "btn-success",
        cancelButton: "btn-danger",
        closeButton: "close-button",
      },
      icon: "",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      showCloseButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(disableWebsite({ website_id, disable: isDisabled }))
          .then(async (response) => {
            handleMenuClose();
            await getWebsitesData();
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      }
    });
  };

  const handleDuplicateVariant = (variant_id) => {
    console.log({ variant_id });
    dispatch(duplicateVariant(variant_id))
      .then(async (response) => {
        handleMenuClose();
        await getWebsitesData();
        toast.success("The page has been duplicated");
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handleVariantTitle = (variantTitle, id, variantId) => {
    dispatch(
      updateVariantTitle({
        id: id,
        variantId: variantId,
        variantTitle,
      })
    )
      .then(async (response) => {
        await getWebsitesData();
        toast.success("Variant Title updated");
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  // Debounce the function
  const debouncedHandleVariantTitle = useCallback(
    debounce((value, id, variantId) => {
      handleVariantTitle(value, id, variantId);
    }, 1500),
    []
  ); // Adjust the debounce delay (1s in this example) as needed

  const debouncedHandleSearching = useCallback(
    debounce((value, filterBy) => {
      filterBy = value ? filterBy : "";
      getFilteredData(value, filterBy);
    }, 1500),
    []
  );

  const handleSearchedit = (value, filterBy) => {
    // const sort = document.getElementById('sortSelect');
    // sort.value = '';
    debouncedHandleSearching(value, filterBy);
  };

  const handleRangeEdit = (dateRange) => {
    console.log({dateRange})
    if(!dateRange.startDate && !dateRange.endDate){
      return getFilteredData();
    }
    // Extract start and end dates from the object
    var startDate = new Date( dateRange.startDate );
    var endDate = new Date( dateRange.endDate );
    setDateRange(dateRange);

    // Convert to timestamps (milliseconds) and then to seconds
    startDate = startDate.getTime();
    endDate = endDate.getTime();
    setIsCalendarOpen(false);
    getFilteredData({ startDate, endDate }, "date");
  };

  const getFilteredData = (search, filterBy) => {
    const data = {
      user_id: userId,
      page: currentPage,
      limit: perPage,
    };
    if (filterBy) {
      data.filterBy = filterBy;
      setFilterBy(filterBy);
    }
    if (search && typeof search === "string") {
      data.search = search;
      setSearch(search);
    }
    if (search && typeof search === "object") {
      data.from = search.startDate;
      data.to = search.endDate;
      setDateRange({
        startDate: search.startDate,
        endDate: search.endDate,
      });
    }
    dispatch(getWebsiteByUserId(data))
      .then((response) => {
        setData(response?.data.websites);
        setTotalPages(response.data.totalPages);
        setTotalDocuments(response.data.totalDocuments);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      });
  };

  const fetchDomain = (url) => {
    try {
      const urlObject = new URL(url);
      let domain = urlObject.hostname;

      // Remove "www." if it exists at the beginning
      domain = domain.replace(/^www\./, "");
      domain = domain.split(".")[0];

      return domain;
    } catch (error) {
      // Handle the error here, for example:
      let domain = url.replace(/^www\./, "");
      domain = domain.split(".")[0];

      return domain;
    }
  };

  const handleEditVariantPage = (websiteId, variant_id) => {
    // dispatch(
    //   clickViewCount({
    //     type: "click",
    //     variantId: variant_id,
    //   })
    // )
    //   .then(async (response) => {
    //     await getWebsitesData();
    //   })
    //   .catch((error) => {
    //     console.error("Error fetching data:", error);
    //   });

    // Construct the URL for the new tab
    const newTabUrl = `/web-builder/${websiteId}?v_id=${variant_id}`;

    // Open the URL in a new tab
    window.open(newTabUrl, "_blank");
  };

  const handleViewVariantPage = (url, variant_id, websiteId) => {
    // dispatch(
    //   clickViewCount({
    //     type: "view",
    //     variantId: variant_id,
    //   })
    // )
    //   .then(async (response) => {
    //     await getWebsitesData();
    //   })
    //   .catch((error) => {
    //     console.error("Error fetching data:", error);
    //   });

      const remove_string = `${websiteId}/`
      localStorage.setItem("view_count", 0);
      const new_url = url.replace(remove_string, "");
      console.log({new_url});

    window.open(new_url, "_blank");
  };

  const customStyles = {
    headCells: {
      style: {
        borderBottom: "1px dashed #999",
        flexBasis: 0,
        paddingLeft: "0px",
        color: "#000",
        background:"#fff",
      },
    },
    cells: {
      style: {
        borderBottom: "1px dashed #999",
        flexBasis: 0,
        paddingLeft: "0px",
      },
    },
  };
  const conditionalRowStyles = [
    {
      when: (row) => row.isDisabled,
      style: {
        backgroundColor: "#EEEEEE",
        color: "white",
        opacity: 0.7,
        // "&:hover": {
        //   cursor: "default",
        // },
      },
    },
  ];
  /* Theme Setting */
  createTheme("solarized", {
    text: {
      primary: "#2688D1",
      secondary: "#10176B",
    },
    background: {
      default: "transparent",
    },
    context: {
      background: "#cb4b16",
      text: "#FFFFFF",
    },
    divider: {
      default: "transparent",
    },
    button: {
      default: "#2aa198",
      hover: "rgba(0,0,0,.08)",
      focus: "rgba(255,255,255,.12)",
      disabled: "rgba(255, 255, 255, .34)",
    },
    sortFocus: {
      default: "#2aa198",
    },
  });

  const columns = [
    {
      name: " Source Url",
      cell: (row) => (
        <div>
          <p className="text-[12px] font-bold">
            {fetchDomain(row.website_url)}
          </p>
          <span className="block text-[8px] mt-2 font-medium text-[#999999] max-w-[236px] hover:text-[#0000ff]">
            <a
              href={row.website_url}
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              {row.website_url.split("?")[0]}{" "}
            </a>
          </span>
        </div>
      ),
    },
    {
      name: " Live Url",
      cell: (row) => (
        <div>
          <p className="text-[12px] font-medium max-w-[236px] font-[ceraProRegular] hover:text-[#0000ff]">
           {row.published_variant && <a href={row.publish_url} target="_blank" rel="noreferrer">              
              {row.publish_url}
            </a>}
          </p>
        </div>
      ),
    },
    {
      name: "Last Saved ",
      selector: (row) =>{return row.last_modified ? moment(row.last_modified).format("DD-MMM-YYYY HH:mm zz") : ""}
    },
    {
      name: "Website Status",
      selector: (row) => row.website_status, // Use selector if you only need to display a string
      cell: (row) => (
        <div
          className={`py-2.5 px-[15px] border ${getStatusClass(row)} `}
        >
          {getWebsiteStatus(row)}
        </div>
      ),
    },
    {
      name: "",
      maxWidth: "50px",
      minWidth: "50px",
      cell: (row) => (
        <div
          className="max-w-[50px]"
          // onMouseEnter={() => {
          //   menuOpen === row._id ? handleMenuClose() : handleMenuClick();
          // }}
          // onMouseLeave={handleMenuClose}
        >
          <MoreVert onClick={() => handleMenuClick(row._id)} />
          {menuOpen === row._id && (
            <div
            // onMouseLeave={handleMenuClose}
            >
              <ul
                id="account-menu"
                onClick={handleMenuClose}
                // onMouseLeave={handleMenuClose}
                className="bg-[black] text-[white] w-[122px] absolute right-[0px] z-10"
              >
                <span className="block w-[122px] h-[1px] bg-[#1F1F1F]"></span>
                <li
                  className="px-1.5 py-2.5 w-[122px]"
                  onClick={() => {
                    handleDisableWebsite(row._id, !row.isDisabled);
                  }}
                >
                  {row.isDisabled ? "Enable" : "Disable"}
                </li>
                <span className="block w-[122px] h-[1px] bg-[#1F1F1F]"></span>
                <li
                  className="px-1.5 py-2.5 w-[122px]"
                  onClick={() => {
                    handleDeleteWebsite(row);
                  }}
                >
                  Delete
                </li>
                {row.published_variant && row.publish_url&&<>
                <span className="block w-[122px] h-[1px] bg-[#1F1F1F]"></span>
                <li
                  className="px-1.5 py-2.5 w-[122px]"
                  onClick={() => {
                    setIsModalOpen(true);
                    setPublishUrl(row.publish_url);
                    setvariantId(row.published_variant)
                    setwebsiteId(row._id)
                  }}
                >
                  Add Domain
                </li>
                {/* <span className="block w-[122px] h-[1px] bg-[#1F1F1F]"></span>
                <li
                  className="px-1.5 py-2.5 w-[122px]"
                  onClick={() => {
                    setUseDomainModal(true);
                    setPublishUrl(row.publish_url);
                    setvariantId(row.published_variant)
                    setwebsiteId(row._id)
                  }}
                >
                  Use My Existing Connected Domain
                </li> */}
                </>}
                <span className="block w-[122px] h-[1px] bg-[#1F1F1F]"></span>
              </ul>
            </div>
          )}
        </div>
      ),
    },
  ];

  const addVariantClick = async (websiteId) => {
    console.log(websiteId, "data");
    dispatch(await addNewVariant({ userId: userId, id: websiteId }))
      .then((response) => {
        Swal.fire({
          title: "Your new variant has been generated",
          html: `<div style="font-size:16px;padding-bottom:12px">Your live URL is  <a  style="color:#3085d6" href=${`${process.env.REACT_APP_URL}/web-builder/${response.data.websiteId}?v_id=${response.data.id}`} target="_blank">${`${process.env.REACT_APP_URL}/web-builder/${response.data.websiteId}?v_id=${response.data.id}`}</a></div>`,
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Got it",
          // cancelButtonText: "Got it",
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "No more variants can be added",
          icon: "error",
          showCancelButton: false,
          cancelButtonText: "Got it",
        });
      });
  };

  const CustomRow = ({ data }) => (
    <div class="p-2.5 border-dashed border border-[#5a8bd9]">
      <div
        className="flex cursor-pointer"
        onClick={() => {
          addVariantClick(data._id);
        }}
      >
        <div>
          <Icon path={mdiPlusCircleOutline} size={1} color="#5a8bd9" />
        </div>
        <div>Add Variant</div>
      </div>
    </div>
  );
  const handleCellEdit = (rowId, field, value) => {
    const updatedData = data.map((item) =>
      item.id === rowId ? { ...item, [field]: value } : item
    );
    setData(updatedData);
  };

  const EditableCell = ({ row, field, value, onChange }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [cellValue, setCellValue] = useState(value);

    const handleCellClick = () => setIsEditing(true);
    const handleBlur = () => {
      setIsEditing(false);
      onChange(row.id, field, cellValue);
    };
    const handleChange = (value, row) => {
      setCellValue(value);
      debouncedHandleVariantTitle(value, row.websiteId, row._id);
    };

    return isEditing ? (
      <>
        <button className="btn btn-outline btn-xs disabled me-5">
          <div className="bg-[#F6F6F6] rounded p-2 w-[max-content]">
            <span>{row.variantName}</span>
          </div>
        </button>
        <input
          type="text"
          value={cellValue}
          onChange={(e) => handleChange(e.target.value, row)}
          onBlur={handleBlur}
          autoFocus
          className="rounded-lg w-full px-1.5 py-2.5 outline outline-1 outline-[#2688D1]"
        />
      </>
    ) : (
      <>
        <button className="btn btn-outline btn-xs disabled me-5">
          <div className="bg-[#F6F6F6] rounded p-2 w-[max-content]">
            <span>{row.variantName}</span>
          </div>
        </button>
        <div
          onClick={handleCellClick}
          className="whitespace-nowrap text-ellipsis overflow-hidden me-0.7"
        >
          {cellValue}
        </div>
        <span onClick={handleCellClick} className="cursor-pointer">
          <Edit fontSize="xs" sx={{ color: "#999999" }} />
        </span>
      </>
    );
  };

  const ExpandedComponent = ({ data }) => {
    if (data.website_status === "ai_formatted") {
      const expandedColumns = [
        {
          name: "Variant Name",
          cell: (row) => (
            <EditableCell
              row={row}
              value={row.variantTitle}
              onChange={handleCellEdit}
            />
          ),
        },
        {
          name: "Last Saved",
          selector: (row) => {
           return row.saved_at? moment(row.saved_at).format("DD-MMM-YY HH:mm"): '';
          },
        },
        {
          name: "Status",
          selector: (row) =>
            row.editor_html_content && row.editor_css_content ? (
              <div
                className={`py-2.5 px-[15px] border ${ row.status && data.isDisabled ?"bg-[#ffe8e8] border-[#e74c3c] text-[#e74c3c]": (row.status && data.published_variant===row._id
                    ? "bg-[#F2FFF7] border-[#00B448] text-[#00B448]"
                    : "bg-[#FFF6E8] border-[#FFCC81] text-[#FFCC81]")
                }`}
              >
                {row.status && data.isDisabled ? "Disabled":(row.status && data.published_variant===row._id ? "Published" : "Not Published")}
              </div>
            ) : (
              <div className="py-2.5 px-[15px] border bg-[#FFF6E8] border-[#FFCC81] text-[#FFCC81]">
                In Progress
              </div>
            ),
        },
        // {
        //   name: "Weight",
        //   selector: (row) =>("100%"),
        // },
        {
          name: "Views",
          selector: (row) => (row.view_count ? row.view_count : 0),
        },
        {
          name: "Clicks",
          selector: (row) => (row.click_count ? row.click_count : 0),
        },
        {
          name: "CTR",
          selector: (row) => {
            const click_count = row.click_count ? row.click_count : 0;
            const view_count = row.view_count ? row.view_count : 0;
            return view_count !== 0
              ? ((click_count / view_count) * 100 || 0).toFixed(2) + "%"
              : "0%";
          },
        },
        {
          name: "View Page",
          cell: (row) =>
            row.editor_html_content &&
            row.editor_css_content && (
              <button
                className="btn btn-outline btn-xs"
                onClick={() => {
                  handleViewVariantPage(row.generated_web_url, row._id, row.websiteId);
                }}
              >
                <div className="bg-[#F6F6F6] rounded p-2">
                  <Icon path={mdiEye} size={1} color="#999" />
                </div>
              </button>
            ),
        },
        {
          name: "Edit Page",
          cell: (row) =>
            row.editor_html_content &&
            row.editor_css_content && (
              <button
                className="btn btn-outline btn-xs"
                onClick={() => {
                  handleEditVariantPage(row.websiteId, row._id);
                }}
              >
                <div className="bg-[#F6F6F6] rounded p-2">
                  <Icon path={mdiPencilBox} size={1} color="#999" />
                </div>
              </button>
            ),
        },
        // {
        //   name: "Enable/Disable",
        //   cell: (row) =>
        //     row.editor_html_content &&
        //     row.editor_css_content && (
        //       <input
        //         type="checkbox"
        //         checked={row.status}
        //         onClick={() => handleVariantStatus(row.websiteId, row._id)}
        //       />
        //     ),
        // },
        {
          name: "",
          maxWidth: "50px",
          minWidth: "50px",
          cell: (row) => (
            <div className="max-w-[50px]">
              <MoreVert onClick={() => handleMenuClick(row._id)} />
              {menuOpen === row._id && (
                <div>
                  <ul
                    id="account-menu"
                    onClick={handleMenuClose}
                    className="bg-[black] text-[white] w-[122px] absolute right-[0px] z-10"
                  >
                    <span className="block w-[122px] h-[1px] bg-[#1F1F1F]"></span>
                    <li
                      className="px-1.5 py-2.5 w-[122px] cursor-pointer"
                      onClick={() => {
                        handleDuplicateVariant(row._id);
                      }}
                    >
                      Duplicate
                    </li>
                  </ul>
                </div>
              )}
            </div>
          ),
        },
      ];

      return (
        <>
          <DataTable
            columns={expandedColumns}
            data={data.variants}
            noHeader
            pagination={false}
            customStyles={customStyles}
          />
          {/* <CustomRow data={data} />TO DO: it will be enabled in future, and need to work on loader and other issues */}
        </>
      );
    } else {
      return null;
    }
  };

  const token = localStorage.getItem("user");
  // Parse the JSON string to get the object
  const userData = JSON.parse(token);

  // Access the "id" property from the user object
  const userId = userData.user.id;

  useEffect(() => {
    if (filterBy === "date") {
      getFilteredData(dateRange, filterBy);
    }
    if (filterBy) {
      getFilteredData(search, filterBy);
    } else {
      getFilteredData();
    }
  }, [currentPage, perPage, userId]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerPageChange = (newPerPage, currentPage) => {
    setPerPage(newPerPage);
    setCurrentPage(currentPage);
  };
  const [selectedOption, setSelectedOption] = useState("Select an option");
  const customSelectWrapper = useRef(null);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option, value) => {
    setSelectedOption(option);
    setIsOpen(false);
    const searchPage = document.getElementById("searchPage");
    searchPage.value = "";
    getFilteredData(value, "sort");
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        customSelectWrapper.current &&
        !customSelectWrapper.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div>
      <div className=" sm:px-[120px] sm:py-[42px] px-4 py-6">
        <div className={`bg-white rounded-lg ${isLoading ? "h-[530px]" : ""}`}>
          <div
            className="px-6"
            style={{
              width: "100%",
              height: "100%",
              typography: "body1",
              overflowY: "auto",
              "&::WebkitScrollbar": {
                display: "none",
              },
              scrollbarWidth: "none",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className="flex justify-between mt-8 mb-6 overflow-visible min-h-[40px]">
              <div className=" font-cera-pro-bold my-auto text-[24px]">
                My Projects
              </div>

              <div className="flex gap-2 flex-col lg:flex-row">
                <div className="flex items-center gap-3 h-[40px]">
                  <p className="text-[12px] font-medium">Sort:</p>
                  <div
                    className={`custom-select-wrapper w-[199px] ${
                      isOpen ? "top-[39px] relative" : "relative"
                    }`}
                    ref={customSelectWrapper}
                  >
                    <div
                      className={`flex justify-between custom-select-trigger text-[12px] font-medium py-[10px] px-[12px] bg-white cursor-pointer outline outline-[0.5px] ${
                        isOpen
                          ? "rounded-t-lg outline-[#000000]"
                          : "rounded-lg outline-[#E2E8F0]"
                      }`}
                      onClick={handleToggle}
                    >
                      <span
                        className={`${
                          selectedOption.includes("Select")
                            ? "text-[#A0AEC0]"
                            : "text-[#000000]"
                        }`}
                      >
                        {selectedOption}{" "}
                      </span>
                      {!isOpen ? (
                        <span aria-label="arrow">
                          <ArrowDropDown
                            onClick={handleToggle}
                            fontSize="small"
                            color="dark"
                          />
                        </span>
                      ) : (
                        <span aria-label="arrow">
                          <ArrowDropUp
                            onClick={handleToggle}
                            fontSize="small"
                            color="dark"
                          />
                        </span>
                      )}
                    </div>
                    {isOpen && (
                      <div
                        className="custom-options relative bg-[black] text-[white] z-10 w-[inherit] rounded-b"
                        onMouseLeave={handleToggle}
                      >
                        <div
                          className="custom-option cursor-pointer py-[10.75px] ps-4 text-[12px] font-medium hover:bg-[#FFFFFF26]"
                          onClick={() =>
                            handleOptionClick("Date created", "createdAt")
                          }
                          data-value="1"
                        >
                          Date created
                        </div>
                        <div
                          className="custom-option cursor-pointer py-[10.75px] ps-4 text-[12px] font-medium hover:bg-[#FFFFFF26]"
                          onClick={() =>
                            handleOptionClick("Date saved", "updatedAt")
                          }
                          data-value="2"
                        >
                          Date saved
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex items-center py-[10px] px-[12px] gap-3 rounded-lg bg-white overflow-hidden outline outline-[0.5px] outline-[#E2E8F0]  w-[237px]  h-[40px] lg:w-[199px]">
                  <div className="grid place-items-center">
                    <Search fontSize="small" />
                  </div>

                  <input
                    className="h-full w-full text-[12px] font-medium pr-2 focus-visible:outline focus-visible:outline-none"
                    type="search"
                    id="searchPage"
                    placeholder="Search by page name"
                    onChange={(e) => {
                      handleSearchedit(e.target.value, "name");
                    }}
                  />
                </div>
                <div className="h-[40px]">
                  <Datepicker 
                      value={dateRange} 
                      onChange={handleRangeEdit} 
                      containerClassName="flex rounded-lg bg-white overflow-hidden outline outline-[0.5px] outline-[#E2E8F0] h-[40px] pl-2 right-0 w-[237px] lg:w-[226px]"
                      toggleClassName="w-[10%] text-[12px] font-medium" 
                      inputClassName="h-full w-[80%] text-[12px] font-medium pr-2 focus-visible:outline focus-visible:outline-none" 
                      displayFormat={"MM/DD/YYYY"} 
                      primaryColor={"fuchsia"} 
                      // useRange={false} 
                      showShortcuts={true} 
                      popoverDirection="down" 
                      maxDate={new Date()}
                    />
                </div>
              </div>
            </div>
            {isLoading ? (
              <div className="flex gap-3 my-1.5 flex-1 justify-center items-center">
                <div className="scrapper-gif-image">
                  <img
                    src="/images/new_theme/Finalize-Loading-Animation.gif"
                    className="h-12"
                    alt="animation loader"
                  />
                </div>
              </div>
            ) : (
              tabValue === "1" && (
                <div
                  onMouseDown={() => {
                    setIsCalendarOpen(false);
                    // handleMenuClose();
                  }}
                >
                  <DataTable
                    // title="Website Data"
                    columns={columns}
                    data={data}
                    theme="solarized"
                    pagination
                    paginationServer
                    paginationPerPage={perPage}
                    paginationRowsPerPageOptions={[5, 10, 20, 30]} // Customize as per your requirement
                    paginationTotalRows={totalDocuments} // Assuming you get totalPages from backend
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handlePerPageChange}
                    highlightOnHover
                    pointerOnHover
                    customStyles={customStyles}
                    expandableRows={true}
                    expandOnRowClicked={true}
                    expandOnRowDoubleClicked={true}
                    expandableRowsHideExpander={true}
                    expandableRowsComponent={ExpandedComponent}
                    // conditionalRowStyles={conditionalRowStyles}
                    onRowMouseLeave={handleMenuClose}
                    fixedHeader={true}
                    fixedHeaderScrollHeight={'450px'}
                  />
                </div>
              )
            )}
          </div>
          {isModalOpen && (
        <CustomAddDomainModal
          setIsModalOpen={setIsModalOpen}
          setUseDomainModal={setUseDomainModal}
          publish_url = {publishUrl}
          variant_Id = {variant_Id}
          websiteId= {websiteId}
        />
      )}
      {useDomainModal && (
        <CustomUseDomainModal
          setUseDomainModal={setUseDomainModal}
          publish_url = {publishUrl}
          variant_Id = {variant_Id}
          websiteId= {websiteId}
        />
      )}
        </div>
        <ToastContainer autoClose={2000} pauseOnHover={false} theme="colored" />
      </div>
      {/* </div> */}
    </div>
  );
};

export default WebsiteList;
