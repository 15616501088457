import axios from "axios";
import { authApi } from './AxiosInstance';
const API_URL = process.env.REACT_APP_API_URL;

  const chatBoxOpenAI = (message) => {
    return authApi.post(API_URL + `template/${message[1]}/chatBoxOpenAI`, {
      data:message[0],
    });
  };

const ChatGPTService = {
  chatBoxOpenAI,
}

export default ChatGPTService;