import { SET_MESSAGE, ADD_SCRIPT_SUCCESS, ADD_SCRIPT_FAILURE, GET_SCRIPT_SUCCESS, GET_SCRIPT_FAILURE, DELETE_SCRIPT_SUCCESS, DELETE_SCRIPT_FAILURE, EDIT_SCRIPT_SUCCESS, EDIT_SCRIPT_FAILURE, ADD_METADATA_SUCCESS, ADD_METADATA_FAILURE, UPDATE_FAVICON_SUCCESS, UPDATE_FAVICON_FAILURE, GET_METADATA_SUCCESS, GET_METADATA_FAILURE, GET_APPLIED_SCRIPT_SUCCESS, GET_APPLIED_SCRIPT_FAILURE } from "../actions/type";
import ScriptService from "../services/script.service";

export const addScript = (data) => (dispatch) => {
  return ScriptService.addScript(data).then(
    (response) => {
      dispatch({
        type: ADD_SCRIPT_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: ADD_SCRIPT_FAILURE,
        payload: error.response.data,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject(error.response.data);
    }
  );
};

export const getScripts = (userId) => (dispatch) => {
    return ScriptService.getScripts(userId).then(
      (response) => {
        dispatch({
          type: GET_SCRIPT_SUCCESS,
          payload: response.data,
        });
        return Promise.resolve(response);
      },
      (error) => {
        dispatch({
          type: GET_SCRIPT_FAILURE,
          payload: error,
        });
        return Promise.reject(error);
      }
    );
  }

  
export const getAppliedScriptsByWebsiteId = (websiteId, userId) => (dispatch) => {
  return ScriptService.getAppliedScriptsByWebsiteId(websiteId, userId).then(
    (response) => {
      dispatch({
        type: GET_APPLIED_SCRIPT_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      dispatch({
        type: GET_APPLIED_SCRIPT_FAILURE,
        payload: error,
      });
      return Promise.reject(error);
    }
  );
}

  export const deleteScripts = (id) => (dispatch) => {
    return ScriptService.deleteScript(id).then(
      (response) => {
        dispatch({
          type: DELETE_SCRIPT_SUCCESS,
          payload: response.data,
        });
        return Promise.resolve(response);
      },
      (error) => {
        dispatch({
          type: DELETE_SCRIPT_FAILURE,
          payload: error,
        });
        return Promise.reject(error);
      }
    );
  }

  export const editScripts = (data) => (dispatch) => {
    return ScriptService.editScripts(data).then(
      (response) => {
        dispatch({
          type: EDIT_SCRIPT_SUCCESS,
          payload: response.data,
        });
        return Promise.resolve(response);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        dispatch({
          type: EDIT_SCRIPT_FAILURE,
          payload: error.response.data,
        });
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject(error.response.data);
      }
    );
  };

  export const addMetaData = (data) => (dispatch) => {
    return ScriptService.addMetaData(data).then(
      (response) => {
        dispatch({
          type: ADD_METADATA_SUCCESS,
          payload: response.data,
        });
        return Promise.resolve(response);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        dispatch({
          type: ADD_METADATA_FAILURE,
          payload: error.response.data,
        });
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject(error.response.data);
      }
    );
  };

  export const updateFavicon = (data) => (dispatch) => {
    return ScriptService.updateFavicon(data).then(
      (response) => {
        dispatch({
          type: UPDATE_FAVICON_SUCCESS,
          payload: response.data,
        });
        return Promise.resolve(response);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        dispatch({
          type: UPDATE_FAVICON_FAILURE,
          payload: error.response.data,
        });
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject(error.response.data);
      }
    );
  };

  export const getMetaData = (websiteId) => (dispatch) => {
    return ScriptService.getMetaData(websiteId).then(
      (response) => {
        dispatch({
          type: GET_METADATA_SUCCESS,
          payload: response.data,
        });
        return Promise.resolve(response);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        dispatch({
          type: GET_METADATA_FAILURE,
          payload: error.response.data,
        });
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject(error.response.data);
      }
    );
  };
