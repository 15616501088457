export const dummyChat = {
  step_1: [{ step: 1, message: "Analyzing your webpage...", type: "text" }],
  step_2: [
    { step: 1, message: "Finished Analyzing your webpage!", type: "text" },
    { step: 2, message: "screen", type: "screenshot" },
    { step: 3, message: "Let’s build your page together.", type: "text" },
  ],
  step_3: [
    {
      step: 3,
      message:
        "Let’s start by selecting your hero image (you can edit this later using AI)",
      type: "text",
    },
    { step: 3, message: "", type: "HeroImage" },
    { step: 3, message: "", type: "buttons" },
    {
      step: 3,
      message:
        " You can upload an image later. Alternatively you can use AI to generate the hero image later as well.",
      type: "button_one_rply",
    },
  ],
  step_4: [
    {
      step: 4,
      message:
        " Let’s start by selecting theme color (you can edit this later using AI)",
      type: "text",
    },
    { step: 4, message: "", type: "colors" },
    { step: 4, message: "", type: "buttons" },
    {
      step: 4,
      message:
        " You can upload an image later. Alternatively you can use AI to generate the hero image later as well.",
      type: "button_one_rply",
    },
  ],
  step_5: [
    {
      step: 5,
      message:
        " Let’s start by selecting your Logo image (you can edit this later using AI)",
      type: "text",
    },
    { step: 5, message: "", type: "logoImage" },
    { step: 5, message: "", type: "time message" },
  ],
  step_6: [
    {
      step: 6,
      message: " I’ve created 4 landing page variants for you!",
      type: "text",
    },
    { step: 6, message: "", type: "variant" },
  ],
};

export const dummyChat2 =  [
  { step: 1, message: "I’ll create 4 different landing pages for you. Let’s go!", type: "text"},
  { step: 1, message: "I’m analyzing your webpage!", type: "text"},
  { step: 2, message: "Finished analyzing your webpage!", type: "text"},
  { step: 2, message: "website screenshot", type: "screenshot",},
  { step: 2, message: "I'll start by detecting your brand colors and logo.", type: "text"},
  { step: 2, message: "At times I make mistakes. If I do, you can tell me, and I can change it later.", type: "text"},
  { step: 3, message: "I was able to identify the following colors as your brand colors.", type: "text"},
  { step: 3, message: "color section", type: "colors"},
  { step: 4, message: "I was able to identify the below image as your logo.", type: "text"},
  { step: 4, message: "logoImage", type: "logoImage"},
  { step: 4, message: "I am creating 4 landing page variants for you!", type: "text"},
  { step: 4, message: "I'll create 1 variant based on your brand colors and 3 based on colors I choose.", type: "text"},
  { step: 4, message: "I'll create your page using placeholder images. Once the page is created, you can instruct me to change the images.", type: "text"},
  { step: 4, message: "I'll generate pages that are 80% completed. You can give me further instructions, and we can work together to get the page 100% perfect.", type: "text"},
  { step: 5, message: "variant list", type: "variants"},

];

export const addData = {
  review: [
    {
      childClassToClone: "testimonial",
      childClassWhereToClone: "slider",
    },
    {
      childClassToClone: "testimonial_outer",
      childClassWhereToClone: "slider",
    },
    {
      childClassToClone: "testimonial_gradient",
      childClassWhereToClone: "slider_box",
    }
  ],
  faq: [
    {
    childClassToClone: "faq-card",
    childClassWhereToClone: "faq-cards",
    },
  ],
  benefits: 
  [
    {
      childClassToClone: "benefits_card_item",
      childClassWhereToClone: "benefits_card",
    },
    {
      childClassToClone: "designType",
      childClassWhereToClone: "benefits_card_alternate",
    },
    {
      childClassToClone: "designType",
      childClassWhereToClone: "benefits_card",
    },
    {
      childClassToClone: "designType",
      childClassWhereToClone: "benefits_card_gradient",
    },
    {
      childClassToClone: "benefits_card_item",
      childClassWhereToClone: "benefits_card",
    },
    {
      childClassToClone: "designType",
      childClassWhereToClone: "benefits_card_gradient",
    }
  ],
  work: 
  [
    {
      childClassToClone: "howItWork",
      childClassWhereToClone: "work-slider",
    },
    {
      childClassToClone: "howItWork",
      childClassWhereToClone: "slider",
    },
    {
      childClassToClone: "work_step",
      childClassWhereToClone: "work_steps",
    },
    {
      childClassToClone: "howItWork",
      childClassWhereToClone: "work-slider",
    }  
  ],
  main: 
  [
    {
      childClassToClone: "header_mark",
      childClassWhereToClone: "header-tick",
    },
  ],
  
};

export const removeData = {
  review: {
    class: "testimonial",
  },
  faq: {
    class: "faq-card",
  },
  benefits: {
    class: "",
  },
  work: {
    class: "howItWork",
  },
};

export const findComponentType = (component, type) => {
  let leftCount = 0;
  let rightCount = 0;

  // Recursive function to traverse through all children and descendants
  const traverseChildren = (currentComponent) => {
    // Check if the current component has child components
    currentComponent.components().each((childComponent, index) => {
      const attributes = childComponent.getAttributes();

      // Check design type for benefits section only
      if (type && attributes && attributes.designtype) {
        const designType = attributes.designtype;
        if (designType === "left") {
          leftCount++;
        } else if (designType === "right") {
          rightCount++;
        }
      } else {
        // Recursively search within the child component's hierarchy
        traverseChildren(childComponent);
      }
    });
  };

  // Start traversing from the parent component
  traverseChildren(component);

  return {
    leftCount,
    rightCount,
  };
};

export const findCloneComponentByType = (component, designCount) => {
  let clonedComponent = null;
  let childIndex;
  let childComponentData;
  let componetHtml;
  let type;

  if (designCount.leftCount > designCount.rightCount) {
    type = "right";
  }

  if (designCount.leftCount < designCount.rightCount) {
    type = "left";
  }

  if (designCount.leftCount == designCount.rightCount) {
    type = "left";
  }

  // Recursive function to traverse through all children and descendants
  const traverseChildren = (currentComponent) => {
    // Check if the current component has child components
    currentComponent.components().each((childComponent, index) => {
      const attributes = childComponent.getAttributes();
      if (attributes && attributes.designtype == type) {
        childIndex = index;
        childComponentData = childComponent;
        componetHtml = childComponent.toHTML();
        // Clone the child component
        clonedComponent = childComponent.clone();
        return false; // Exit the loop once the child component is cloned
      } else {
        // Recursively search within the child component's hierarchy
        traverseChildren(childComponent);
      }
    });
  };

  // Start traversing from the parent component
  traverseChildren(component);

  return {
    clonedComponent,
    childIndex,
    childComponentData,
    componetHtml,
  };
};

export const findAndCloneChildComponent = (component, childClass) => {
  let clonedComponent = null;
  let childIndex;
  let childComponentData;
  let componetHtml;

  // Recursive function to traverse through all children and descendants
  const traverseChildren = (currentComponent) => {
    // Check if the current component has child components
    currentComponent.components().each((childComponent, index) => {
      const classes = childComponent.getClasses();

      // Check if the current child component has the class to clone
      if (classes.includes(childClass)) {
        childIndex = index;
        childComponentData = childComponent;
        componetHtml = childComponent.toHTML();
        // Clone the child component
        clonedComponent = childComponent.clone();
        return false; // Exit the loop once the child component is cloned
      } else {
        // Recursively search within the child component's hierarchy
        traverseChildren(childComponent);
      }
    });
  };

  // Start traversing from the parent component
  traverseChildren(component);

  return {
    clonedComponent,
    childIndex,
    childComponentData,
    componetHtml,
  };
};

export const findAncestor = (component, ComponentClass) => {
  let topLevelAncestor = component;
  while (topLevelAncestor.parent() && topLevelAncestor.parent().parent()) {
    topLevelAncestor = topLevelAncestor.parent();
    if (ComponentClass == "designtype") {
      const attributes = topLevelAncestor.getAttributes();
      // Check design type for benefits section only
      if (attributes && attributes.designtype) {
        return topLevelAncestor; // Return the ancestor if found
      }
    }
    // Check if the top-level ancestor contains the class "ComponentClass"
    if (topLevelAncestor.getClasses().includes(ComponentClass)) {
      return topLevelAncestor; // Return the ancestor if found
    }
  }
  return null; // Return null if not found
};

export const findTopLevelAncestor = (component) => {
  let topLevelAncestor = component;
  while (topLevelAncestor.parent() && topLevelAncestor.parent().parent()) {
    topLevelAncestor = topLevelAncestor.parent();
  }
  return topLevelAncestor;
};

// Function to recursively collect IDs of all child components
export const collectChildIds = (component) => {
  const childIds = [];
  component.components().forEach((childComponent) => {
    childIds.push(childComponent.getId());
    // Recursively collect IDs of grandchildren
    childIds.push(...collectChildIds(childComponent));
  });
  return childIds;
};

export const workData = (updatedHtml, step) => {
  // Define the patterns for replacements
  const srcPattern = /<img[^>]*src="([^"]*)"/;
  const paragraphPattern = /(<p[^>]*>)([\s\S]*?)(<\/p>)/;
  const h4Pattern = /(<h4[^>]*>)([\s\S]*?)(<\/h4>)/;
  const h2Pattern = /(<h2[^>]*>)([\s\S]*?)(<\/h2>)/;
  // Perform replacements for content
  return updatedHtml
    .replace(paragraphPattern, (match, openTag, content, closeTag) => {
      return (
        openTag +
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ac erat ipsum. Donec sed mollis mauris, id placerat odio. Cras in massa ac lectus ultricies pretium quis eget libero." +
        closeTag
      );
    })
    .replace(h4Pattern, (match, openTag, content, closeTag) => {
      return openTag + "Lorem ipsum dolor sit amet. " + closeTag;
    })
    .replace(h2Pattern, (match, openTag, content, closeTag) => {
      return openTag + `Step: ${step}` + closeTag;
    })
    .replace(srcPattern, (match, src) => {
      return `<img src="https://joxy-web-scrapper-ai.s3.amazonaws.com/74203cac2c453e0f7b6b168a91e6d5c0/images/1709558411400_image.png"`;
    });
};

export const faqData = (updatedHtml, step) => {
  // Define the patterns for replacements
  const paragraphPattern = /(<p[^>]*>)([\s\S]*?)(<\/p>)/;
  const h4Pattern = /(<h4[^>]*>)([\s\S]*?)(<\/h4>)/;
  // Perform replacements for content of paragraph, heading 5, and heading 4
  return updatedHtml
    .replace(paragraphPattern, (match, openTag, content, closeTag) => {
      return (
        openTag +
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ac erat ipsum. Donec sed mollis mauris, id placerat odio. Cras in massa ac lectus ultricies pretium quis eget libero. Maecenas placerat augue tellus, eu aliquet urna auctor vel. Nam tincidunt dapibus dapibus." +
        closeTag
      );
    })
    .replace(h4Pattern, (match, openTag, content, closeTag) => {
      return openTag + "Lorem ipsum dolor sit amet. " + closeTag;
    });
};

export const reviewData = (updatedHtml, step) => {
  // Define the patterns for replacements
  const paragraphPattern = /(<p[^>]*>)([\s\S]*?)(<\/p>)/;
  const h5Pattern = /(<h5[^>]*>)([\s\S]*?)(<\/h5>)/;
  const h4Pattern = /(<h4[^>]*>)([\s\S]*?)(<\/h4>)/;

  // Perform replacements for content of paragraph, heading 5, and heading 4
  return updatedHtml
    .replace(paragraphPattern, (match, openTag, content, closeTag) => {
      return (
        openTag +
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry." +
        closeTag
      );
    })
    .replace(h5Pattern, (match, openTag, content, closeTag) => {
      return openTag + "Writer & Actor" + closeTag;
    })
    .replace(h4Pattern, (match, openTag, content, closeTag) => {
      return openTag + "Pete Smith" + closeTag;
    });
};

export const benefitsData = (updatedHtml, step) => {
  // Define the patterns for replacements
  const paragraphPattern = /(<div[^>]*class="benefits_text"[^>]*>)([\s\S]*?)(<\/div>)/;
  const h2Pattern = /(<div[^>]*class="benefits_heading"[^>]*>)([\s\S]*?)(<\/div>)/;
  const h2PatternSpan = /(<span[^>]*class="benefits_heading"[^>]*>)([\s\S]*?)(<\/span>)/;
  const h2PatternSpan_1 = /(<span[^>]*class="benefits_heading_span"[^>]*>)([\s\S]*?)(<\/span>)/;

  // Define the pattern for the src attribute
  const srcPattern = /<img[^>]*src="([^"]*)"/;

  console.log({updatedHtml});

  // Check if updatedHtml matches the h2PatternSpan
  const matchesH2PatternSpan = h2PatternSpan.test(updatedHtml);
  const matchesH2PatternSpan_1 = h2PatternSpan_1.test(updatedHtml);

const finalH2Pattern = matchesH2PatternSpan ? h2PatternSpan : h2Pattern;
if(matchesH2PatternSpan_1){
  // Perform replacements for content of paragraph, heading, and image
  return updatedHtml
    .replace(paragraphPattern, (match, openTag, content, closeTag) => {
      return (
        openTag +
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ac erat ipsum. Donec sed mollis mauris, id placerat odio. Cras in massa ac lectus ultricies pretium quis eget libero. Maecenas placerat augue tellus, eu aliquet urna auctor vel. Nam tincidunt dapibus dapibus." +
        closeTag
      );
    })
    .replace(finalH2Pattern, (match, openTag, content, closeTag) => {
      return openTag + "Lorem ipsum dolor" + closeTag;
    })
    .replace(h2PatternSpan_1, (match, openTag, content, closeTag) => {
      return openTag + "sit amet" + closeTag;
    })
    .replace(srcPattern, (match, src) => {
      return `<img src="https://joxy-template.s3.amazonaws.com/images_v2/placeholder/placeholder.svg"`;
    });
}else{
  return updatedHtml
    .replace(paragraphPattern, (match, openTag, content, closeTag) => {
      return (
        openTag +
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ac erat ipsum. Donec sed mollis mauris, id placerat odio. Cras in massa ac lectus ultricies pretium quis eget libero. Maecenas placerat augue tellus, eu aliquet urna auctor vel. Nam tincidunt dapibus dapibus." +
        closeTag
      );
    })
    .replace(finalH2Pattern, (match, openTag, content, closeTag) => {
      return openTag + "Lorem ipsum dolor sit amet" + closeTag;
    })
    .replace(srcPattern, (match, src) => {
      return `<img src="https://joxy-template.s3.amazonaws.com/images_v2/placeholder/placeholder.svg"`;
    });
}
};

export const mainData = (updatedHtml, step) => {

  // Define the pattern to target the <span> with class="header_mark_text"
  const spanPattern = /(<span class="header_mark_text"[^>]*>)([\s\S]*?)(<\/span>)/;

  // Perform the replacement for the content
  return updatedHtml.replace(spanPattern, (match, openTag, content, closeTag) => {
    // You can customize the replacement text as needed
    const replacementText = "Lorem ipsum dolor sit amet.";
    return openTag + replacementText + closeTag;
  });
};

// Function to recursively collect classes of all child components
export const collectChildClasses = (component) => {
  const childClasses = [];
  component.components().forEach((childComponent) => {
    const componentClasses = childComponent.getClasses();
    childClasses.push(...componentClasses);
    // Recursively collect classes of grandchildren
    childClasses.push(...collectChildClasses(childComponent));
  });
  return childClasses;
};

export const chatBoxData = () => {
  return {
    section: {
      main:
        "if user is talking about header section then give it true otherwise false",
      benefits:
        "if user is talking about benefits section then give it true otherwise false",
      reviews:
        "if user is talking about reviews section then give it true otherwise false",
      faq: "if user is talking about faq section then give it true otherwise false",
      howItWorks:
        "if user is talking about howItWorks section then give it true otherwise false",
    },
    action: {
      add: "if user is talking about add action then give it true otherwise false",
      remove:
        "if user is talking about remove action then give it true otherwise false",
    },
    item: {
      benefit:
        "if user is talking about benefit item then give it true otherwise false",
      review:
        "if user is talking about review item then give it true otherwise false",
      faq: "if user is talking about faq item then give it true otherwise false",
      howItWorks:
        "if user is talking about howItWorks item then give it true otherwise false",
      headerBullets:
        "if user is talking about bullet points item then give it true otherwise false",
      button:
        "if user is talking about button item then give it true otherwise false",
    },
  };
};

export const addButton = (buttonType) => {
  let parentClassToFind,
    childClassToClone,
    childClassWhereToClone,
    new_class,
    new_id,
    flex_class;
  switch (buttonType) {
    case "header_button":
      parentClassToFind = "main";
      childClassToClone = "header_button";
      childClassWhereToClone = "header-btn";
      new_class = "header_button_two";
      new_id = "header_button_two";
      flex_class = "header_two_button";
      break;
    case "benefits_button":
      parentClassToFind = "benefits";
      childClassToClone = "benefits_button";
      childClassWhereToClone = "joxy-benefits-btn";
      new_class = "benefits_button_two";
      new_id = "benefits_button_two";
      flex_class = "benefits_two_button";
      break;
    case "review_button":
      parentClassToFind = "review";
      childClassToClone = "review_button";
      childClassWhereToClone = "joxy-review-btn";
      new_class = "review_button_two";
      new_id = "review_button_two";
      flex_class = "review_two_button";
      break;
    case "service_button":
      parentClassToFind = "service";
      childClassToClone = "service_button";
      childClassWhereToClone = "joxy-service-btn";
      new_class = "service_button_two";
      new_id = "service_button_two";
      flex_class = "service_two_button";
      break;
    default:
      parentClassToFind = "main";
      childClassToClone = "header_button";
      childClassWhereToClone = "header-btn";
      new_class = "header_button_two";
      new_id = "header_button_two";
      flex_class = "header_two_button";
  }
  return {
    childClassToClone,
    childClassWhereToClone,
    new_class,
    new_id,
    flex_class,
    parentClassToFind,
  };
};


// Define a mapping object for your functions
export const functionMap = {
  main: mainData,
  review: reviewData,
  benefits: benefitsData,
  work: workData,
  faq: faqData,
};

export const replaceImagesWithThumbnails = (str) => {
  if (!str) {
    return str;
  }
  return str.replace("/images/", "/thumbnail/");
};

export const checkPlaceholderExists = (url) => {
  return url.includes('/placeholder/');
};
