import React, { useState, useRef, useEffect } from "react";
import basestyle from "../Base.module.css";
import loginstyle from "./Login.module.css";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useNavigate, NavLink } from "react-router-dom";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import { login, googleLogin } from "../../actions/auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import Footer from "../../pages/Footer";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [user, setUserDetails] = useState({
    email: "",
    password: "",
    rememberMe : false
  });
  const [showPassword, setShowPassword] = useState(true);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const changeHandler = (e) => {
    const { name, value , type, checked} = e.target;
    setUserDetails({
      ...user,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  useEffect(() => {
  }, [user]);
  
  const handleGoogleLoginSuccess = async (credentialResponse) => {
    const googleUser = credentialResponse;

    // Example: Dispatch a Google login action
    dispatch(googleLogin(googleUser))
      .then((response) => {
        // Handle the response or navigate to the desired page
        navigate("/", { replace: true });
      })
      .catch((error) => {
        // Handle error as needed
        toast.error("Google login failed");
      });
  };
  const validateForm = (values) => {
    const error = {};
    const regex = /^[^\s+@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.email) {
      error.email = "Email is required";
    } else if (!regex.test(values.email)) {
      error.email = "Please enter a valid email address";
    }
    if (!values.password) {
      error.password = "Password is required";
    }
    return error;
  };

  const loginHandler = (e) => {
    e.preventDefault();
    setFormErrors(validateForm(user));
    setIsSubmit(true);
    // if (!formErrors) {

    // }
  };

  // const handleImageClick = () => {
  //   if (googleLoginRef.current) {
  //     googleLoginRef.current.click();
  //   }
  // };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      dispatch(login(user))
        .then(async (response) => {
          navigate("/", { replace: true });
        })
        .catch((error) => {
          toast.error(error.message);
          // Handle error as needed
        });
    }
  }, [formErrors]);
  return (
    <>
      <div className={`${basestyle.LoginFormBackground} grid`}>
        <div className="xl:m-0  m-auto">
        <div className="mb-7">
          <img
            src="/images/new_theme/Joxy.svg"
            className="scrapper-logo-color"
          />
        </div>
        <form className={basestyle.userForm}>
          <h1 className="font-cera-pro-bold text-xl font-bold leading-[33.6px] pb-7 text-center">
            Login
          </h1>
          <div className="mb-3 w-full">
            <label htmlFor="email" className="font-cera-pro-bold block text-sm font-medium leading-6 mb-2">
              Email Address
            </label>
            <div className={`relative ${basestyle.formInputBox}  w-full`}>
              <div className="inset-y-0 left-0 pr-3 flex items-center text-sm leading-5">
                <img
                  className={`h-6 text-gray-700 cursor-pointer`}
                  src="/images/new_theme/sms-tracking.svg"
                />
                <input
              className="w-full pl-[7.18px]  focus-visible:outline-none"
              type="email"
              name="email"
              id="email"
              placeholder="Enter Email Address"
              onChange={changeHandler}
              value={user.email}
            />
              </div>
            
            </div>
            <p className={basestyle.error}>{formErrors.email}</p>
          </div>
          <div className="mb-2 md:w-full" x-data="{ show: true }">
            <label
              htmlFor="password"
              className="font-cera-pro-bold block text-sm font-medium leading-6 mb-2"
            >
              Password
            </label>
            <div className={`relative ${basestyle.formInputBox}  w-full`}>
              <div className="inset-y-0 left-0 pr-3 flex items-center text-sm leading-5">
                <img
                  className={`h-6 text-gray-700 cursor-pointer`}
                  src="/images/new_theme/lock_icon.svg"
                />
                 <input
                type={showPassword ? "password" : "text"}
                className="w-full pl-[7.18px] focus-visible:outline-none"
                name="password"
                id="password"
                placeholder="Enter Password"
                onChange={changeHandler}
                value={user.password}
              />
              </div>
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                <img
                  className={`h-6 text-gray-700 cursor-pointer ${
                    showPassword ? "hidden" : "block"
                  }`}
                  onClick={togglePasswordVisibility}
                  src="/images/new_theme/eye.svg"
                />

                <img
                  className={`h-6 text-gray-700 cursor-pointer ${
                    showPassword ? "block" : "hidden"
                  }`}
                  fill="none"
                  onClick={togglePasswordVisibility}
                  src="/images/new_theme/eye-off.svg"
                />
              </div>
            </div>

            <p className={basestyle.error}>{formErrors.password}</p>
          </div>
          <div className="flex justify-between items-center mb-6">
            {/* <div className="mt-auto"> */}
            <div className="flex items-center">
              <input
                type="checkbox"
                id="remember-me"
                name="rememberMe"
                onChange={changeHandler}
                value={user.rememberMe}
                  className="mr-1 checked:bg-blue-600 checked:border-transparent rounded-lg border-black"
              />
              <label
                htmlFor="remember-me"
                className="text-black leading-[21px] text-xs font-normal"
              >
                Remember Me
              </label>
            </div>
            <a href="/forgot-password" className="text-[#2688D1] leading-[21px] text-xs underline">
              Forgot your password?
            </a>
          </div>
          <button
            className={`font-cera-pro-bold w-full ${basestyle.button_common}`}
            onClick={loginHandler}
          >
            Log In
          </button>
          <div className="mt-8 flex flex-row justify-center">
            <span className="absolute bg-white px-4 text-black">
              Or with Social Profile
            </span>
            <div className="mt-3 h-px w-full bg-[#6C757D]"></div>
          </div>
          <div className="flex justify-center mt-8 mr-2">
            <img className="mr-3" src="/images/new_theme/google.svg"  />
            
            {/* <GoogleLogin
            onSuccess={handleGoogleLoginSuccess}
            onError={() => {
              toast.error("Google login failed");
            }}
            useOneTap
          /> */}

       {/* <img 
             src="/images/new_theme/linkedin.svg" 
             onClick={handleImageClick} 
             style={{ cursor: 'pointer' }}
             alt="LinkedIn Login"
          />

         <div style={{ display: 'none' }}>
           <GoogleLogin
            clientId="YOUR_GOOGLE_CLIENT_ID" // Replace with your Google client ID
            onSuccess={handleGoogleLoginSuccess}
            onFailure={handleGoogleLoginError}
            cookiePolicy={'single_host_origin'}
            useOneTap
            render={renderProps => (
              <button onClick={renderProps.onClick} ref={googleLoginRef}>Google Login</button>
             )}
          />
       </div> */}

            <img className="mr-3" src="/images/new_theme/facebook.svg" />
            <img className="mr-3" src="/images/new_theme/twitter.svg" />
            <img src="/images/new_theme/linkedin.svg" />
          </div>
          <div className="mt-6 text-center text-black">
            Not yet registered?{" "}
            <a href="/signup" className="text-[#2688D1] underline">
              Register Now
            </a>
          </div>
        </form>
        {/* <div className="flex justify-center mt-4">
          <GoogleLogin
            onSuccess={handleGoogleLoginSuccess}
            onError={() => {
              toast.error("Google login failed");
            }}
            useOneTap
          />
        </div> */}
        <ToastContainer autoClose={2000} pauseOnHover={false} theme="colored" />
        </div>
        
      </div>
      {/* <Footer/> */}
    </>
  );
};
export default Login;
