import React, { useState } from "react";
import { mdiClose, mdiContentCopy } from "@mdi/js";
import Icon from "@mdi/react";
import { addDomain } from "../../actions/grapesjs";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import Swal from "sweetalert2";

const CustomAddDomainModal = ({
  setIsModalOpen,
  publish_url,
  websiteId,
  variant_Id,
  setUseDomainModal,
  ...props
}) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const location = useLocation();
  const [url, setUrl] = useState("");
  const [error, setError] = useState(null);
  const [pagePath, setPagePath] = useState("");

  // Function to extract query parameter from the URL
  const getQueryParam = (param) => {
    return new URLSearchParams(location.search).get(param);
  };

  const token = localStorage.getItem("user");
  // Extract the v_id from the query string
  const variantId = getQueryParam("v_id");

  // Parse the JSON string to get the object
  const userData = JSON.parse(token);
  // Access the "id" property from the user object
  const userId = userData.user.id;

  const addCustomDomain = () => {
    if (!url) {
      return setError("Input Domain Name");
    }
    const data = {
      userId: userId,
      websiteId: id ? id : websiteId,
      variantId: variantId ? variantId : variant_Id,
      domain: url,
      publish_url: publish_url,
      page_path: pagePath
    };
    dispatch(addDomain(data))
      .then(async (response) => {
        console.log({ response });
        if (response.data.message) {
          return setError(response.data.message);
        }
        setError("");
        setIsModalOpen(false);
        setUrl("");
        Swal.fire({
          title: "Configuring",
          imageUrl: "/images/publish.svg",
          imageWidth: 166,
          imageHeight: 162.52,
          imageAlt: "Custom image",
          customClass: {
            htmlContainer: "htmlContainer1",
            title: "custom-swal-title",
            confirmButton: "btn-gotit",
            closeButton: "close-button",
            popup: "popup",
            image: "customimg",
          },
          html: `<div style="margin-bottom:24px;"><span style="font-size:14px; font-weight:400;">We are configuring your domain. In most cases, this takes just a few minutes, but it can also take up to 24 hours. </span></div>`,
          showCancelButton: false,
          confirmButtonText: "Got it",
          showCloseButton: true,
        });
      })
      .catch((error) => {
        // Handle error as needed
        console.error("Error in adding domain", error);
      });
  };

  const copyUrl = (url) => {
    navigator.clipboard
      .writeText(url)
      .then(() => {
        console.log("URL copied to clipboard:", url);
        // Optionally, add a success message or some kind of feedback for the user.
      })
      .catch((error) => {
        console.error("Failed to copy URL:", error);
        // Optionally, handle the error or display a failure message to the user.
      });
  };

  return (
    <div className=" edit-moadal absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 ">
      <div className="bg-[white] rounded-xl relative w-[484px] ">
        <div className="flex justify-end pe-2 pt-2">
          <button
            className="outline outline-black outline-1 rounded-full"
            type="button"
            onClick={() => {
              setIsModalOpen(false);
              setUrl("");
            }}
          >
            <Icon size={0.9} path={mdiClose} />
          </button>
        </div>
        <div className="flex justify-center">
          <span className="text-[20px] text-black font-bold leading-6">
            Add a New Domain
          </span>
        </div>
        <div className="px-5 pt-6">
          <div className="">
            <span className="font-medium text-[15px] text-black leading-5">
              What is the domain you would like to use?
            </span>
            <input
              type="text"
              onChange={(e) => {
                setUrl(e.target.value);
                setError(null);
              }}
              className=" w-[100%] mt-2 outline outline-1 outline-[#959595] rounded-lg ps-[10px] py-[10px] font-medium text-[15px] text-black leading-5 placeholder:font-medium placeholder:text-[15px] placeholder:leading-5"
              name="url"
              placeholder="try.example.com"
              value={url}
            />
          </div>

          {error && (
            <div className="mt-1 mb-2">
              <span className="font-normal text-[14px] text-[red] leading-5">
                {error}
              </span>
            </div>
          )}
          <div className="mt-4">
            <span className="font-normal text-[14px] text-black leading-5">
              We recommend using a sub-domain like "try" or "get," but if you
              don’t have an existing website on that domain, you can also use
              "www."
            </span>
          </div>
          <div className="mt-3 mb-8">
            <span className="font-medium text-[15px] text-black leading-5">
              Page path
            </span>
            <input
              type="text"
              onChange={(e) => {
                setPagePath(e.target.value);
              }}
              className=" w-[100%] mt-2 outline outline-1 outline-[#959595] rounded-lg ps-[10px] py-[10px] font-medium text-[15px] text-black leading-5 placeholder:font-medium placeholder:text-[15px] placeholder:leading-5"
              name="pagePath"
              placeholder="abc-ab-abc"
              value={pagePath}
              autoComplete="off"
            />
          </div>

          {/* {publish_url && (
                  <div className="flex text-[black] pt-3 ps-1 text-[12px] cursor-pointer" onClick={()=>copyUrl(publish_url.replace("http://", ""))}>
                    <span>CNAME: {publish_url.replace("http://", "")}</span>
                    <span className="ms-2">
                     <Icon size={0.7} path={mdiContentCopy} color={'black'}/>
                    </span>
                  </div>
                )} */}

          <div className="text-center mb-10">
            <button
              type="button"
              className="rounded text-[12px] outline outline-1 outline-black text-center font-medium w-[79px] h-[27px] text-black btn-bg hover:outline-none hover:text-white"
              onClick={() => {
                setUrl("");
                setIsModalOpen(false);
              }}
            >
              Cancel
            </button>
            <button
              type="button"
              className="ms-2 outline outline-1 outline-black rounded leading-[15.08px] text-[12px] text-center h-[27px] font-medium px-2 text-black btn-bg hover:outline-none hover:text-white"
              onClick={() => {
                addCustomDomain();
              }}
            >
              Connect Domain
            </button>
            <button
              type="button"
              className="ms-2 outline outline-1 outline-black rounded leading-[15.08px] text-[12px] text-center h-[27px] font-medium px-2 text-black btn-bg hover:outline-none hover:text-white"
              onClick={() => {
                setUseDomainModal(true);
                setUrl("");
                setIsModalOpen(false);
              }}
            >
              Use My Existing Connected Domain
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomAddDomainModal;
