import React from 'react';
import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import { MAIN_BG_COLOR, MAIN_TXT_COLOR, cx } from './common';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  border: '2px solid #000',
  boxShadow: 24,
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  maxHeight: '90vh',
  p: 2,
};

const CustomModal = ({ children, title, close, ...props }) => {
  return (
    <Modal onClose={close} {...props}>
      <Fade in={props.open}>
        
          <div className="flex-grow overflow-y-auto">{children}</div>
        
      </Fade>
    </Modal>
  );
};

export default CustomModal;
