import {  GET_S3BUCKET_IMAGES_SUCCESS, GET_S3BUCKET_IMAGES_FAILURE , UPLOAD_S3BUCKET_IMAGES_SUCCESS, UPLOAD_S3BUCKET_IMAGES_FAILURE } from "../actions/type";

const initialState = {

};

export default function s3BucketReducer(state = initialState, action) {
  switch (action.type) {
    case GET_S3BUCKET_IMAGES_SUCCESS:
      return {
        ...state,
        s3Images: action.payload,
      };
    case GET_S3BUCKET_IMAGES_FAILURE:
      return {
        ...state,
        s3Images: action.payload,
      };
      case UPLOAD_S3BUCKET_IMAGES_SUCCESS:
        return {
          ...state,
          uploadToS3Bucket: action.payload,
        };
      case UPLOAD_S3BUCKET_IMAGES_FAILURE:
        return {
          ...state,
          uploadToS3Bucket: action.payload,
        };
            
            
    default:
      return state;
  }
}
