import React, { useState, useEffect } from "react";
import basestyle from "../Base.module.css";
import forgotPasswordStyle from "./ForgotPassword.module.css";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useNavigate, NavLink } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { forgetPassword } from "../../actions/auth";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [user, setUserDetails] = useState({
    email: "",
  });
  const changeHandler = (e) => {
    const { name, value } = e.target;
    setUserDetails({
      ...user,
      [name]: value,
    });
  };

  const SubmitHandler = (e) => {
    e.preventDefault();
    setFormErrors(validateForm(user));
    setIsSubmit(true);
  };

  const validateForm = (values) => {
    const error = {};
    const regex = /^[^\s+@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.email) {
      error.email = "Email is required";
    } else if (!regex.test(values.email)) {
      error.email = "Please enter a valid email address";
    }
    return error;
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      dispatch(forgetPassword(user.email))
        .then(async (response) => {
          // navigate("/login", { replace: true });
          toast.success('Sent password reset. Please check your email');
          setUserDetails({
            ...user,
            email: '',
          });
        })
        .catch((error) => {
          toast.error(error.message);
          // Handle error as needed
        });
    }
  }, [formErrors]);

  return (
    <>
       <div className={` ${basestyle.formBackground} grid`}>
      <div className="xl:m-0  m-auto">
        <div className="mb-7">
          <img
            src="/images/new_theme/Joxy.svg"
            className="scrapper-logo-color"
          />
        </div>
        <form className={basestyle.userForm}>
          <h1 className="font-cera-pro-bold text-xl font-bold leading-[33.6px] pb-7 text-center">
            Forgot Password?
          </h1>
          <p className="text-xs font-normal leading-4 mb-6  mx-auto xl:w-[287px] text-center">
            Enter the email address or mobile number you used when you joined
            and we will send you temporary password.
          </p>
          <div className="mb-8 w-full">
            <label for="email" className="font-cera-pro-bold block text-sm font-medium leading-6 mb-2">
            Email or Mobile Number
            </label>
            <div className={`relative ${basestyle.formInputBox}  w-full`}>
              <div className="inset-y-0 left-0 pr-3 flex items-center text-sm leading-5">
                <img
                  className={`h-6 text-gray-700 cursor-pointer`}
                  src="/images/new_theme/sms-tracking.svg"
                />
                <input
                  className="w-full pl-[7.18px]  focus-visible:outline-none"
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Enter Email Address"
                  onChange={changeHandler}
                  value={user.email}
                />
              </div>
            </div>
            <p className={basestyle.error}>{formErrors.email}</p>
          </div>

          <button
            className={`font-cera-pro-bold w-full ${basestyle.button_common}`}
            onClick={SubmitHandler}
          >
            Submit
          </button>
          <div className="mt-8 flex flex-row justify-center">
            <span className="absolute bg-white px-4 text-black">
              Or with Social Profile
            </span>
            <div className="mt-3 h-px w-full bg-[#6C757D]"></div>
          </div>
          <div className="flex justify-center mt-8">
            <img className="mr-3" src="/images/new_theme/google.svg" />
            <img className="mr-3" src="/images/new_theme/facebook.svg" />
            <img className="mr-3" src="/images/new_theme/twitter.svg" />
            <img src="/images/new_theme/linkedin.svg" />
          </div>
          <div className="mt-6 text-center text-black">
            Not yet registered?{" "}
            <a href="/signup" className="text-[#2688D1] underline">
              Register Now
            </a>
          </div>
        </form>
        <ToastContainer autoClose={2000} pauseOnHover={false} theme="colored" />
      </div>
      </div>
    </>
  );
};
export default ForgotPassword;
