import axios from "axios";
import { LOGIN_SUCCESS, LOGIN_FAILURE, REGISTER_FAILURE, REGISTER_SUCCESS, GOOGLE_LOGIN_FAILURE, GOOGLE_LOGIN_SUCCESS, SET_MESSAGE, FORGET_PASSWORD_SUCCESS, FORGET_PASSWORD_FAILURE, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAILURE } from "../actions/type";
import AuthService from "../services/auth.service";
import moment from "moment"
export const REFRESH_TOKEN_CONFIRMED_ACTION = '[refresh token action] refresh token';
export const LOGOUT_ACTION = '[Logout action] logout action';
export const LOGIN_USER_ACTION = '[login action] login user';


export const login = (data) => (dispatch) => {
  return AuthService.login(data).then(
    (response) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: response.data,
      });
      const data = {
        expiresIn: moment(response?.data?.tokens?.access?.expires).valueOf(),
        idToken: response?.data?.tokens?.access?.token,
        refreshToken: response?.data?.tokens?.refresh.token
      };
      const currentTime = Date.now();
      const expiresInMilliseconds = parseInt(data.expiresIn, 10) - parseInt(currentTime, 10);
   
      AuthService.saveTokenInLocalStorage(response.data, moment(response?.data?.tokens?.access?.expires).valueOf());
      // AuthService.runLogoutTimer(dispatch, expiresInMilliseconds);

      localStorage.setItem("user", JSON.stringify(response.data));
      return Promise.resolve(response);
    },
    (error) => {
      const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();
      dispatch({
        type: LOGIN_FAILURE,
        payload: error.response.data,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject(error.response.data);
    }
  );
};




export function saveUserDetails(navigate) {
  return (dispatch) => {
    const refreshToken = JSON.parse(localStorage.getItem('refreshToken'));
    AuthService.userDetails(refreshToken)
      .then((response) => {
        if (response.data && response.data.token) {
          const newAccessToken = response.data.token.access.token;
          localStorage.setItem('token', JSON.stringify(newAccessToken));
          localStorage.setItem('refreshToken', JSON.stringify(response.data.token.refresh.token));
        }    
        dispatch(loginSaveAction(response?.data?.user));
      })
      .catch((error) => {
        if (error.response?.data?.code === 401) {
          dispatch(Logout(navigate));
        }
        console.error(error);
      });
  };
}

export const refreshTokenHit =  (id, navigate)  => async (dispatch,navigate) =>{
  return await AuthService.refreshTokenApi(id)
      .then((response) => {
        dispatch(confirmRefreshToken(response.data));
        const data = {
          expiresIn: moment(response?.data?.access?.expires).valueOf(),
          idToken: response?.data?.tokens?.access?.token,
          refreshToken: response?.data?.tokens?.refresh.token
        };
        const currentTime = Date.now();

        const expiresInMilliseconds = parseInt(data.expiresIn, 10) - parseInt(currentTime, 10);
  
        AuthService.saveTokenInLocalStorage({tokens :response.data}, moment(response?.data?.access?.expires).valueOf());
        // AuthService.runLogoutTimer(dispatch, expiresInMilliseconds);
      })
      .catch((error) => {
        dispatch(Logout(navigate));
        console.error(error);
      });
  };

// export const refreshTokenHit = (id) => async (dispatch) => {
//   try {
//     const response = await AuthService.refreshTokenApi(id);
//     dispatch(confirmRefreshToken(response.data));
//     const data = {
//       expiresIn: moment(response?.data?.tokens?.access?.expires).valueOf(),
//       idToken: response?.data?.tokens?.access?.token,
//       refreshToken: response?.data?.tokens?.refresh?.token,
//     };
//     const currentTime = Date.now();
//     const expiresInMilliseconds = parseInt(data.expiresIn, 10) - parseInt(currentTime, 10);

//     AuthService.saveTokenInLocalStorage({ tokens: response.data }, moment(response?.data?.tokens?.access?.expires).valueOf());
//     AuthService.runLogoutTimer(dispatch, expiresInMilliseconds);

//     return response;
//   } catch (error) {
//     if (error.response?.data?.code === 401) {
//       dispatch(Logout());
//     }
//     console.error(error);
//     throw error;
//   }
// };






export function confirmRefreshToken(data) {
  return {
    type: REFRESH_TOKEN_CONFIRMED_ACTION,
    payload: data
  };
}


export function Logout(navigate) {

  const token = JSON.parse(localStorage.getItem('user'));
 
  if (token) {
    // AuthService.logoutUser(token?.refreshToken);
    // localStorage.removeItem('user');
    // localStorage.removeItem('refreshToken');
    localStorage.clear();


  }
  window.location.href = '/auth/login';

  // return <Redirect to="/login" />;
  navigate('/login'); // Use history.push to navigate to the login page

  // const token = JSON.parse(localStorage.getItem('token'));
  // if (token) {
  //   AuthService.logoutUser(token?.refreshToken);
  //   localStorage.removeItem('token');
  // }
  // // navigate('/auth/login');
  // return {
  //   type: LOGOUT_ACTION
  // };
}


export function loginSaveAction(data) {
  return {
    type: LOGIN_USER_ACTION,
    payload: data
  };
}


export const register = (data) => (dispatch) => {
  return AuthService.register(data).then(
    (response) => {
      dispatch({
        type: REGISTER_SUCCESS,
        payload: response.data.data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: REGISTER_FAILURE,
        payload: error.response.data,
      });

     dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject(error.response.data);
    }
  );
};

export const googleLogin = (data) => (dispatch) => {
  return AuthService.googleLogin(data).then(
    (response) => {
      dispatch({
        type: GOOGLE_LOGIN_SUCCESS,
        payload: response.data,
      });
      localStorage.setItem("user", JSON.stringify(response.data));
      return Promise.resolve(response);
    },
    (error) => {
      const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();
      dispatch({
        type: GOOGLE_LOGIN_FAILURE,
        payload: error.response.data,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject(error.response.data);
    }
  );
};



export const forgetPassword = (email) => (dispatch) => {
  return AuthService.forgetPassword(email).then(
    (response) => {
      dispatch({
        type: FORGET_PASSWORD_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();
      dispatch({
        type: FORGET_PASSWORD_FAILURE,
        payload: error.response.data,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject(error.response.data);
    }
  );
};

export const resetPassword = (password, token) => (dispatch) => {
  return AuthService.resetPassword(password, token).then(
    (response) => {
      dispatch({
        type: RESET_PASSWORD_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();
      dispatch({
        type: RESET_PASSWORD_FAILURE,
        payload: error.response.data,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject(error.response.data);
    }
  );
};
