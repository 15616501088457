import axios from "axios";
import { authApi } from './AxiosInstance';

const API_URL = process.env.REACT_APP_API_URL;

const template1 = (data) => {
  return authApi.post(API_URL + "template/templateById", {
    id: data.id,
  });
};

const getWebsiteByUserandId = (data) => {
  return axios.post(API_URL + "website/websiteById", {
    id: data.id,
  });
};

const getPreviewVariant = (id) => {
  return authApi.post(API_URL + "template/getPreviewVariant", {
    id,
  });
};

const addNewSection = (data) => {
  console.log(data,"-------------------data");
  return authApi.post(API_URL + "template/addNewSection", {
    name: data.name,
  });
};

const variantById = (data) => {
  return axios.post(API_URL + "website/variantById", {
    id: data.id,
  });
};
const getWebsiteStatus = (id) => {
  return axios.post(API_URL + "website/getWebsiteStatus", {
    id,
  });
};


const getWebsiteByUserId = (user_id) => {
  return authApi.post(API_URL + "website/getWebsiteByUserId", user_id);
};
const templateSection = (data) => {
  return authApi.post(API_URL + "template/template-section", {
    type: data.event,
    id: data.templatedId,
  });
};
const templateColor = (data) => {
  return authApi.post(API_URL + "template/color", {
    id: data.userId,
  });
};
const getTemplateByIdAndWebsite = (data) => {
  return authApi.post(API_URL + "template/templateByIdAndWebsite", {
    id: data.id,
    userId: data.userId,
    variantId: data.variantId ? data.variantId : null,
  });
};

const getSavedVariant = (data) => {
  return authApi.post(API_URL + "template/getSavedVariant", {
    id: data.id,
    variantId: data.variantId,
  });
}

const addNewVariant = (data) => {
  return authApi.post(API_URL + "template/addNewVariant", {
    id: data.id,
    userId: data.userId,
  });
}

const getAllSavedVariantsData = (variant_id, website_id) => {
  return authApi.post(API_URL + "template/getAllSavedVariantsData", {
    variant_id,
    website_id
  });
}

const revertSavedVariant = (saved_variant_id) => {
  return authApi.post(API_URL + "template/revertSavedVariant", {
    saved_variant_id
  });
}

const updateEditorDataByWebsiteId = (id, html, css, projectData) => {
  return authApi.post(API_URL + "website/updateEditorDataByWebsiteById", {
    id: id,
    html: html,
    css: css,
    projectData: projectData,
  });
};

const updateSavedDataInEditor = (id,variant_id) => {
  return authApi.post(API_URL + "website/updateSavedDataInEditor", {
    id: id,
    variantId: variant_id
  });
};


const removeSection = (id,type,classData,userId) => {
  return authApi.post(API_URL + "template/removeSection", {
    id:id,
    type:type,
    classData:classData,
    userId:userId
  });
};

const templateVariants = (data) => {
  return authApi.post(API_URL + "template/templateVariants", {
    type:data.type,
  });
};

const updateVariantsByWebsiteId = (data) => {
  return authApi.post(API_URL + "website/updateVariantByWebsiteById", {
   data
  });
};

const updateVariantTitle = (data) => {
  return authApi.post(API_URL + "website/editVariantName", {
   data
  });
};

const deleteWebsite = (id) => {
  return authApi.post(`${API_URL}website/deleteWebsite/${id}`);
};

const disableWebsite = (data) => {
  return authApi.post(API_URL + "website/disableWebsite", {
   data
  });
};

const duplicateVariant = (variantId) => {
  return authApi.post(API_URL + "website/duplicateVariant", {
   variantId
  });
};

const clickViewCount= (data) => {
  return authApi.post(API_URL + "website/clickViewCount", {
   data
  });
};

const saveUserVariant = (data) => {
  return authApi.post(API_URL + "website/saveUserVariant", {
    data
  });
};

const addDomain = (data) => {
  return authApi.post(API_URL + "domain/add-domain", data);
};

const getExistingDomains = (userId) => {
  return authApi.post(API_URL + "domain/get-domains/" + userId);
};

const existingDomains = (data) => {
  return authApi.post(API_URL + "domain/use-existing-domains", data);
};

const GrapesjsService = {
  template1,
  getWebsiteByUserandId,
  getTemplateByIdAndWebsite,
  updateEditorDataByWebsiteId,
  getWebsiteByUserId,
  removeSection,
  templateSection,
  templateColor,
  templateVariants,
  updateVariantsByWebsiteId,
  clickViewCount,
  variantById,
  saveUserVariant,
  updateSavedDataInEditor,
  getSavedVariant,
  updateVariantTitle,
  getPreviewVariant,
  addNewVariant,
  deleteWebsite,
  disableWebsite,
  getWebsiteStatus,
  duplicateVariant,
  addNewSection,
  getAllSavedVariantsData,
  revertSavedVariant,
  addDomain,
  getExistingDomains,
  existingDomains
};


export default GrapesjsService;
