import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getWebsiteStatus, variantById } from "../actions/grapesjs";
import { useDispatch } from "react-redux";
import { Box } from "@mui/material";
import { PageLoaderJoxy } from "../components/library/LoaderJoxy";

const PublishPage = () => {
  const dispatch = useDispatch();
  const [showHtml, setShowHtml] = useState("");
  const [showStyle, setShowStyle] = useState("");
  const { userId, websiteId } = useParams();
  const [loading, setLoading] = useState(false);
  const [websiteDisabled, setWebsiteDisabled] = useState(false);

  // Your dynamic HTML content
  useEffect(() => {
    setLoading(true);
    dispatch(getWebsiteStatus(websiteId))
      .then((res) => {
        if (res.data.isDisabled) {
          setLoading(false);
          return setWebsiteDisabled(true);
        }
        setWebsiteDisabled(false);
        dispatch(variantById({ id: userId }))
          .then(async (response) => {
            setLoading(false);
            setShowStyle(
              response.data.updated_css_content
                ? response.data.updated_css_content
                : response.data.editor_css_content
            );

            let cssContentWithFontFamily = response.data.updated_css_content
              ? response.data.updated_css_content
              : response.data.editor_css_content;
            cssContentWithFontFamily =
              cssContentWithFontFamily +
              ` @import url('${"https://fonts.googleapis.com/css2?family=Assistant&display=swap"}');
          ${
            // i == 0 ?
            "body"
            // : "h2 ,.header_heading_black, #about_title, .work-subHead, .service-title-one, .faq-heading"
          } {
          font-family: '${"Assistant"}', sans-serif; 
        } ${response?.data?.style_css[0]?.fontFace}`;
            // }
            setShowStyle(cssContentWithFontFamily);
            setShowHtml(
              response.data.updated_html_content
                ? response.data.updated_html_content
                : response.data.editor_html_content
            );
          })
          .catch((error) => {
            setLoading(false);
            // Handle error as needed
          });
      })
      .catch((error) => {
        setLoading(false);
        // Handle error as needed
      });
  }, [userId,websiteId, dispatch]);

  useEffect(() => {
    // Create a style element
    const styleElement = document.createElement("style");
    styleElement.type = "text/css";
    styleElement.id = "dynamic-styles";

    // Set the content of the style element with dynamic styles
    styleElement.appendChild(document.createTextNode(showStyle));

    // Remove the previous style element if it exists
    const prevStyleElement = document.getElementById("dynamic-styles");
    if (prevStyleElement) {
      document.head.removeChild(prevStyleElement);
    }

    // Append the style element to the head of the document
    document.head.appendChild(styleElement);

    // Cleanup function to remove the style element on component vunmount
    return () => {
      document.head.removeChild(styleElement);
    };
  }, [showStyle]);

  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <PageLoaderJoxy />
        </Box>
      ) : (
        <div>
          {websiteDisabled ? (
            <div>This page is no longer available.</div>
          ) : (
            <div dangerouslySetInnerHTML={{ __html: showHtml }} />
          )}
        </div>
      )}
    </>
  );
};

export default PublishPage;
