import React, { useEffect, useRef, useState } from "react";
import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { getS3Images, uploadImageToS3Bucket } from "../../actions/s3bucket";
import { replaceImagesWithThumbnails } from "../constants/jsonData";
import { getSavedVariant } from "../../actions/grapesjs";
import { ButtonLoaderJoxy, PageLoaderJoxy } from "./LoaderJoxy";
import { ToastContainer, toast } from "react-toastify";
import { ExpandLessOutlined, ExpandMoreOutlined } from "@mui/icons-material";

const CustomUploadImageModal = ({
  editorRef,
  setSelectedImage,
  setModalOpen,
  setIsUploadImageModalOpen,
  setMessageId,
  setIsCreateImageModalOpen,
  setOriginalImage,
  aiImages,
  ...props
}) => {
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const { id } = useParams();
  const location = useLocation();

  const [dragActive, setDragActive] = useState(false);
  const [url, setUrl] = useState("");
  const [selectImage, setSelectImage] = useState(null);
  const [savingImage, setSavingImage] = useState(false);
  const [selectedImageId, setSelectedImageId] = useState(null);
  const [assets, setAssets] = useState([]);
  const [moreImgLoading, setMoreImgLoading] = useState(false);
  const [invalidUrl,setInvalidUrl] = useState(false);
  const [expandedSection, setExpandedSection] = useState('saved');

  // Function to extract query parameter from the URL
  const getQueryParam = (param) => {
    return new URLSearchParams(location.search).get(param);
  };

  // Extract the v_id from the query string
  const variantId = getQueryParam("v_id");

  useEffect(() => {
    getBucketImages();
  }, []);



  // get s3 bucket images
  const getBucketImages = () => {
    setMoreImgLoading(true);
    dispatch(
      getSavedVariant({
        id: id,
        variantId: variantId,
      })
    )
      .then(async (response) => {
        setMoreImgLoading(true);
        if (response.data.assets_images) {
          setAssets(response.data.assets_images);
        }
          // dispatch(
          //   getS3Images({
          //     bucket_name: process.env.REACT_APP_S3_BUCKET,
          //     prefix: `${response.data.folder_name}/images/assets_images/`,
          //   })
          // )
          //   .then(async (response) => {
          //     setMoreImgLoading(false);
          //     setAssets(response.data);
          //   })
          //   .catch((error) => {
          //     // Handle error as needed
          //     console.log("Error in fetching s3 bucket images", error);
          // });
      })
      .catch((error) => {
        // Handle error as needed
        console.log("Error in fetching template", error);
      });
  };

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const reader = new FileReader();
      const file = e.dataTransfer.files[0];
      const acceptedFileTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/webp', 'image/gif'];
      if (!acceptedFileTypes.includes(file.type)) {
        toast.error('File type not supported. Please upload an image of type JPEG, JPG, PNG, WEBP, or GIF.');
        return;
      }
      reader.onloadend = () => {
        // setSelectImage(reader.result);
        setSelectedImage(reader.result);
        setOriginalImage(reader.result);
        setIsUploadImageModalOpen(false);
        setModalOpen(true);
        setSelectImage(null);
        setUrl("");
      };
      reader.readAsDataURL(e.dataTransfer.files[0]);
    }
  };

  // triggers when file is selected with click
  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      const reader = new FileReader();
      const file = e.target.files[0];
      const acceptedFileTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/webp', 'image/gif'];
      if (!acceptedFileTypes.includes(file.type)) {
        toast.error('File type not supported. Please upload an image of type JPEG, JPG, PNG, WEBP, or GIF.');
        return;
      }
      reader.onloadend = () => {
        // setSelectImage(reader.result);
        setSelectedImage(reader.result);
        setOriginalImage(reader.result);
        setIsUploadImageModalOpen(false);
        setModalOpen(true);
        setSelectImage(null);
        setUrl("");
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current.click();
  };

  const convertImageToDataURL = async (image_url) => {
    try {
      // const proxyUrl = 'http://cors-anywhere.herokuapp.com/';
      const response = await fetch(image_url);
      const blob = await response.blob();

      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onloadend = () => {
          resolve(reader.result);
        };

        reader.onerror = () => {
          reject(new Error("Error reading blob as Data URL"));
        };

        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error("Error converting image to Data URL:", error);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      // setSelectImage(url);
      setSelectedImage(url);
      setOriginalImage(url);
      setIsUploadImageModalOpen(false);
      setModalOpen(true);
      setSelectImage(null);
      setUrl("");
    }
  };
  const isValidURL = (url)=> {
    try {
        new URL(url);
        return true;
    } catch (e) {
        return false;
    }
}

  const getImage = async (dataURL, image_id) => {
    if (url && !dataURL) {
      if(!isValidURL(url)){
        console.log("here")
        return setInvalidUrl(true);
      }
      setInvalidUrl(false);
      setSelectedImage(url);
      setOriginalImage(url);
      setIsUploadImageModalOpen(false);
      setModalOpen(true);
      setSelectImage(null);
      setUrl("");
      // return setSelectImage(url);
      return
    }
    if (!dataURL) {
      return;
    }
    image_id ? setSelectedImageId(image_id) : setSelectedImageId(null);

    setSavingImage(true);
    const file = dataURL.startsWith("data:image")
      ? dataURL
      : await convertImageToDataURL(dataURL);

    dispatch(
      uploadImageToS3Bucket({
        file: file,
        folder_name: id,
      })
    )
      .then(async (response) => {
        if (response.data.file) {
          const selectedImage = editorRef.current.getSelected();
          editorRef.current.AssetManager.add(response.data.file);
          selectedImage.set("src", response.data.file);
          setUrl("");
          setIsUploadImageModalOpen(false);
          setSavingImage(false);
          setSelectImage(null);
        }
      })
      .catch((error) => {
        // Handle error as needed
        setSavingImage(false);
      });
  };

  return (
    <div className=" edit-moadal absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white px-7 py-10 rounded relative w-[1200px]">
        <div className="flex   justify-between">
          <button className="flex items-center text-[14px] gap-1.5 mb-[15px]"
           onClick={() => {
            setTimeout(() => {
              editorRef.current.runCommand("open-assets");
              setSelectImage(null);
              setUrl("");
              setIsUploadImageModalOpen(false);
            }, 500); // Wait for fade-out transition
          }}>
           <img
              src="/images/arrow.svg"
              alt="arrow"
              className="w-[14px]"
            />
            Back</button>
            <button
            type="button"
            onClick={() => {
              setIsUploadImageModalOpen(false);
              setSelectImage(null);
              setUrl("");
            }}
          >
            <Icon size={1} path={mdiClose} />
          </button>
        </div>
        <div className="flex">
          <h2>Upload/Select Image</h2>          
        </div>
        <div className="flex mt-10 w-[1140px] h-[446px]">
          <div className="outline outline-1 outline-[#959595] rounded-xl overflow-y-auto w-[878px] h-[100%] px-10 pt-8">
            {!selectImage && (
              <div>
                <h6>Upload Photos</h6>
                <form
                  className="mt-4"
                  id="form-file-upload"
                  onDragEnter={handleDrag}
                  onSubmit={(e) => e.preventDefault()}
                >
                  <input
                    ref={inputRef}
                    type="file"
                    id="input-file-upload"
                    multiple={true}
                    onChange={handleChange}
                    readOnly={savingImage}
                  />
                  <label
                    id="label-file-upload"
                    htmlFor="input-file-upload"
                    className={
                      dragActive ? "drag-active h-[188px]" : "h-[188px]"
                    }
                  >
                    <div className="flex flex-col items-center">
                      <img src="/images/select-image.svg" alt="upload-icon" />
                      <div className="text-[12px] flex items-center">
                        <p>Drop your image here, or</p>
                        <button
                          className="upload-button underline text-[12px] "
                          onClick={onButtonClick}
                          disabled={savingImage}
                        >
                          browse
                        </button>
                      </div>
                      <p className="text-[8px] text-[#969DB2]">
                        Supports: PNG, JPG, JPEG, WEBP
                      </p>
                    </div>
                  </label>
                  {dragActive && (
                    <div
                      id="drag-file-element"
                      onDragEnter={handleDrag}
                      onDragLeave={handleDrag}
                      onDragOver={handleDrag}
                      onDrop={handleDrop}
                    ></div>
                  )}
                </form>
                <div className="separator text-[#959DB2] text-[12px]">Or</div>
                <div className="mt-6">
                  <span className="font-bold text-[12px]">Import from URL</span>
                  <div className="mt-1 flex">
                    <input
                      type="url"
                      onChange={(e) => {
                        setUrl(e.target.value);
                        setInvalidUrl(false)
                      }}
                      className=" w-[100%] h-[40px] p-3 bg-[#F1F4F9] rounded-lg focus:outline-none"
                      name="url"
                      placeholder="Add file URL"
                      value={url}
                      onKeyDown={handleKeyDown}
                      readOnly={savingImage}
                    />
                    {/* <button
                        type="button"
                        className="bg-[#F1F4F9] rounded-e-lg text-[10px] py-[9px] pe-[9px] text-[#1A1A1A99]"
                        onClick={() => {
                          setSelectImage(url);
                        }}
                      >
                        Upload
                      </button> */}
                  </div>
                  {invalidUrl && <div className="text-[red]">Invalid Url</div>}
                </div>
              </div>
            )}
            {selectImage && (
              <div className="h-[350px] overflow-y-hidden">
                <img
                  src={selectImage}
                  alt="selected"
                  className="h-[100%] w-[100%] object-contain"
                />
              </div>
            )}
            <div className="text-end mt-4">
              <button
                type="button"
                className="rounded-lg text-[12px] outline outline-1 outline-[#1A1A1A2E] text-center font-medium w-[70px] h-[32px]"
                onClick={() => {
                  setSelectImage(null);
                  setUrl("");
                }}
                disabled={savingImage}
              >
                Cancel
              </button>
              {savingImage && !selectedImageId ? (
                <button
                  type="button"
                  className="ms-4 rounded-lg text-[12px] bg-[#2688D1] text-center font-medium text-white w-[70px] h-[32px]"
                  disabled
                >
                  {/* <CircularProgress size={16} color="inherit" /> */}
                  <ButtonLoaderJoxy />
                </button>
              ) : (
                <button
                  type="button"
                  className="ms-4 rounded-lg text-[12px] bg-[#2688D1] text-center font-medium text-white w-[70px] h-[32px]"
                  onClick={() => {
                    getImage(selectImage);
                  }}
                >
                  Save
                </button>
              )}
            </div>
          </div>

          <div>
          <div className={`${expandedSection==='saved'?'h-[86%]':''}`}>
            <div className="ms-3 flex items-center justify-between cursor-pointer" onClick={()=>{
              expandedSection==='ai_generated'?setExpandedSection('saved'):setExpandedSection('ai_generated')
            }}>
              <span className="font-bold text-sm">Saved Images</span>
              {expandedSection==='saved'?<span onClick={()=>setExpandedSection('ai_generated')}><ExpandLessOutlined /></span>:
              <span onClick={()=>setExpandedSection('saved')}><ExpandMoreOutlined /></span>}
            </div>
            {moreImgLoading && assets.length===0 && (
              <div className="flex ms-3 w-[248px] h-[95%] content-center flex-wrap">
                {/* <CircularProgress color="inherit" /> */}
                <ButtonLoaderJoxy />
              </div>
            )}
            {expandedSection==='saved'&&
            <div className="max-h-[90%] grid-container grid grid-cols-3 ms-3 mt-3 gap-1.5 overflow-y-auto">
              {assets.map((asset, index) => (
                <div
                  key={index}
                  className="image-box w-[76px] h-[76px] rounded logo-trans"
                >
                  <img
                    className="image"
                    src={replaceImagesWithThumbnails(asset)}
                    alt="background"
                  />
                  <div className="overlay w-[76px] h-[76px] rounded">
                    {savingImage && selectedImageId === index+"_id" ? (
                      <button
                        className="hover-btn disabled:cursor-default disabled:bg-[#3c3c3c] w-[39px] h-[28px]"
                        disabled
                      >
                        {/* <CircularProgress size={12} color="inherit" /> */}
                        <ButtonLoaderJoxy />
                      </button>
                    ) : (
                      <button
                        className="hover-btn disabled:cursor-default disabled:bg-[#3c3c3c] disabled:text-[#777777]"
                        // onClick={() => {
                        //   getImage(asset, index+"_id");
                        // }}
                        onClick={() => {
                          setTimeout(() => {
                            setIsUploadImageModalOpen(false);
                            setSelectedImage(asset);
                            setOriginalImage(asset)
                            setSelectImage(null);
                            setUrl("");
                            setModalOpen(true);
                          }, 500); // Wait for fade-out transition
                          
                        }}
                        disabled={savingImage}
                      >
                        Select
                      </button>
                    )}
                    {/* <button
                      className="hover-btn disabled:cursor-default disabled:bg-[#3c3c3c] disabled:text-[#777777]"
                      onClick={() => {
                        setSelectedImage(asset);
                        setIsUploadImageModalOpen(false);
                        setModalOpen(true);
                        setSelectImage(null);
                        setUrl("");
                      }}
                      disabled={savingImage}
                    >
                      Edit
                    </button> */}
                  </div>
                </div>
              ))}
            </div>}
          </div>

          {aiImages.length>0 &&
          <div className={`${expandedSection==='ai_generated'?'h-[86%] mt-6':'mt-6'}`}>
          <div className="ms-3 grid">
          <div className="flex items-center justify-between cursor-pointer" onClick={()=>{
              expandedSection==='ai_generated'?setExpandedSection('saved'):setExpandedSection('ai_generated')
            }}>
              <span className="font-bold text-sm">AI Generated Images</span>
              {expandedSection==='ai_generated'?<span onClick={()=>setExpandedSection('saved')}><ExpandLessOutlined /></span>:
              <span onClick={()=>setExpandedSection('ai_generated')}><ExpandMoreOutlined /></span>}
              </div>
              <span className="text-[10px]">(To modify a previously generated image click edit)</span>
              </div>
              {expandedSection==='ai_generated'&&<div className="max-h-[90%] grid-container grid grid-cols-3 ms-3 mt-3 gap-1.5 overflow-y-auto">
            {aiImages.map((img, index) => (
                  <div
                    key={index}
                    className="image-box w-[76px] h-[76px] rounded logo-trans"
                  >
                    <img className="image" src={img.thumbnailUri} alt="background" />
                    <div className="overlay w-[76px] h-[76px] rounded">
                      <button
                        className="hover-btn disabled:cursor-default disabled:bg-[#3c3c3c] disabled:text-[#777777]"
                        onClick={() => {
                          setTimeout(() => {
                            setIsUploadImageModalOpen(false);
                            setSelectedImage(img.imageUri);
                            setOriginalImage(img.imageUri);
                            setSelectImage(null);
                            setUrl("");
                            setModalOpen(true);
                          }, 500); // Wait for fade-out transition
                        }}
                        disabled={savingImage}
                      >
                        Select
                      </button>
                      <button
                        className="hover-btn disabled:cursor-default disabled:bg-[#3c3c3c] disabled:text-[#777777]"
                        onClick={() => {
                          setMessageId(img);
                          setIsCreateImageModalOpen(true);
                          setIsUploadImageModalOpen(false);
                        }}
                        disabled={savingImage}
                      >
                        Edit
                      </button>
                    </div>
                  </div>
                ))}
            </div>}
          </div>}
          </div>
        </div>
      </div>
      <ToastContainer autoClose={2000} pauseOnHover={false} theme="colored" />
    </div>
  );
};

export default CustomUploadImageModal;
