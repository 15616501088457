import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Navigate,
  Routes,
  Route,
  useMatch,
  useLocation,
} from "react-router-dom";
import Dashboard from "./components/dashbaord";
import GenerationFlow from "./components/generation-flow";
import WebBuilder from "./components/WebBuilder";
import Login from "./components/Login/Login";
import Register from "./components/Register/Register";
import PreviewPage from "./pages/PreviewPage";
import PublishPage from "./pages/PublishPage";
import DynamicContent from "./pages/DynamicContent";
import WebsiteList from "./components/WebsiteList";
import Footer from "./components/Footer";
import { saveUserDetails} from "./actions/auth"


import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import ChangePasword from "./components/ForgotPassword/ChangePassword";
import './App.css';

const App = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  let location = useLocation();

  const path = location.pathname;
  const dispatch = useDispatch();
  
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       await checkAutoLogin(dispatch);
  //     } catch (error) {
  //       // Handle error, possibly token expired
  //       console.error("Token expired or checkAutoLogin failed:", error);
  //       // Attempt to refresh access token
  //       // try {
  //       //   await refreshAccessToken(); // Assuming this function refreshes the access token
  //       //   // Retry original API call or any other necessary operations
  //       //   await checkAutoLogin(dispatch);
  //       //   // Now make subsequent API calls or perform operations
  //       //   // Example: dispatch(fetchUserData());
  //       // } catch (refreshError) {
  //       //   console.error("Failed to refresh access token:", refreshError);
  //       //   // Handle failed refresh, possibly redirect to login page
  //       //   // Example: dispatch(logout());
  //       // }
  //     }
  //   };

  //   fetchData();

  //   if (["/", "/signup"].includes(location.pathname)) {
  //     // dispatch(clearMessage()); // clear message when changing location
  //   }
  // }, [dispatch, location]);


  //   const fetchData = async () => {
  //     try {
  //       await auth.checkAutoLogin(dispatch);
  //     } catch (error) {
  //       console.error("Token expired or checkAutoLogin failed:", error);
  //     }
  //   };

  //   fetchData();

  //   if (["/", "/signup"].includes(location.pathname)) {
  //     // dispatch(clearMessage()); // clear message when changing location
  //   }

  useEffect(() => {
    let user = (localStorage.getItem('token'));
    if (user) {
      dispatch(saveUserDetails());
    }
  }, [dispatch ]);



  const token = localStorage.getItem("user");
  const isWebBuilderRoute = useMatch("/web-builder/:id");
  const isWebSiteRoute = useMatch("/:userId/:websiteName/index.html");
  const isWebSiteRoute1 = useMatch("/:variantId/:websiteName/index.html");
  const isWebSiteRoute2 = useMatch("/:userId/:websiteId/:websiteName/index.html");
  return (
    <div>
      <Routes>
        {token ? (
          <>
            <Route path="/" element={<Dashboard />} />
            <Route path="/page-processing/:id" element={<GenerationFlow />} />
            <Route path="/web-builder/:id" element={<WebBuilder />} />
            <Route path="/website-list" element={<WebsiteList />} />
            <Route path="*" element={<Dashboard />} />
          </>
        ) : (
          <>
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Register />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/change-password" element={<ChangePasword />} />
            <Route path="*" element={<Navigate to="/login" replace />} />
          </>
        )}

        <Route
          path="/:variantId/:websiteName/index.html"
          element={<PreviewPage />}
        />
        <Route
          path="/:userId/:websiteName/index.html"
          element={<DynamicContent />}
        />

        <Route
          path="/:userId/:websiteId/:websiteName/index.html"
          element={<PublishPage />}
        />


        {/* Add more routes as needed */}
      </Routes>
      {token && !isWebBuilderRoute && !isWebSiteRoute && !isWebSiteRoute2 && !isWebSiteRoute1 && <Footer url={path} />}
    </div>
  );
};

export default App;
