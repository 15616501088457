import {  CHATBOX_OPEN_AI_SUCCESS, CHATBOX_OPEN_AI_FAILURE } from "./type";
import ChatGPTService from "../services/chatgpt.service";

export const chatBoxOpenAI = (message) => (dispatch) => {
return ChatGPTService.chatBoxOpenAI(message).then(
    (response) => {
        dispatch({
            type: CHATBOX_OPEN_AI_SUCCESS,
            payload: response.data.data,
          });
          return Promise.resolve(response.data.data);
        },
        (error) => {
          dispatch({
            type: CHATBOX_OPEN_AI_FAILURE,
            payload: error,
          });
          return Promise.reject(error);
        }
)};