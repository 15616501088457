export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
export const TEMPLATE_1_SUCCESS = "TEMPLATE_1_SUCCESS";
export const TEMPLATE_1_FAILURE = "TEMPLATE_1_FAILURE";
export const SCRAPER_SUCCESS = "SCRAPER_SUCCESS";
export const SCRAPER_FAILURE = "SCRAPER_FAILURE";
export const USER_WEBSITE_SUCCESS = "USER_WEBSITE_SUCCESS";
export const USER_WEBSITE_FAILURE = "USER_WEBSITE_FAILURE";
export const USER_TEMPLATE_SUCCESS = "USER_TEMPLATE_SUCCESS";
export const USER_TEMPLATE_FAILURE = "USER_TEMPLATE_FAILURE";
export const USER_COLOR_SUCCESS = "USER_COLOR_SUCCESS";
export const USER_COLOR_FAILURE = "USER_COLOR_FAILURE";
export const TEMPLATE_SUCCESS = "TEMPLATE_SUCCESS";
export const TEMPLATE_FAILURE = "TEMPLATE_FAILURE";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";
export const GOOGLE_LOGIN_FAILURE = "GOOGLE_LOGIN_FAILURE";
export const GOOGLE_LOGIN_SUCCESS = "GOOGLE_LOGIN_SUCCESS";
export const FORGET_PASSWORD_SUCCESS = "FORGET_PASSWORD_SUCCESS";
export const FORGET_PASSWORD_FAILURE = "FORGET_PASSWORD_FAILURE";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";
export const CHATBOX_OPEN_AI_SUCCESS = "CHATBOX_OPEN_AI_SUCCESS";
export const CHATBOX_OPEN_AI_FAILURE = "CHATBOX_OPEN_AI_FAILURE";
export const UPDATE_EDITOR_SUCCESS = "UPDATE_EDITOR_SUCCESS";
export const UPDATE_EDITOR_FAILURE = "UPDATE_EDITOR_FAILURE";
export const WEBSITE_LIST_FAILURE = "WEBSITE_LIST_FAILURE";
export const WEBSITE_LIST_SUCCESS = "WEBSITE_LIST_SUCCESS";
export const GET_S3BUCKET_IMAGES_SUCCESS = "GET_S3BUCKET_IMAGES_SUCCESS";
export const UPLOAD_S3BUCKET_IMAGES_SUCCESS = "UPLOAD_S3BUCKET_IMAGES_SUCCESS";
export const GET_S3BUCKET_IMAGES_FAILURE = "GET_S3BUCKET_IMAGES_FAILURE";
export const UPLOAD_S3BUCKET_IMAGES_FAILURE = "UPLOAD_S3BUCKET_IMAGES_FAILURE";
export const  REMOVE_SECTION_FAILURE = "REMOVE_SECTION_FAILURE";
export const  REMOVE_SECTION_SUCCESS = "REMOVE_SECTION_SUCCESS";
export const SCRAPER_DATA_SUCCESS = "SCRAPER_DATA_SUCCESS";
export const SCRAPER_DATA_FAILURE = "SCRAPER_DATA_FAILURE";
export const UPDATE_SCRAPER_DATA_SUCCESS = "UPDATE_SCRAPER_DATA_SUCCESS";
export const UPDATE_SCRAPER_DATA_FAILURE = "UPDATE_SCRAPER_DATA_FAILURE";
export const TEMPLATE_VARIANTS_FAILURE = "TEMPLATE_VARIANTS_FAILURE";
export const TEMPLATE_VARIANTS_SUCCESS = "TEMPLATE_VARIANTS_SUCCESS";
export const AUTO_SKIP_SUCCESS = "AUTO_SKIP_SUCCESS";
export const AUTO_SKIP_FAILURE = "AUTO_SKIP_FAILURE";
export const BACKGROUND_REMOVE_SUCCESS = "BACKGROUND_REMOVE_SUCCESS";
export const BACKGROUND_REMOVE_FAILURE = "BACKGROUND_REMOVE_FAILURE";
export const UPDATE_VARIANT_SUCCESS = "UPDATE_VARIANT_SUCCESS";
export const UPDATE_VARIANT_FAILURE = "UPDATE_VARIANT_FAILURE";
export const GENERATE_IMAGE_SUCCESS = "GENERATE_IMAGE_SUCCESS";
export const GENERATE_IMAGE_FAILURE = "GENERATE_IMAGE_FAILURE";
export const GET_IMAGE_PROGRESS_SUCCESS = "GET_IMAGE_PROGRESS_SUCCESS";
export const GET_IMAGE_PROGRESS_FAILURE = "GET_IMAGE_PROGRESS_FAILURE";
export const IMAGE_CONTROLS_SUCCESS = "IMAGE_CONTROLS_SUCCESS";
export const IMAGE_CONTROLS_FAILURE = "IMAGE_CONTROLS_FAILURE";
export const CLICK_SUCCESS = "CLICK_SUCCESS";
export const CLICK_FAILURE = "CLICK_FAILURE";
export const USER_WEBSITE_PUBLISH_SUCCESS = "USER_WEBSITE_PUBLISH_SUCCESS";
export const USER_WEBSITE_PUBLISH_FAILURE = "USER_WEBSITE_PUBLISH_FAILURE";
export const GET_IMAGE_SUCCESS = "GET_IMAGE_SUCCESS";
export const GET_IMAGE_FAILURE = "GET_IMAGE_FAILURE";
export const SAVE_USER_VARIANT_FAILURE = "SAVE_USER_VARIANT_FAILURE";
export const SAVE_USER_VARIANT_SUCCESS = "SAVE_USER_VARIANT_SUCCESS";
export const EDITOR_CHANGE_STATUS = "EDITOR_CHANGE_STATUS";
export const EDITOR_PUBLISH_STATUS = "EDITOR_PUBLISH_STATUS";
export const SAVE_EDITOR_FAILURE = "SAVE_EDITOR_FAILURE";
export const SAVE_EDITOR_SUCCESS = "SAVE_EDITOR_SUCCESS";
export const SAVED_TEMPLATE_SUCCESS = "SAVED_TEMPLATE_SUCCESS";
export const SAVED_TEMPLATE_FAILURE = "SAVED_TEMPLATE_FAILURE";
export const UPDATE_VARIANT_TITLE_SUCCESS = "UPDATE_VARIANT_TITLE_SUCCESS";
export const UPDATE_VARIANT_TITLE_FAILURE = "UPDATE_VARIANT_TITLE_FAILURE";
export const PREVIEW_SUCCESS = "PREVIEW_SUCCESS";
export const PREVIEW_FAILURE = "PREVIEW_FAILURE";
export const ADD_NEW_VARIANTS_FAILURE = "ADD_NEW_VARIANTS_FAILURE";
export const ADD_NEW_VARIANTS_SUCCESS = "ADD_NEW_VARIANTS_SUCCESS";
export const DELETE_WEBSITE_SUCCESS = "DELETE_WEBSITE_SUCCESS";
export const DELETE_WEBSITE_FAILURE = "DELETE_WEBSITE_FAILURE";
export const DISABLE_WEBSITE_SUCCESS = "DISABLE_WEBSITE_SUCCESS";
export const DISABLE_WEBSITE_FAILURE = "DISABLE_WEBSITE_FAILURE";
export const USER_WEBSITE_STATUS_SUCCESS = "USER_WEBSITE_STATUS_SUCCESS";
export const USER_WEBSITE_STATUS_FAILURE = "USER_WEBSITE_STATUS_FAILURE";
export const DUPLICATE_WEBSITE_SUCCESS = "DUPLICATE_WEBSITE_SUCCESS";
export const DUPLICATE_WEBSITE_FAILURE = "DUPLICATE_WEBSITE_FAILURE";
export const ADD_NEW_SECTION_SUCCESS = "ADD_NEW_SECTION_SUCCESS";
export const ADD_NEW_SECTION_FAILURE = "ADD_NEW_SECTION_FAILURE";
export const GET_SAVED_TEMPLATE_SUCCESS = "GET_SAVED_TEMPLATE_SUCCESS";
export const GET_SAVED_TEMPLATE_FAILURE = "GET_SAVED_TEMPLATE_FAILURE";
export const UPDATE_SAVED_TEMPLATE_SUCCESS = "UPDATE_SAVED_TEMPLATE_SUCCESS";
export const UPDATE_SAVED_TEMPLATE_FAILURE = "UPDATE_SAVED_TEMPLATE_FAILURE";
export const ADD_DOMAIN_SUCCESS = "ADD_DOMAIN_SUCCESS"; 
export const ADD_DOMAIN_FAILURE = "ADD_DOMAIN_FAILURE";
export const SECTION_ADD_STATUS = "SECTION_ADD_STATUS";
export const GET_DOMAIN_SUCCESS = "GET_DOMAIN_SUCCESS";
export const GET_DOMAIN_FAILURE = "GET_DOMAIN_FAILURE";
export const USE_DOMAIN_SUCCESS = "USE_DOMAIN_SUCCESS";
export const USE_DOMAIN_FAILURE = "USE_DOMAIN_FAILURE";
export const ADD_SCRIPT_SUCCESS = "ADD_SCRIPT_SUCCESS";
export const ADD_SCRIPT_FAILURE = "ADD_SCRIPT_FAILURE";
export const GET_SCRIPT_SUCCESS = "GET_SCRIPT_SUCCESS";
export const GET_SCRIPT_FAILURE = "GET_SCRIPT_FAILURE";
export const EDIT_SCRIPT_SUCCESS = "EDIT_SCRIPT_SUCCESS";
export const EDIT_SCRIPT_FAILURE = "EDIT_SCRIPT_FAILURE";
export const DELETE_SCRIPT_SUCCESS = "DELETE_SCRIPT_SUCCESS";
export const DELETE_SCRIPT_FAILURE = "DELETE_SCRIPT_FAILURE";
export const ADD_METADATA_SUCCESS = "ADD_METADATA_SUCCESS";
export const ADD_METADATA_FAILURE = "ADD_METADATA_FAILURE";
export const UPDATE_FAVICON_SUCCESS = "UPDATE_FAVICON_SUCCESS";
export const UPDATE_FAVICON_FAILURE = "UPDATE_FAVICON_FAILURE";
export const GET_METADATA_SUCCESS = "GET_METADATA_SUCCESS";
export const GET_METADATA_FAILURE = "GET_METADATA_FAILURE";
export const GET_APPLIED_SCRIPT_SUCCESS = "GET_APPLIED_SCRIPT_SUCCESS";
export const GET_APPLIED_SCRIPT_FAILURE = "GET_APPLIED_SCRIPT_FAILURE";
