import { BACKGROUND_REMOVE_SUCCESS, BACKGROUND_REMOVE_FAILURE, SET_MESSAGE, GENERATE_IMAGE_SUCCESS, GENERATE_IMAGE_FAILURE, GET_IMAGE_PROGRESS_SUCCESS, GET_IMAGE_PROGRESS_FAILURE, IMAGE_CONTROLS_SUCCESS, IMAGE_CONTROLS_FAILURE,GET_IMAGE_SUCCESS, GET_IMAGE_FAILURE} from "../actions/type";
import ImageService from "../services/image.service";

export const removeBackground = (data) => (dispatch) => {
  return ImageService.removeBackground(data).then(
    (response) => {
      dispatch({
        type: BACKGROUND_REMOVE_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: BACKGROUND_REMOVE_FAILURE,
        payload: error.response.data,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject(error.response.data);
    }
  );
};

export const generateImage = (prompt) => (dispatch) => {
  return ImageService.generateImage(prompt).then(
    (response) => {
      dispatch({
        type: GENERATE_IMAGE_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: GENERATE_IMAGE_FAILURE,
        payload: error.response.data,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject(error.response.data);
    }
  );
};

export const getGeneratedImages = (userId, websiteId) => (dispatch) => {
  return ImageService.getGeneratedImages(userId, websiteId).then(
    (response) => {
      dispatch({
        type: GET_IMAGE_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: GET_IMAGE_FAILURE,
        payload: error.response.data,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject(error.response.data);
    }
  );
};

export const getImageProgress = (messageId) => (dispatch) => {
  return ImageService.getImageProgress(messageId).then(
    (response) => {
      dispatch({
        type: GET_IMAGE_PROGRESS_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: GET_IMAGE_PROGRESS_FAILURE,
        payload: error.response.data,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject(error.response.data);
    }
  );
};

export const imageControls = (data) => (dispatch) => {
  return ImageService.imageControls(data).then(
    (response) => {
      dispatch({
        type: IMAGE_CONTROLS_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: IMAGE_CONTROLS_FAILURE,
        payload: error.response.data,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject(error.response.data);
    }
  );
};
