import React, { useState, useEffect, useRef } from "react";
import { authApi } from "../services/AxiosInstance";
import { useNavigate, useParams } from "react-router-dom";
import CustomChatBot from "./library/CustomChatBot";
import HeaderComp from "./Header/Header";
const Swal = require("sweetalert2");

const API_URL = process.env.REACT_APP_API_URL;

const GenerationFlowComponent = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [scrapperData, setScrapperData] = useState({});
  const [scrapData, setScrapData] = useState({});
  const [typingIndex, setTypingIndex] = useState(0);
  const progressIntervalExec = useRef(null);

  const handleStepIndex = (index) => {
    setTypingIndex(index);
  };
console.log(typingIndex,"typingIndex");
  useEffect(() => {
    const fetchData = async () => {
      try {
        let currentTime = new Date();
        const response = await authApi.post(API_URL + `scrapai/${id}`, {
          windowStatus: "hidden",
          currentTime,
        });
        const data = response.data.data;
        setScrapperData(data);
        setScrapData(data);

        if (data?.websiteStatus === "web_scrapping_failed") {
          Swal.fire({
            title: "Web Scrapping Failed",
            text: "Something went wrong while scraping the website. Please try another again.",
            icon: "error",
          }).then((result) => {
            if (result.isConfirmed || result.isDismissed) {
              navigate("/");
            }
          });
          clearInterval(progressIntervalExec.current);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        // clearInterval(progressIntervalExec.current);
      }
    };

    if (progressIntervalExec.current === null) {
      progressIntervalExec.current = setInterval(fetchData, 3000);;
    }

  }, []);

  return (
    <>
      <div className="processing-section">
        <div>
          <HeaderComp title="Web Scrapping" />
          <div
            className={`flex flex-col xl:justify-center justify-normal xl:mt-0 mt-[20%] h-[78vh] px-[10px] lg:px-[120px] py-[0px]`}
          >
            {scrapperData ? (
              <>
              {typingIndex <= 14 && (
                  <>
                    {typingIndex <= 9 || typingIndex <= 13 ? (
                      <div className="flex flex-wrap">
                        <div className="w-[56%] xl:w-[36%] px-4">
                          <div
                            className={`font-bold text-[32px] leading-10`}
                          >
                            Creating 4 unique variants of your{" "}
                            <span className="text-bg-gradiant">
                              landing page
                            </span>
                            .
                          </div>
                          {typingIndex != 14 && (
                            <div className="text-base font-normal leading-[21px]">
                              (Takes 3-4 minutes)
                            </div>
                          )}
                        </div>
                      </div>
                    ) : (
                      typingIndex >= 14 && (
                        <div className="flex flex-wrap">
                          <div className="w-[56%] xl:w-[36%] px-4">
                            <div
                              className={`font-bold text-[32px] leading-10`}
                            >
                              I have created 4  <span className="text-bg-gradiant">
                                landing page
                              </span> variants for you!{" "}

                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </>
                ) 
                //  (
                //   typingIndex >= 4 &&
                //   typingIndex <= 9 && (  
                //     <>
                //       <div className="bg-[#ffffff3d] w-[350px] md:w-[719px] h-[378px] rounded-lg">
                //         <div>
                //           <div class="flex flex-wrap items-center justify-between w-full py-4 md:py-0 px-[24px] text-lg text-black">
                //             <div className="font-cera-pro-bold pt-4">
                //               <a
                //                 href=""
                //                 className={`${(typingIndex >= 8 && typingIndex <= 9)
                //                   ? "pulse-animation p-1.5"
                //                   : ""
                //                   }`}
                //               >
                //                 Logo Here
                //               </a>
                //             </div>
                //           </div>
                //         </div>

                //         <div class="pl-[24px] pr-[77px] py-[46px]">
                //           <div class="flex justify-center items-center max-w-2xl ">
                //             <div class="header_section_one pr-[50px]">
                //               <div class="font-cera-pro-bold text-black text-[24px] leading-[33px] pb-[14px] font-bold">
                //                 {" "}
                //                 Lorem ipsum dollar sit amet featured
                //               </div>
                //               <div className="font-normal text-[12px] leading-[20px] pr-[5rem]">
                //                 Lorem ipsum dolor sit amet. Ut
                //                 necessitatibus beatae est laboriosam
                //                 perferendis ut galisum.
                //               </div>

                //               <div
                //                 class={`${(typingIndex >= 4 && typingIndex <= 7) ? "pulse-animation" : ""
                //                   } font-cera-pro-bold py-[16px] px-[31px] w-[165px] h-[52px] bg-white rounded-[87px] mt-[27px] text-[15px] leading-[18px] font-medium `}
                //                 type="button"
                //                 id="header_button"
                //               >
                //                 Call to action
                //               </div>
                //             </div>
                //             <div class="header_section_second">
                //               <div class="header_section_inner hero-image-wrapper">
                //                 <div
                //                   className={` ${(typingIndex >= 10 && typingIndex <= 14)
                //                     ? "pulse-animation"
                //                     : ""
                //                     } hero-Image max-w-[186px] max-h-[185px] bg-[#fff] min-h-[186px] h-[186px] w-[186px] border-[1px] border-white text-center`}
                //                 ></div>
                //                 <div class="header_side"></div>
                //               </div>
                //             </div>
                //           </div>
                //         </div>
                //       </div>
                //     </>
                //     )
                // )
                }  
              </>
            ) : (
              <>
                <div className="scrapper-gif-image"></div>
                <div className="scrapper-status-text">
                  <h1>Generating your high-converting site</h1>
                  <p>(Takes upto 5 minutes)</p>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <CustomChatBot scrapData={scrapData} handleStepIndex={handleStepIndex} />
    </>
  );
};

export default GenerationFlowComponent;
