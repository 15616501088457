const LoaderJoxy = () => {
    return (
        <div className="flex gap-3 my-1.5 flex-1 float-left clear-both w-[70.5%]">
            <div className="scrapper-gif-image">
                <img
                    src="/images/new_theme/Finalize-Loading-Animation.gif"
                    className="h-12"
                    alt="animation loader"
                />
            </div>
        </div>
    );
}

const PageLoaderJoxy = () => {
    return (
        <div className="text-center">
            <div className="gif-image">
                <img
                    src="/images/new_theme/Finalize-Loading-Animation.gif"
                    className="h-12 top-0 bottom-0 left-0 right-0 m-auto absolute"
                    alt="animation loader"
                />
            </div>
        </div>
    );
}

const ButtonLoaderJoxy = () => {
    return (
       
                <img
                    src="/images/new_theme/Finalize-Loading-Animation.gif"
                    className="h-[22px] m-auto inline"
                    alt="animation loader"
                />
          
    );
}



export { LoaderJoxy, PageLoaderJoxy, ButtonLoaderJoxy };