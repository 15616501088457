import React, { createContext, useContext, useState } from 'react';

const AppStateContext = createContext();

export const AppStateProvider = ({ children }) => {
  const [chatFunctionTriggered, setChatFunctionTriggered] = useState(false);

  const triggerChatFunction = () => {
    setChatFunctionTriggered(true);
  };

  return (
    <AppStateContext.Provider value={{ chatFunctionTriggered, triggerChatFunction }}>
      {children}
    </AppStateContext.Provider>
  );
};

export const useAppState = () => {
  return useContext(AppStateContext);
};
