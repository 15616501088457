import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { mdiMenuDown, mdiMenuUp } from "@mdi/js";
import ProfileDropdown from "./ProfileDropdown";
import Icon from "@mdi/react";
function HeaderComp({ title }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [userDetail, setUserDetail] = useState(null);
  const [openDrop, setOpenDrop] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    setUserDetail(user);
  }, []);

  const handleClick = (event) => {
    setOpenDrop(!openDrop);
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <div className="flex p-4 lg:px-[120px] justify-between items-center text-center lg:py-[22px] border-b-2 border-[#86878D]">
        <div
          onClick={() => {
            navigate("/");
            localStorage.removeItem("selectedMenuItem");
          }}
        >
          <img src="/images/new_theme/Joxy.svg" className="scrapper-logo-color" alt="" />
        </div>
        {/* {title && <p style={{ minWidth: 100, color: "#000" }}>{title}</p>} */}
        <p className="font-cera-pro-bold text-black items-center capitalize text-xs cursor-pointer font-bold flex"
          onClick={handleClick}
        >  <img src="/images/new_theme/dummy_person.png" className="h-8 mr-1.5 rounded-full border-2 border-[#86878D]" alt="" />
          {userDetail?.user?.name}{" "}
          {openDrop ?  <Icon size={0.7} path={mdiMenuUp} /> : <Icon size={0.7} path={mdiMenuDown} />}
        </p>
      </div>
      <ProfileDropdown
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        userDetail={userDetail}
        open={openDrop}
        setOpen={setOpenDrop}
      />
    </>
  );
}
export default HeaderComp;
