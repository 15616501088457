import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import React, { useEffect, useState } from "react";
import { replaceImagesWithThumbnails } from "../constants/jsonData";
import { useDispatch } from "react-redux";
import { uploadImageToS3Bucket } from "../../actions/s3bucket";
import { useParams } from "react-router-dom";
import { ButtonLoaderJoxy, PageLoaderJoxy } from "./LoaderJoxy";
import { removeCroppedFromImageName } from "./common";
import { ExpandLessOutlined, ExpandMoreOutlined } from "@mui/icons-material";

const CustomAssetManager = ({
  editorRef,
  setSelectedImage,
  assets,
  aiImages,
  close,
  setModalOpen,
  setIsCreateImageModalOpen,
  setIsUploadImageModalOpen,
  isPlaceholderImage,
  messageId,
  setMessageId,
  setOriginalImage,
  setIsUploadVideoModalOpen,
  setOpenEditImagePropModal,
  ...props
}) => {

  const dispatch = useDispatch();
  const { id } = useParams();

  const [hoveredBlock, setHoveredBlock] = useState('');
  const [savingImage, setSavingImage] = useState(false);
  const [selectedImageId, setSelectedImageId] = useState(null);
  const [generatingImage, setGeneratingImage] = useState(false);
  const [expandedSection, setExpandedSection] = useState('saved');

  
  useEffect(() => {
    messageId ? setGeneratingImage(true):setGeneratingImage(false);
  },[messageId]);


  const getImage = async (dataURL, image_id) => {
    if (!dataURL) {
      return;
    }
    image_id ? setSelectedImageId(image_id) : setSelectedImageId(null);
    

    setSavingImage(true);
    const file = dataURL.startsWith("data:image") ? dataURL : await convertImageToDataURL(dataURL);

    dispatch(
      uploadImageToS3Bucket({
        file: file,
        folder_name: id,
      })
    )
      .then(async (response) => {
        if (response.data.file) {
          const selectedImage = editorRef.current.getSelected();
          editorRef.current.AssetManager.add(response.data.file);
          selectedImage.set("src", response.data.file);
          close();
          setSavingImage(false);
        }
      })
      .catch((error) => {
        // Handle error as needed
        setSavingImage(false);
      });
  };

  const convertImageToDataURL = async (image_url) => {
    try {
      // const proxyUrl = 'http://cors-anywhere.herokuapp.com/';
      const response = await fetch(image_url);
      const blob = await response.blob();

      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onloadend = () => {
          resolve(reader.result);
        };

        reader.onerror = () => {
          reject(new Error("Error reading blob as Data URL"));
        };

        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error("Error converting image to Data URL:", error);
    }
  };


  const manageOriginalImage = async ()=>{
    const selectedComponent = window.editor.getSelected();
    
    const originalImage = removeCroppedFromImageName({
      imageUrl: selectedComponent.attributes.src,
    });
    setOriginalImage(originalImage.modifiedUrl);
  }



  return (
    <div className=" edit-moadal absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white px-7 py-10 rounded relative w-[1200px]">
        <div className="flex justify-between">
          <h2 className="text-[20px]">Images</h2>
          <button
            type="button"
            onClick={() => {
              close();
            }}
          >
            <Icon size={1} path={mdiClose} />
          </button>
        </div>
        <div className="flex mt-10 w-[1140px] h-[446px]">
          <div className="flex outline outline-1 outline-[#959595] rounded-xl overflow-y-auto w-[878px] h-[100%] px-10 pt-8 flex-wrap">
            {!isPlaceholderImage && <>
              <div
              onClick={()=>{
                setModalOpen(true)
                setHoveredBlock('')              
                close()
                manageOriginalImage()
              }}
              onMouseEnter={() => setHoveredBlock('edit')}
              onMouseLeave={() => setHoveredBlock('')}
              className="h-[175px] w-[175px] cursor-pointer rounded-lg me-4 outline outline-1 text-center flex flex-col items-center justify-center hover:bg-[#2688D1] hover:text-white"
            >
              <div className={`h-[75px] w-[75px] outline outline-1 rounded-full flex items-center justify-center mb-3 ${hoveredBlock==='edit'?'bg-[#D9D9D980] outline-[#FFFFFF80]': 'bg-[#7C7C7C80] outline-[#00000080]'}`}>
                <img src={hoveredBlock==='edit'? "/images/edit-2.svg": "/images/edit-2-dark.svg"} alt="edit" />
              </div>
              <h2 className="text-sm mb-1">Edit Images</h2>
              <div className="flex">
                <img
                  src="/images/gallery.svg"
                  alt="gallary"
                  className={hoveredBlock==='edit'?"bg-white rounded":""}
                />
                <span className="ms-1 text-[8px]">
                  Remove background, edit mask
                </span>
              </div>
            </div>
            <div
              onClick={()=>{
                setOpenEditImagePropModal(true)
                setHoveredBlock('')              
                close()
                manageOriginalImage()
              }}
              onMouseEnter={() => setHoveredBlock('editImage')}
              onMouseLeave={() => setHoveredBlock('')}
              className="h-[175px] w-[175px] cursor-pointer rounded-lg me-4 outline outline-1 text-center flex flex-col items-center justify-center hover:bg-[#2688D1] hover:text-white"
            >
              <div className={`h-[75px] w-[75px] outline outline-1 rounded-full flex items-center justify-center mb-3 ${hoveredBlock==='editImage'?'bg-[#D9D9D980] outline-[#FFFFFF80]': 'bg-[#7C7C7C80] outline-[#00000080]'}`}>
                <img src={hoveredBlock==='editImage'? "/images/edit-2.svg": "/images/edit-2-dark.svg"} alt="edit" />
              </div>
              <h2 className="text-sm mb-1">Edit Image Properties</h2>
              <div className="flex">
                <img
                  src="/images/gallery.svg"
                  alt="gallary"
                  className={hoveredBlock==='editImage'?"bg-white rounded":""}
                />
                <span className="ms-1 text-[8px]">
                Add alt text and title for this image
                </span>
              </div>
            </div>
            </>
            }
            <div>
            {generatingImage && <div className="absolute">
              <ButtonLoaderJoxy/>
            </div>}
            <div
              onClick={()=>{
                setIsCreateImageModalOpen(true)
                setHoveredBlock('')
                close()
              }}
              onMouseEnter={() => setHoveredBlock('generate')}
              onMouseLeave={() => setHoveredBlock('')}
              className="h-[175px] w-[175px] cursor-pointer rounded-lg me-4 outline outline-1 text-center flex flex-col items-center justify-center hover:bg-[#2688D1] hover:text-white"
            >
              <div className={`h-[75px] w-[75px] outline outline-1 rounded-full flex items-center justify-center mb-3 ${hoveredBlock==='generate'?'bg-[#D9D9D980] outline-[#FFFFFF80]': 'bg-[#7C7C7C80] outline-[#00000080]'}`}>
                <img src={hoveredBlock==='generate'? "/images/generate.svg": "/images/generate-dark.svg"} alt="edit" />
              </div>
              <h2 className="text-sm mb-1">Generate Ai Images</h2>
              <div className="flex">
                <img
                  src="/images/Joxy 1.svg"
                  alt="gallary"
                  className={hoveredBlock==='generate'?"bg-white rounded":""}
                />
                <span className="ms-1 text-[8px]">Powered by Midjourney</span>
              </div>
            </div>
            
            </div>
            <div
              onClick={()=>{
                setIsUploadImageModalOpen(true)
                setHoveredBlock('')
                close()
              }}
              onMouseEnter={() => setHoveredBlock('upload')}
              onMouseLeave={() => setHoveredBlock('')}
              className="h-[175px] w-[175px] cursor-pointer rounded-lg me-4 outline outline-1 text-center flex flex-col items-center justify-center hover:bg-[#2688D1] hover:text-white"
            >
              <div className={`h-[75px] w-[75px] outline outline-1 rounded-full flex items-center justify-center mb-3 ${hoveredBlock==='upload'?'bg-[#D9D9D980] outline-[#FFFFFF80]': 'bg-[#7C7C7C80] outline-[#00000080]'}`}>
                <img src={hoveredBlock==='upload'? "/images/gallery-import.svg": "/images/gallery-import-dark.svg"} alt="edit" />
              </div>
              <h2 className="text-sm mb-1">Upload Images</h2>
              <span className="h-[12px]"></span>
            </div>

            <div
              onClick={()=>{
                setIsUploadVideoModalOpen(true)
                setHoveredBlock('')
                close()
              }}
              onMouseEnter={() => setHoveredBlock('upload_video')}
              onMouseLeave={() => setHoveredBlock('')}
              className="h-[175px] w-[175px] cursor-pointer rounded-lg me-4 outline outline-1 text-center flex flex-col items-center justify-center hover:bg-[#2688D1] hover:text-white"
            >
              <div className={`h-[75px] w-[75px] outline outline-1 rounded-full flex items-center justify-center mb-3 ${hoveredBlock==='upload_video'?'bg-[#D9D9D980] outline-[#FFFFFF80]': 'bg-[#7C7C7C80] outline-[#00000080]'}`}>
                <img src={hoveredBlock==='upload_video'? "/images/insert-video.svg": "/images/insert-video-dark.svg"} alt="edit" />
              </div>
              <h2 className="text-sm mb-1">Insert Video</h2>
              <span className="h-[12px]"></span>
            </div>
          </div>

          <div>

          <div className={`${expandedSection==='saved'?'h-[86%]':''}`}>
            <div className="ms-3 flex items-center justify-between cursor-pointer" onClick={()=>{
              expandedSection==='ai_generated'?setExpandedSection('saved'):setExpandedSection('ai_generated')
            }}>
              <span className="font-bold text-sm">Saved Images</span>
              {expandedSection==='saved'?<span onClick={()=>setExpandedSection('ai_generated')}><ExpandLessOutlined /></span>:
              <span onClick={()=>setExpandedSection('saved')}><ExpandMoreOutlined /></span>}
            </div>
            {expandedSection==='saved'&&<div className="max-h-[90%] grid-container grid grid-cols-3 ms-3 mt-3 gap-1.5 overflow-y-auto">
              {assets.map((asset, index) => (
                <div
                  key={index}
                  className="image-box w-[76px] h-[76px] rounded logo-trans"
                >
                  <img
                    className="image"
                    src={replaceImagesWithThumbnails(asset.getSrc())}
                    alt="background"
                  />
                  <div className="overlay w-[76px] h-[76px] rounded">
                  { savingImage && selectedImageId === index+'_id' ? <button
                      className="hover-btn disabled:cursor-default disabled:bg-[#3c3c3c] w-[39px] h-[28px] disabled:text-[#777777]"
                      disabled
                    >
                      {/* <CircularProgress size={12} color="inherit" /> */}
                      Select
                      <PageLoaderJoxy />
                    </button>:
                    <button
                      className="hover-btn disabled:cursor-default disabled:bg-[#3c3c3c] disabled:text-[#777777]"
                      // onClick={() => {
                      //   getImage(asset.getSrc(), index+'_id');
                      // }}
                      onClick={() => {
                        setTimeout(() => {
                          close();
                          setSelectedImage(asset.getSrc());
                          setOriginalImage(asset.getSrc());
                          setModalOpen(true);
                      }, 500); // Wait for fade-out transition
                      }}
                      disabled={savingImage}
                    >
                      Select
                    </button>}

                    {/* <button
                      className="hover-btn disabled:cursor-default disabled:bg-[#3c3c3c] disabled:text-[#777777]"
                      onClick={() => {
                        setSelectedImage(asset.getSrc());
                        close();
                        setModalOpen(true);
                      }}
                      disabled={savingImage}
                    >
                      Edit
                    </button> */}
                  </div>
                </div>
              ))}
            </div>}
          </div>
          {aiImages.length>0 &&
          <div className={`${expandedSection==='ai_generated'?'h-[86%] mt-6':'mt-6'}`}>
          <div className="ms-3 grid">
            <div className="flex items-center justify-between cursor-pointer" onClick={()=>{
              expandedSection==='ai_generated'?setExpandedSection('saved'):setExpandedSection('ai_generated')
            }}>
              <span className="font-bold text-sm">AI Generated Images</span>
              {expandedSection==='ai_generated'?<span onClick={()=>setExpandedSection('saved')}><ExpandLessOutlined /></span>:
              <span onClick={()=>setExpandedSection('ai_generated')}><ExpandMoreOutlined /></span>}
              </div>
              <span className="text-[10px]">(To modify a previously generated image click edit)</span>
              </div>
              {expandedSection==='ai_generated'&&<div className="max-h-[90%] grid-container grid grid-cols-3 ms-3 mt-3 gap-1.5 overflow-y-auto">
            {aiImages.map((img, index) => (
                  <div
                    key={index}
                    className="image-box w-[76px] h-[76px] rounded logo-trans"
                  >
                    <img className="image" src={img.thumbnailUri} alt="background" />
                    <div className="overlay w-[76px] h-[76px] rounded">
                      <button
                        className="hover-btn disabled:cursor-default disabled:bg-[#3c3c3c] disabled:text-[#777777]"
                        onClick={() => {
                          setTimeout(() => {
                            close();
                            setSelectedImage(img.imageUri);
                            setOriginalImage(img.imageUri);
                            setModalOpen(true);
                        }, 500); // Wait for fade-out transition
                        }}
                        disabled={savingImage}
                      >
                        Select
                      </button>
                      <button
                        className="hover-btn disabled:cursor-default disabled:bg-[#3c3c3c] disabled:text-[#777777]"
                        onClick={() => {
                          setMessageId(img);
                          setIsCreateImageModalOpen(true)
                          setHoveredBlock('')
                          close()
                        }}
                        disabled={savingImage}
                      >
                        Edit
                      </button>
                    </div>
                  </div>
                ))}
            </div>}
          </div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomAssetManager;
