import React, { useEffect, useState } from "react";
import { getPreviewVariant } from "../actions/grapesjs";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import { PageLoaderJoxy } from "../components/library/LoaderJoxy";
import { getAppliedScriptsByWebsiteId, getMetaData } from "../actions/scripts";

const PreviewPage = () => {
  const dispatch = useDispatch();
  const [showHtml, setShowHtml] = useState("");
  const [showStyle, setShowStyle] = useState("");
  const { variantId } = useParams();
  const [loading, setLoading] = useState(false);

  const token = localStorage.getItem("user");
  // Parse the JSON string to get the object
  const userData = JSON.parse(token);
  // Access the "id" property from the user object
  const userId = userData.user.id;

  const setMetaData = async (websiteId) => {
    dispatch(getMetaData(websiteId))
      .then((response) => {
        const data = response.data[0];
        if (data) {
          const metaData = JSON.parse(data?.metaData);
          // Update the document title
          document.title = `${data.title}`;

          // Update meta description
          const metaDescription = document.querySelector(
            "meta[name='description']"
          );
          if (metaDescription) {
            metaDescription.setAttribute(
              "content",
              getMeta(metaData, "description").field_value
            );
          }

          // Update meta keywords
          const metaKeywords = document.querySelector("meta[name='keywords']");
          if (metaKeywords) {
            metaKeywords.setAttribute(
              "content",
              getMeta(metaData, "keywords").field_value
            );
          }

          // Update the favicon dynamically
          const favicon = document.querySelector("link[rel='icon']");
          if (favicon) {
            favicon.href = data.userwebsitesDetails[0].favicon; // Update with dynamic favicon
          }

          const rowsData = metaData.filter(
            (item) =>
              item.field_key_value !== "keywords" &&
              item.field_key_value !== "description"
          );
          rowsData.forEach((row) => {
            let meta = document.querySelector(
              `meta[${row.field_key_name}='${row.field_key_value}']`
            );
            if (!meta) {
              meta = document.createElement("meta");
              meta.setAttribute(row.field_key_name, row.field_key_value);
            }
            meta.setAttribute("content", row.field_value);
            document.head.appendChild(meta);
          });
        }
      })
      .catch((error) => {
        console.error({ error });
      });
  };
  const getScriptsData = async (websiteId) => {
    try {
      const response = await dispatch(getAppliedScriptsByWebsiteId(websiteId, userId));
      const scriptsData = response.data;
      let updated_html = showHtml;
  
      // Log scripts data
      const before_body_script = getScript(scriptsData, "Before Body End Tag");
      const after_body_script = getScript(scriptsData, "After Body End Tag");
      const header_script = getScript(scriptsData, "Header");
  
      // Ensure `showHtml` is not empty
      if (!updated_html) {
        console.error("Error: showHtml is empty or undefined.");
        return;
      }
  
      // Replace <body> tag
      updated_html = updated_html.replace(/<body(\s[^>]*)?>/, `${header_script}<body$1>`);
  
      // Insert scripts before closing </body> tag and after it
      updated_html = updated_html.replace(/<\/body>/, `${before_body_script}</body>${after_body_script}`);
      setShowHtml(updated_html)
    } catch (error) {
      // Handle error as needed
      console.error("Error in getting script", error);
    }
  };

  const getScript = (appliedScripts, placement) => {
    const scripts = appliedScripts.filter((appliedScript) => {
      const script = appliedScript.scriptId;
      return script.placement === placement;
    });
    const result = [];
    scripts.map((appliedScript) => {
      return result.push(appliedScript.scriptId.script);
    });
    return result.map((item) => item.replace(/&lt;/g, "<")).join(" ");
  };

  function getMeta(metaData, key) {
    const data = metaData.find((meta) => meta.field_key_value === key);
    return data;
  }

  useEffect(() => {
    setLoading(true);

    dispatch(getPreviewVariant(variantId))
      .then(async (response) => {
        await setMetaData(response.data.websiteId);
        let view_count = localStorage.getItem("view_count");
        localStorage.setItem("view_count", parseInt(view_count) + 1);
        setLoading(false);
        setShowStyle(response?.data?.css_data);

        let cssContentWithFontFamily = response?.data?.css_data;
        cssContentWithFontFamily =
          cssContentWithFontFamily +
          ` @import url('https://fonts.googleapis.com/css2?family=Assistant&display=swap');
          body {
            font-family: 'Assistant', sans-serif; 
          } ${response?.data?.style_css[0]?.fontFace}`;

        setShowStyle(cssContentWithFontFamily);
        setShowHtml(response?.data?.html_data);
        await getScriptsData(response.data.websiteId);
      })
      .catch((error) => {
        setLoading(false);
        // Handle error as needed
      });
  }, [variantId, dispatch]);

  useEffect(() => {
    // Create a style element
    const styleElement = document.createElement("style");
    styleElement.type = "text/css";
    styleElement.id = "dynamic-styles";

    // Set the content of the style element with dynamic styles
    styleElement.appendChild(document.createTextNode(showStyle));

    // Remove the previous style element if it exists
    const prevStyleElement = document.getElementById("dynamic-styles");
    if (prevStyleElement) {
      document.head.removeChild(prevStyleElement);
    }

    // Append the style element to the head of the document
    document.head.appendChild(styleElement);

    // Cleanup function to remove the style element on component unmount
    return () => {
      document.head.removeChild(styleElement);
    };
  }, [showStyle]);

  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <PageLoaderJoxy />
        </Box>
      ) : (
        <div>
          <div dangerouslySetInnerHTML={{ __html: showHtml }} />
        </div>
      )}
    </>
  );
};

export default PreviewPage;
