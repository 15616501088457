export const MAIN_BG_COLOR = "bg-slate-900";

export const MAIN_TXT_COLOR = "text-black";

export const BTN_CLS = "border rounded-lg w-[86px]";

export const BTN_CLS_NEW = "border rounded py-1 w-full";

export const MAIN_BORDER_COLOR = "border-black";

export const ROUND_BORDER_COLOR = `rounded border ${MAIN_BORDER_COLOR}`;

export function cx(...inputs) {
  const inp = Array.isArray(inputs[0]) ? inputs[0] : [...inputs];
  return inp.filter(Boolean).join(" ");
}
export function checkURL(url) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve(false); // Image loaded successfully
    };
    img.onerror = () => {
      resolve(true); // Error occurred while loading image
    };
    img.src = url;
  });
}

export function faqChecked(editorInstance) {
  editorInstance.DomComponents.getComponents().forEach((component) => {
    const classes = component.getClasses();
    if (classes.includes("faq")) {
        // Find all input elements within the FAQ block
        const inputs = component.find("input[type='checkbox']");
        
        // Iterate over the input elements and add the checked attribute
        inputs.forEach((input) => {
            input.addAttributes({ checked: true });
        });
    }
});
}

export function faqUnChecked(editorInstance) {
  editorInstance.DomComponents.getComponents().forEach((component) => {
    const classes = component.getClasses();
    if (classes.includes("faq")) {
        
        // Find all input elements within the FAQ block
        const inputs = component.find("input[type='checkbox']");
        
        // Iterate over the input elements and add the checked attribute
        inputs.forEach((input) => {
            input.addAttributes({ checked: false });
        });
    }
});
}

export function ImageNameExtractor({ imageUrl }) {
  // Extract image name from the URL
  const imageName = imageUrl.substring(imageUrl.lastIndexOf('/') + 1);
  
  // Extract image type (file extension)
  const imageType = imageUrl.split('.').pop();

  const croppedImageName = `${imageName.split('.')[0]}_cropped.${imageType}`;

  return { name: croppedImageName, type: imageType };

}
export function removeCroppedFromImageName({ imageUrl }) {
  // Extract image name from the URL
  const imageName = imageUrl.substring(imageUrl.lastIndexOf('/') + 1);

  // Remove "_cropped" from the image name using a regular expression
  const modifiedImageName = imageName.replace(/_cropped/g, '');

  // Get the base URL without the image name
  const baseUrl = imageUrl.substring(0, imageUrl.lastIndexOf('/') + 1);

  // Concatenate the base URL with the modified image name
  const modifiedImageUrl = baseUrl + modifiedImageName;
  return { originalName: imageName, modifiedName: modifiedImageName, modifiedUrl: modifiedImageUrl };
}

export function fetchDomainUrl (url)  {
  try {
    const urlObject = new URL(url);
    let domain = urlObject.hostname;

    // Remove "www." if it exists at the beginning
    domain = domain.replace(/^www\./, "");

    return domain;
  } catch (error) {
    // Handle the error here, for example:
    let domain = url.replace(/^www\./, "");

    return domain;
  }
};






