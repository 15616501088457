import { SCRAPER_DATA_SUCCESS, SCRAPER_DATA_FAILURE, UPDATE_SCRAPER_DATA_FAILURE, UPDATE_SCRAPER_DATA_SUCCESS } from "../actions/type";

const initialState = {
  template1: {},
};

export default function grapejsReducer(state = initialState, action) {
  switch (action.type) {
    case SCRAPER_DATA_SUCCESS:
      return {
        ...state,
        scraperData: action.payload,
      };
      case SCRAPER_DATA_FAILURE:
      return {
        ...state,
        scraperData: action.payload,
      };
      case UPDATE_SCRAPER_DATA_FAILURE:
        return {
          ...state,
          updateScraperData: action.payload,
        };
        case UPDATE_SCRAPER_DATA_SUCCESS:
        return {
          ...state,
          updateScraperData: action.payload,
        };
    default:
      return state;
  }
}
